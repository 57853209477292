import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppDetails } from '@core/app_details';
import { AppRoutes, AvailableTranslationLang } from '@project/enums';
import { store } from '@store/store';

export function useAdvancedNavigator(): UseAdvancedNavigationHook {
  const { i18n } = useTranslation();
  const navigator = useNavigate();
  const location = useLocation();

  return {
    navigator: (
      path?: Nullable<AppRoutes>,
      lang?: Nullable<AvailableTranslationLang>,
    ) => {
      const routesWithDynamicPaths = ['/article/', '/service/'];
      const pathParticles = location.pathname.split('/');
      const currentLang = lang ?? i18n.language;
      const isLangNotChanged = !!lang && lang !== i18n.language;

      const isFallbackLang = currentLang === AppDetails.fallbackAppLang;
      const langPrefix = isFallbackLang ? '/' : `/${currentLang}`;

      const cleanPathParticles = pathParticles.filter((path) => !!path);
      const restOfPaths = cleanPathParticles
        .slice(0, cleanPathParticles.length)
        .filter(
          (v) =>
            !AppDetails.supportedLangs.includes(v as AvailableTranslationLang),
        );

      let finalPath = langPrefix;
      if (path) {
        finalPath += `${path!}`;
      } else if (restOfPaths.length) {
        finalPath += `/${restOfPaths.join('/')}`;
      }

      const { dynamicPaths } = store.getState().app;
      const dynamicRoute = routesWithDynamicPaths.filter((v) =>
        finalPath.includes(v),
      );

      if (dynamicRoute.length && dynamicPaths && path === null) {
        const currentDynamicRoute = dynamicRoute[0];
        const pathByLangCode = dynamicPaths![currentLang];

        finalPath = `${langPrefix}${currentDynamicRoute}${pathByLangCode}`;
      }

      finalPath = finalPath.replaceAll(new RegExp('//', 'gim'), '/');

      // TODO: SYNC NAVIGATION WITH HISTORY
      navigator(finalPath, { replace: isLangNotChanged });

      if (!isLangNotChanged) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    guessNextRoute: (
      path?: Nullable<AppRoutes>,
      lang?: Nullable<AvailableTranslationLang>,
    ) => {
      const routesWithDynamicPaths = ['/article/', '/service/'];
      const pathParticles = location.pathname.split('/');
      const currentLang = lang ?? i18n.language;

      const isFallbackLang = currentLang === AppDetails.fallbackAppLang;
      const langPrefix = isFallbackLang ? '/' : `/${currentLang}`;

      const cleanPathParticles = pathParticles.filter((path) => !!path);
      const restOfPaths = cleanPathParticles
        .slice(0, cleanPathParticles.length)
        .filter(
          (v) =>
            !AppDetails.supportedLangs.includes(v as AvailableTranslationLang),
        );

      let finalPath = langPrefix;
      if (path) {
        finalPath += `${path!}`;
      } else if (restOfPaths.length) {
        finalPath += `/${restOfPaths.join('/')}`;
      }

      const { dynamicPaths } = store.getState().app;
      const dynamicRoute = routesWithDynamicPaths.filter((v) =>
        finalPath.includes(v),
      );

      if (dynamicRoute.length && dynamicPaths && path === null) {
        const currentDynamicRoute = dynamicRoute[0];
        const pathByLangCode = dynamicPaths![currentLang];

        finalPath = `${langPrefix}${currentDynamicRoute}${pathByLangCode}`;
      }

      return finalPath.replaceAll(new RegExp('//', 'gim'), '/');
    },
  };
}
