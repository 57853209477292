import { useTranslation } from 'react-i18next';

import { FormForRequest } from '@components/form_for_request';
import { FormForRequestType } from '@project/enums';
import { TranslationsKeys } from '@translations/translations_keys';

export function AskForViewing({
  propertyId,
  onClose,
}: AskForViewingProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="ask-for-viewing">
      <div className="ask-for-viewing__container">
        <div className="ask-for-viewing__header">
          <span>{t(TranslationsKeys.propertyModalTitle)}</span>
          <img
            className="close"
            src={require('../assets/svg/icons/close.svg').default}
            alt="close"
            onClick={onClose}
          />
        </div>
        <div className="ask-for-viewing__form">
          <FormForRequest
            type={FormForRequestType.ASK_FOR_VIEWING}
            propertyId={propertyId}
          />
        </div>
      </div>
    </div>
  );
}
