import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

import { AppDetails } from '@core/app_details';
import { TranslationsKeys } from '@translations/translations_keys';

export function Achievements({ data }: AchievementsProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="achievements static">
      <div className="achievements__item">
        <CountUp
          className="achievements__stats"
          end={data.houses}
          duration={AppDetails.defaultCountersDuration}
          suffix="+"
          separator=""
          enableScrollSpy
          scrollSpyOnce
        />
        <span className="achievements__label">
          {t(TranslationsKeys.mainPageWhyIsNataliaRosoAchievementsHouses)}
        </span>
      </div>
      <hr />
      <div className="achievements__item">
        <CountUp
          className="achievements__stats"
          end={data.years}
          duration={AppDetails.defaultCountersDuration}
          suffix="+"
          separator=""
          enableScrollSpy
          scrollSpyOnce
        />
        <span className="achievements__label">
          {t(TranslationsKeys.mainPageWhyIsNataliaRosoAchievementsYears)}
        </span>
      </div>
      <hr />
      <div className="achievements__item">
        <CountUp
          className="achievements__stats"
          end={data.customers}
          duration={AppDetails.defaultCountersDuration}
          suffix="+"
          separator=""
          enableScrollSpy
          scrollSpyOnce
        />
        <span className="achievements__label">
          {t(TranslationsKeys.mainPageWhyIsNataliaRosoAchievementsCustomers)}
        </span>
      </div>
    </div>
  );
}
