import { ReduxActionType } from '@project/enums';

const initialState: ArticlesState = {
  priorityNews: null,
  exactArticle: null,
  recommendations: null,
};

export function articlesReducer(
  state: ArticlesState = initialState,
  action: DispatchWithPayload<
    PriorityNews | ExactArticle | PriorityArticlePreview[]
  >,
): ArticlesState {
  switch (action.type) {
    default:
      return state;

    case ReduxActionType.ARTICLES_SET_PRIORITY_NEWS:
      return {
        ...state,
        priorityNews: action.payload as PriorityNews,
      };

    case ReduxActionType.ARTICLES_SET_EXACT_ARTICLE:
      return {
        ...state,
        exactArticle: action.payload as ExactArticle,
      };

    case ReduxActionType.ARTICLES_SET_RECOMMENDATION:
      return {
        ...state,
        recommendations: [...(action.payload as PriorityArticlePreview[])],
      };
  }
}

export class ArticlesActions {
  public static requestForAllNews(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.ARTICLES_REQUEST_GET_ALL_NEWS,
    };
  }

  public static requestForPriorityNews(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.ARTICLES_REQUEST_GET_PRIORITY_NEWS,
    };
  }

  public static setPriorityNews(
    payload: PriorityNews,
  ): DispatchWithPayload<PriorityNews> {
    return {
      type: ReduxActionType.ARTICLES_SET_PRIORITY_NEWS,
      payload,
    };
  }

  public static requestForGetExactArticle(
    payload: GetExactArticleDto,
  ): DispatchWithPayload<GetExactArticleDto> {
    return {
      type: ReduxActionType.ARTICLES_REQUEST_GET_EXACT_ARTICLE,
      payload,
    };
  }

  public static setExactArticle(
    payload: ExactArticle,
  ): DispatchWithPayload<ExactArticle> {
    return {
      type: ReduxActionType.ARTICLES_SET_EXACT_ARTICLE,
      payload,
    };
  }

  public static requestForGetRecommendations(
    payload: GetArticlesRecommendationsDto,
  ): DispatchWithPayload<GetArticlesRecommendationsDto> {
    return {
      type: ReduxActionType.ARTICLES_REQUEST_GET_RECOMMENDATION,
      payload,
    };
  }

  public static setRecommendations(
    payload: PriorityArticlePreview[],
  ): DispatchWithPayload<PriorityArticlePreview[]> {
    return {
      type: ReduxActionType.ARTICLES_SET_RECOMMENDATION,
      payload,
    };
  }
}
