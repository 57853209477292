import { useRive, Layout, Fit, Alignment } from '@rive-app/react-canvas';

import { RiveAssets } from '@core/rive_assets';

export function ButtonSba({ label, onClick }: ButtonSbaProps): JSX.Element {
  const { RiveComponent: Animation } = useRive({
    src: RiveAssets.spinBorderYellow,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
    autoplay: true,
  });

  return (
    <div className="button-sba" onClick={onClick}>
      <div className="button-sba__animation">
        <Animation />
      </div>
      <div className="button-sba__label">{label}</div>
    </div>
  );
}
