import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ButtonCwu } from '@components/button_cwu';
import { ContentHeader } from '@components/content_header';
import { Footer } from '@components/footer';
import { Navbar } from '@components/navbar';
import { PreFooter } from '@components/pre_footer';
import { PropertyItem } from '@components/property_item';
import { SearchPane } from '@components/search_pane';
import { TermsOfUsageToast } from '@components/terms_of_usage_toast';
import { AppDetails } from '@core/app_details';
import { PropertiesActions } from '@store/items/properties';
import { TranslationsKeys } from '@translations/translations_keys';

const mapState = ({ properties, customers }: RootState) => ({
  properties: properties.data,
  filters: customers.filters,
});

const mapDispatch = {
  requestForAll: () => PropertiesActions.requestForAll(),
};

const landAreaLimitation: DataRange = AppDetails.landAreaLimitation;
const buildingAreaLimitation: DataRange = AppDetails.buildingAreaLimitation;
const bathroomsLimitation: DataRange = AppDetails.bathroomsLimitation;
const priceLimitation: DataRange = AppDetails.priceLimitation;
const bedRoomsLimitation: DataRange = AppDetails.bedRoomsLimitation;

function Component({
  properties,
  filters,
  requestForAll,
}: PropertiesPageProps): JSX.Element {
  const { t } = useTranslation();

  const [filteredProperties, setFilteredProperties] =
    useState<Nullable<PropertyPreview[]>>(null);

  useEffect(() => {
    requestForAll();
  }, []);

  useEffect(() => {
    if (filters && properties) {
      const filtered: PropertyPreview[] = [];
      const filteredIds: Set<number> = new Set();

      const shouldApplySearch = !!filters.freeSearch;
      const shouldApplyLocation = !!filters.locations.length;
      const shouldApplyType = !!filters.types.length;

      const shouldApplyBedRooms =
        filters.bedrooms[0] !== bedRoomsLimitation[0] ||
        filters.bedrooms[1] !== bedRoomsLimitation[1];

      const shouldApplyPrice =
        filters.price[0] !== priceLimitation[0] ||
        filters.price[1] !== priceLimitation[1];

      const shouldApplyLandArea =
        filters.landArea[0] !== landAreaLimitation[0] ||
        filters.landArea[1] !== landAreaLimitation[1];

      const shouldApplyBuildingArea =
        filters.buildingArea[0] !== buildingAreaLimitation[0] ||
        filters.buildingArea[1] !== buildingAreaLimitation[1];

      const shouldApplyBathrooms =
        filters.bathrooms[0] !== bathroomsLimitation[0] ||
        filters.bathrooms[1] !== bathroomsLimitation[1];

      const notOneApplied =
        !shouldApplySearch &&
        !shouldApplyLocation &&
        !shouldApplyType &&
        !shouldApplyBedRooms &&
        !shouldApplyPrice &&
        !shouldApplyLandArea &&
        !shouldApplyBuildingArea &&
        !shouldApplyBathrooms;

      for (const property of properties) {
        if (shouldApplySearch) {
          property.title
            .toLowerCase()
            .includes(filters.freeSearch!.toLowerCase()) &&
            filteredIds.add(property.id);

          if (
            filters.freeSearch!.toLowerCase().startsWith('ext_') ||
            filters.freeSearch!.toLowerCase().startsWith('roso_')
          ) {
            const id = +filters.freeSearch!.split('_')[1];

            if (id === property.id) {
              filteredIds.add(property.id);
            }
          }
        }

        if (shouldApplyLocation) {
          if (filters.locations.includes(property.locationId)) {
            filteredIds.add(property.id);
          }
        }

        if (shouldApplyType) {
          if (filters.types.includes(property.typeId)) {
            filteredIds.add(property.id);
          }
        }

        if (shouldApplyBedRooms) {
          if (property.bedrooms.between(filters.bedrooms)) {
            filteredIds.add(property.id);
          }
        }

        if (shouldApplyPrice) {
          if (property.price?.between(filters.price)) {
            filteredIds.add(property.id);
          }
        }

        if (shouldApplyLandArea) {
          if (property.landArea?.between(filters.landArea)) {
            filteredIds.add(property.id);
          }
        }

        if (shouldApplyBuildingArea) {
          if (property.buildArea?.between(filters.buildingArea)) {
            filteredIds.add(property.id);
          }
        }

        if (shouldApplyBathrooms) {
          if (property.bathrooms?.between(filters.bathrooms)) {
            filteredIds.add(property.id);
          }
        }

        if (Array.from(filteredIds).includes(property.id) || notOneApplied) {
          filtered.push(property);
        }
      }

      setFilteredProperties(filtered.length ? [...filtered] : []);

      return;
    }

    setFilteredProperties(null);
  }, [filters, properties]);

  return (
    <>
      <Navbar shouldUseDarkTheme />
      <section className="all-properties">
        <ContentHeader
          subTitle={t(TranslationsKeys.mainPagePropertiesPreTitle)}
          title={t(TranslationsKeys.mainPagePropertiesTitle)}
          hasMoreButton={false}
        />
        {properties
          ? (filteredProperties ?? properties)!.map((property, i) => (
              <PropertyItem
                key={property.title}
                data={property}
                positionAtList={i + 1}
              />
            ))
          : null}
      </section>
      <PreFooter />
      <Footer />
      <TermsOfUsageToast />
      <ButtonCwu />
      <SearchPane />
    </>
  );
}

export const PropertiesPageConnector = connect(mapState, mapDispatch);

export const PropertiesPage = PropertiesPageConnector(Component);
