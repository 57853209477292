export class TranslationsKeys {
  public static splashLoaderLabel = 'splashLoader.label';

  public static navbarHome = 'navbar.home';
  public static navbarProperties = 'navbar.properties';
  public static navbarAboutAlgarve = 'navbar.aboutAlgarve';
  public static navbarWhoIsNr = 'navbar.whoIsNr';
  public static navbarNews = 'navbar.news';
  public static navbarServices = 'navbar.services';
  public static navbarLegalAdvice = 'navbar.legalAdvice';
  public static navbarFreeValuation = 'navbar.freeValuation';
  public static navbarFaq = 'navbar.faq';

  public static mainPageWelcomePropertyGallery =
    'mainPage.welcome.propertyGallery';
  public static mainPagePropertiesPreTitle = 'mainPage.properties.preTitle';
  public static mainPagePropertiesTitle = 'mainPage.properties.title';
  public static mainPageServicesPreTitle = 'mainPage.services.preTitle';
  public static mainPageServicesTitle = 'mainPage.services.title';
  public static mainPageReviewsPreTitle = 'mainPage.reviews.preTitle';
  public static mainPageReviewsTitle = 'mainPage.reviews.title';
  public static mainPageNewsPreTitle = 'mainPage.news.preTitle';
  public static mainPageNewsTitle = 'mainPage.news.title';
  public static mainPageWhyIsNataliaRosoPreTitle =
    'mainPage.whyIsNataliaRoso.preTitle';
  public static mainPageWhyIsNataliaRosoTitle =
    'mainPage.whyIsNataliaRoso.title';
  public static mainPageWhyIsNataliaRosoDescription =
    'mainPage.whyIsNataliaRoso.description';
  public static mainPageWhyIsNataliaRosoAchievementsHouses =
    'mainPage.whyIsNataliaRoso.achievements.houses';
  public static mainPageWhyIsNataliaRosoAchievementsYears =
    'mainPage.whyIsNataliaRoso.achievements.years';
  public static mainPageWhyIsNataliaRosoAchievementsCustomers =
    'mainPage.whyIsNataliaRoso.achievements.customers';
  public static mainPageSubscribedToNewslettersPreTitle =
    'mainPage.subscribedToNewsletters.preTitle';
  public static mainPageSubscribedToNewslettersTitle =
    'mainPage.subscribedToNewsletters.title';
  public static mainPageStnlTitleAgreementLabel =
    'mainPage.subscribedToNewsletters.agreement.label';
  public static mainPageStnlTitleAgreementTerms =
    'mainPage.subscribedToNewsletters.agreement.terms';
  public static mainPageStnlTitleAgreementPrivacyPolicy =
    'mainPage.subscribedToNewsletters.agreement.privacyPolicy';
  public static mainPageStnlTitleAgreementAnd =
    'mainPage.subscribedToNewsletters.agreement.and';
  public static mainPageStnlTitleAgreementGetting =
    'mainPage.subscribedToNewsletters.agreement.getting';

  public static whoIsNrPageTitle = 'whoIsNrPage.title';
  public static whoIsNrPageDescription = 'whoIsNrPage.description';
  public static whoIsNrPageSubtitle = 'whoIsNrPage.subtitle';
  public static whoIsNrPageSubDescription = 'whoIsNrPage.subDescription';
  public static whoIsNrPageAdvantages = 'whoIsNrPage.advantages';
  public static whoIsNrPageLicense = 'whoIsNrPage.license';
  public static whoIsNrPageAmi = 'whoIsNrPage.ami';

  public static tncPageContent = 'tncPage.content';

  public static privacyPolicyPageContent = 'privacyPolicyPage.content';

  public static newsPageTitle = 'newsPage.title';

  public static servicesPageTitle = 'servicesPage.title';

  public static faqPageTitle = 'faqPage.title';

  public static articlesRecommendationWidgetTitle =
    'articlesRecommendationWidget.title';

  public static servicesRecommendationWidgetTitle =
    'servicesRecommendationWidget.title';

  public static touPopupLabel = 'termsOfUsagePopup.label';
  public static touPopupTerms = 'termsOfUsagePopup.terms';
  public static touPopupPrivacyPolicy = 'termsOfUsagePopup.privacyPolicy';
  public static touPopupAnd = 'termsOfUsagePopup.and';
  public static touPopupAccept = 'termsOfUsagePopup.accept';

  public static connectWithUsButton = 'connectWithUs.button';

  public static propertyPreviewWidgetTypeLabels = (key: string) =>
    `propertyPreviewWidget.typeLabels.${key}`;

  public static propertyPreviewWidgetCommonFieldsLandArea =
    'propertyPreviewWidget.commonFields.landArea';
  public static propertyPreviewWidgetCommonFieldsBedroom =
    'propertyPreviewWidget.commonFields.bedroom';
  public static propertyPreviewWidgetCommonFieldsBathroom =
    'propertyPreviewWidget.commonFields.bathroom';
  public static propertyPreviewWidgetCommonFieldsSwimmingPool =
    'propertyPreviewWidget.commonFields.swimmingPool';
  public static propertyPreviewWidgetCommonFieldsBuildingArea =
    'propertyPreviewWidget.commonFields.buildingArea';
  public static propertyPreviewWidgetCommonFieldsGarage =
    'propertyPreviewWidget.commonFields.garage';
  public static propertyPreviewWidgetControllersPresentation =
    'propertyPreviewWidget.controllers.presentation';
  public static propertyPreviewWidgetControllersPrint =
    'propertyPreviewWidget.controllers.print';
  public static propertyPreviewWidgetControllersCopyLink =
    'propertyPreviewWidget.controllers.copyLink';
  public static propertyPreviewWidgetControllersAskForViewing =
    'propertyPreviewWidget.controllers.askForViewing';

  public static preFooterTnc = 'preFooter.tnc';
  public static preFooterPrivacyPolicy = 'preFooter.privacyPolicy';
  public static preFooterComplaintBook = 'preFooter.complaintBook';
  public static preFooterAboutAlgarve = 'preFooter.aboutAlgarve';
  public static preFooterFaq = 'preFooter.faq';
  public static preFooterServices = 'preFooter.services';

  public static footerRights = 'footer.rights';
  public static footerPoweredBy = 'footer.poweredBy';

  public static formsLabelsFullName = 'forms.labels.fullName';
  public static formsLabelsEmail = 'forms.labels.email';
  public static formsLabelsSubject = 'forms.labels.subject';
  public static formsLabelsDescription = 'forms.labels.description';
  public static formsButtonsSubscribe = 'forms.buttons.subscribe';
  public static formsButtonsSend = 'forms.buttons.send';
  public static formsButtonsReadMore = 'forms.buttons.readMore';
  public static formsButtonsMore = 'forms.buttons.more';
  public static formsButtonsSeeMore = 'forms.buttons.seeMore';

  public static dynamicApiMessages = (key: string) =>
    `dynamicApiMessages.${key}`;

  public static aboutAlgarvePagePreTitle = 'aboutAlgarvePage.preTitle';
  public static aboutAlgarvePageTitle = 'aboutAlgarvePage.title';
  public static aboutAlgarvePageDescription = 'aboutAlgarvePage.description';
  public static aboutAlgarvePageHint = 'aboutAlgarvePage.hint';
  public static aboutAlgarvePageTotal = 'aboutAlgarvePage.total';
  public static aboutAlgarvePageAdvantages = 'aboutAlgarvePage.advantages';

  public static propertyPageFeaturesTitle = 'propertyPage.featuresTitle';
  public static propertyPageFeaturesWashingMachine =
    'propertyPage.features.washingMachine';
  public static propertyPageFeaturesDishwasher =
    'propertyPage.features.dishwasher';
  public static propertyPageFeaturesAirConditioning =
    'propertyPage.features.airConditioning';
  public static propertyPageFeaturesFittedWardrobes =
    'propertyPage.features.fittedWardrobes';
  public static propertyPageFeaturesUnderfloorHeating =
    'propertyPage.features.underfloorHeating';
  public static propertyPageFeaturesWalkInWardRope =
    'propertyPage.features.walkInWardRope';
  public static propertyPageFeaturesTelevisionRoom =
    'propertyPage.features.televisionRoom';
  public static propertyPageFeaturesFeatureFireplace =
    'propertyPage.features.featureFireplace';
  public static propertyPageFeaturesOpenPlanLivingArea =
    'propertyPage.features.openPlanLivingArea';
  public static propertyPageFeaturesVideoEntrySystem =
    'propertyPage.features.videoEntrySystem';
  public static propertyPageFeaturesLift = 'propertyPage.features.lift';
  public static propertyPageFeaturesTennisCourt =
    'propertyPage.features.tennisCourt';
  public static propertyPageFeaturesElectricPoolCover =
    'propertyPage.features.electricPoolCover';
  public static propertyPageFeaturesLargePlot =
    'propertyPage.features.largePlot';
  public static propertyPageFeaturesSouthFacingTerraces =
    'propertyPage.features.southFacingTerraces';
  public static propertyPageFeaturesCoveredAlFrescoDining =
    'propertyPage.features.coveredAlFrescoDining';
  public static propertyPageFeaturesLandscapedGardens =
    'propertyPage.features.landscapedGardens';
  public static propertyPageFeaturesExteriorEntertainingLounge =
    'propertyPage.features.exteriorEntertainingLounge';
  public static propertyPageFeaturesLaundry = 'propertyPage.features.laundry';
  public static propertyPageFeaturesDriveWay = 'propertyPage.features.driveWay';
  public static propertyPageFeaturesBasement = 'propertyPage.features.basement';
  public static propertyPageFeaturesGreatRentalPotential =
    'propertyPage.features.greatRentalPotential';
  public static propertyPageFeaturesViewToSea =
    'propertyPage.features.viewToSea';
  public static propertyPageFeaturesGym = 'propertyPage.features.gym';
  public static propertyPageFeaturesCentralMusicSystem =
    'propertyPage.features.centralMusicSystem';
  public static propertyPageFeaturesCentralLocation =
    'propertyPage.features.centralLocation';
  public static propertyPageFeaturesSolarHeating =
    'propertyPage.features.solarHeating';
  public static propertyPageFeaturesSolarOrientation =
    'propertyPage.features.solarOrientation';
  public static propertyPageFeaturesBalcony = 'propertyPage.features.balcony';
  public static propertyPageFeaturesHomeOffice =
    'propertyPage.features.homeOffice';
  public static propertyPageFeaturesMultipleBespoke =
    'propertyPage.features.multipleBespoke';
  public static propertyPageFeaturesFurnished =
    'propertyPage.features.furnished';
  public static propertyPageFeaturesAnimals = 'propertyPage.features.animals';
  public static propertyPageFeaturesPond = 'propertyPage.features.pond';
  public static propertyPageFeaturesChildrenGround =
    'propertyPage.features.childrenGround';
  public static propertyPageFeaturesOrangePlantation =
    'propertyPage.features.orangePlantation';
  public static propertyPageFeaturesCarport = 'propertyPage.features.carport';
  public static propertyPageFeaturesPadelCourt =
    'propertyPage.features.padelCourt';
  public static propertyPageFeaturesJacuzzi = 'propertyPage.features.jacuzzi';
  public static propertyPageFeaturesBbqArea = 'propertyPage.features.bbqArea';
  public static propertyPageFeaturesCommunalPool =
    'propertyPage.features.communalPool';
  public static propertyPageFeaturesGatedArea =
    'propertyPage.features.gatedArea';
  public static propertyPageFeaturesIbc = 'propertyPage.features.ibc';
  public static propertyPageFeaturesHeatedPool =
    'propertyPage.features.heatedPool';
  public static propertyPageFeaturesReception =
    'propertyPage.features.reception';
  public static propertyPageFeaturesTearOfBuild =
    'propertyPage.features.yearOfBuild';
  public static propertyPageFeaturesWiFi = 'propertyPage.features.wiFi';
  public static propertyPageFeaturesBar = 'propertyPage.features.bar';
  public static propertyPageFeaturesInsidePool =
    'propertyPage.features.insidePool';
  public static propertyPageFeaturesSauna = 'propertyPage.features.sauna';
  public static propertyPageFeaturesOven = 'propertyPage.features.oven';
  public static propertyPageFeaturesDoubleGlazed =
    'propertyPage.features.doubleGlazed';
  public static propertyPageFeaturesMicrowave =
    'propertyPage.features.microwave';
  public static propertyPageFeaturesOutdoorParking =
    'propertyPage.features.outdoorParking';
  public static propertyPageFeaturesRoofTop = 'propertyPage.features.roofTop';
  public static propertyPageFeaturesShutters = 'propertyPage.features.shutters';
  public static propertyPageFeaturesSmartDoorKey =
    'propertyPage.features.smartDoorKey';
  public static propertyPageFeaturesElectricShutters =
    'propertyPage.features.electricShutters';
  public static propertyPageFeaturesParkingSpace =
    'propertyPage.features.parkingSpace';
  public static propertyPageFeaturesSecurityAlarm =
    'propertyPage.features.securityAlarm';
  public static propertyPageFeaturesTerrace = 'propertyPage.features.terrace';
  public static propertyPageFeaturesPrivateCondominium =
    'propertyPage.features.privateCondominium';
  public static propertyPageFeaturesFridge = 'propertyPage.features.fridge';
  public static propertyPageFeaturesNearTheBeach =
    'propertyPage.features.nearTheBeach';
  public static propertyPageFeaturesElectricGate =
    'propertyPage.features.electricGate';
  public static propertyPageFeaturesSolarOrientationValues = (v: number) =>
    `propertyPage.features.solarOrientationValues.${v}`;
  public static propertyPageInfo = 'propertyPage.info';
  public static propertyPageEnergyClass = 'propertyPage.energyClass';
  public static propertyPageLabelsType = 'propertyPage.labels.type';
  public static propertyPageLabelsTypeValues = (v: number) =>
    `propertyPage.labels.typeValues.${v}`;
  public static propertyPageLabelsOwnerShip = 'propertyPage.labels.ownerShip';
  public static propertyPageLabelsOwnerShipValues = (v: number) =>
    `propertyPage.labels.ownerShipValues.${v}`;
  public static propertyPageTaxDetails = 'propertyPage.taxDetails';

  public static articlePageVisitWebSite = 'articlePage.visitWebSite';

  public static toasterMessagesLinkWasCopied = 'toasterMessages.linkWasCopied';

  public static favoritePropertiesTitle = 'favoriteProperties.title';
  public static favoritePropertiesLabel = 'favoriteProperties.label';

  public static propertyModalTitle = 'propertyModal.title';
  public static propertyModalLabel = 'propertyModal.label';

  public static buttonCwuFormTitle = 'buttonCwuForm.title';
  public static buttonCwuFormLabel = 'buttonCwuForm.label';
  public static buttonCwuFormThanksLabel = 'buttonCwuForm.labelThanks';
  public static buttonCwuFormThinkingOfSelling =
    'buttonCwuForm.thinkingOfSelling';
  public static buttonCwuFormSendYourRequest = 'buttonCwuForm.sendYourRequest';
  public static buttonCwuFormAskForFreeValuation =
    'buttonCwuForm.askForFreeValuation';

  public static searchPaneSearch = 'searchPane.search';
}
