import { useTranslation } from 'react-i18next';

import { ButtonCwu } from '@components/button_cwu';
import { ContentHeader } from '@components/content_header';
import { Footer } from '@components/footer';
import { Navbar } from '@components/navbar';
import { PreFooter } from '@components/pre_footer';
import { SearchPane } from '@components/search_pane';
import { TermsOfUsageToast } from '@components/terms_of_usage_toast';
import { TranslationsKeys } from '@translations/translations_keys';

export function AboutAlgarvePage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Navbar shouldUseDarkTheme />
      <section className="algarve">
        <ContentHeader
          subTitle={t(TranslationsKeys.aboutAlgarvePagePreTitle)}
          title={t(TranslationsKeys.aboutAlgarvePageTitle)}
          actionWidget={
            <h1>{t(TranslationsKeys.aboutAlgarvePageDescription)}</h1>
          }
          hasMoreButton={false}
        />
        <div className="algarve__title"></div>
        <img
          src={require('../assets/png/about_algarve/welcome.png')}
          alt="algarve_header"
        />
        <div className="algarve__block">
          <div className="algarve__block-text">
            <span>{t(TranslationsKeys.aboutAlgarvePageHint)}</span>
          </div>
          <img
            src={require('../assets/svg/icons/arrow_down.svg').default}
            alt="arrow_right"
          />
        </div>
      </section>
      <section className="offers">
        {(
          t(TranslationsKeys.aboutAlgarvePageAdvantages, {
            returnObjects: true,
          }) as string[]
        ).map((v, i) => (
          <div
            key={v}
            className={`offers__sample ${i % 2 === 0 ? 'even' : 'odd'} `}
          >
            <div key={v + i} className="offers__title">
              <h3>{t(v[0])}</h3>
              <span>{t(v[1])}</span>
            </div>
            <img
              src={require(`../assets/png/about_algarve/${i + 1}.png`)}
              alt="png"
            />
          </div>
        ))}
      </section>
      <section className="bottom">
        <h2>{t(TranslationsKeys.aboutAlgarvePageTotal)}</h2>
      </section>
      <PreFooter />
      <Footer />
      <TermsOfUsageToast />
      <ButtonCwu />
      <SearchPane />
    </>
  );
}
