import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ButtonCwu } from '@components/button_cwu';
import { Footer } from '@components/footer';
import { Navbar } from '@components/navbar';
import { PreFooter } from '@components/pre_footer';
import { SearchPane } from '@components/search_pane';
import { ServiceItem } from '@components/service_item';
import { TermsOfUsageToast } from '@components/terms_of_usage_toast';
import { useAdvancedNavigator } from '@hooks/advanced_navigator.hook';
import { AppRoutes } from '@project/enums';
import { ServicesActions } from '@store/items/services';
import { TranslationsKeys } from '@translations/translations_keys';

const mapState = ({ services }: RootState) => ({
  recommendations: services.recommendations,
});

const mapDispatch = {
  requestForAll: () => ServicesActions.requestForAll(),
};

function Component({
  recommendations,
  requestForAll,
}: ServicesPageProps): JSX.Element {
  const { t, i18n } = useTranslation();
  const { navigator } = useAdvancedNavigator();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    requestForAll();
  }, []);

  useEffect(() => {
    if (recommendations) {
      setIsLoaded(true);
    }
  }, [recommendations]);

  return (
    <>
      <Navbar />
      <section className="all-services">
        <div className="all-services__bg">
          <img
            src={require('../assets/png/welcome_services.png')}
            alt="welcome to services"
          />
        </div>
        <div className="all-services__fg">
          <h1>{t(TranslationsKeys.servicesPageTitle)}</h1>
        </div>
      </section>
      <section className="services-content">
        {isLoaded
          ? recommendations!.map((service) => (
              <ServiceItem
                key={service.title[i18n.language]}
                data={service}
                onClick={() =>
                  navigator(
                    `/service/${service.slug[i18n.language]}` as AppRoutes,
                  )
                }
              />
            ))
          : null}
      </section>
      <PreFooter />
      <Footer />
      <TermsOfUsageToast />
      <ButtonCwu />
      <SearchPane />
    </>
  );
}

export const ServicesPageConnector = connect(mapState, mapDispatch);

export const ServicesPage = ServicesPageConnector(Component);
