import { useRive, Layout, Fit, Alignment } from '@rive-app/react-canvas';

import { RiveAssets } from '@core/rive_assets';

export function TownFaq(): JSX.Element {
  const { RiveComponent: Animation } = useRive({
    src: RiveAssets.towerBg,
    layout: new Layout({
      fit: Fit.Cover,
      alignment: Alignment.BottomCenter,
    }),
    autoplay: true,
  });

  return (
    <div className="town-faq">
      <Animation />
    </div>
  );
}
