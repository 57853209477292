import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ButtonIcon } from '@components/button_icon';
import { useAdvancedNavigator } from '@hooks/advanced_navigator.hook';
import { AppRoutes } from '@project/enums';
import { CustomersActions } from '@store/items/customers';
import { TranslationsKeys } from '@translations/translations_keys';

const DEFAULT_REVERSE_ANIMATION_IN_MS = 700;

const mapState = ({ customers }: RootState) => ({
  wasAcceptTermsOfUsage: customers.wasAcceptTermsOfUsage,
});

const mapDispatch = {
  requestGetTermsAcceptance: () => CustomersActions.requestGetTermsAcceptance(),
  requestForAcceptTermsOfUsage: () =>
    CustomersActions.requestForAcceptTermsOfUsage(),
};

function Component({
  wasAcceptTermsOfUsage,
  requestGetTermsAcceptance,
  requestForAcceptTermsOfUsage,
}: TermsOfUsageToastProps): Nullable<JSX.Element> {
  const { t } = useTranslation();
  const [isAccepted, setIsAccepted] = useState(false);
  const { navigator } = useAdvancedNavigator();

  useEffect(() => {
    requestGetTermsAcceptance();
  }, []);

  useEffect(() => {
    if (isAccepted) {
      setTimeout(requestForAcceptTermsOfUsage, DEFAULT_REVERSE_ANIMATION_IN_MS);
    }
  }, [isAccepted]);

  if (wasAcceptTermsOfUsage !== null && !wasAcceptTermsOfUsage) {
    return (
      <div className={`tou-toast ${isAccepted ? 'animation-reverse' : ''}`}>
        <img
          src={require('../assets/svg/icons/cookie.svg').default}
          alt={t(TranslationsKeys.touPopupTerms)}
        />
        <span className="tou-toast__label">
          {`${t(TranslationsKeys.touPopupLabel)} `}
          <span
            className="tou-toast__link"
            onClick={() => navigator(AppRoutes.TNC)}
          >
            {t(TranslationsKeys.touPopupTerms)}
          </span>
          {` ${t(TranslationsKeys.touPopupAnd)} `}
          <span
            className="tou-toast__link"
            onClick={() => navigator(AppRoutes.PRIVACY_POLICY)}
          >
            {t(TranslationsKeys.touPopupPrivacyPolicy)}
          </span>
          .
        </span>
        <ButtonIcon
          label={t(TranslationsKeys.touPopupAccept)}
          extraClasses="default-filled-button"
          onClick={setIsAccepted.bind(null, true)}
        />
      </div>
    );
  }

  return null;
}

export const TermsOfUsageToastConnector = connect(mapState, mapDispatch);

export const TermsOfUsageToast = TermsOfUsageToastConnector(Component);
