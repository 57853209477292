import { useTranslation } from 'react-i18next';

import { ButtonCwu } from '@components/button_cwu';
import { Footer } from '@components/footer';
import { Navbar } from '@components/navbar';
import { PreFooter } from '@components/pre_footer';
import { SearchPane } from '@components/search_pane';
import { TermsOfUsageToast } from '@components/terms_of_usage_toast';
import { AppDetails } from '@core/app_details';
import { TranslationsKeys } from '@translations/translations_keys';

export function PrivacyPolicyPage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Navbar shouldUseDarkTheme />
      <article
        className="tou"
        dangerouslySetInnerHTML={{
          __html: t(TranslationsKeys.privacyPolicyPageContent).replaceAll(
            '{{host}}',
            AppDetails.apiHost,
          ),
        }}
      ></article>
      <PreFooter />
      <Footer />
      <TermsOfUsageToast />
      <ButtonCwu />
      <SearchPane />
    </>
  );
}
