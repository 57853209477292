import { useEffect } from 'react';
import { connect } from 'react-redux';

import { AppDetails } from '@core/app_details';
import { AppError, FormInputType, FormName } from '@project/enums';
import { AppActions } from '@store/items/app';

const mapState = ({ app }: RootState) => ({
  hasUiLocked: app.hasUiLocked,
});

const mapDispatch = {
  resetError: (payload: AppError) => AppActions.resetError(payload),
};

function Component({
  label,
  name,
  value,
  resetError,
  onValueChanged,
  hasUiLocked,
  error,
  multiline = false,
  autoResetError = true,
  placeholder = '',
  type = FormInputType.TEXT,
  extraClass,
}: FormInputProps): JSX.Element {
  useEffect(() => {
    if (error && autoResetError) {
      setTimeout(
        resetError.bind(null, error),
        AppDetails.defaultAppearanceDuration,
      );
    }
  }, [error]);

  return (
    <div className={`form-input ${extraClass}`}>
      <span>{label}</span>
      {multiline ? (
        <textarea
          rows={3}
          autoComplete="off"
          spellCheck="false"
          disabled={hasUiLocked}
          className={`${error ? 'error shake' : ''} `}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onValueChanged(
              e.currentTarget.name as FormName,
              e.currentTarget.value,
            )
          }
        />
      ) : (
        <input
          autoComplete="off"
          spellCheck="false"
          type={type}
          disabled={hasUiLocked}
          className={`${error ? 'error shake' : ''} `}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onValueChanged(
              e.currentTarget.name as FormName,
              e.currentTarget.value,
            )
          }
        />
      )}
    </div>
  );
}

export const FormInputComponentConnector = connect(mapState, mapDispatch);

export const FormInput = FormInputComponentConnector(Component);
