import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ArrowBottomIcon } from '@components/icons/arrow_bottom.icon';
import { AppDetails } from '@core/app_details';
import { useAdvancedNavigator } from '@hooks/advanced_navigator.hook';
import { AvailableTranslationLang } from '@project/enums';
import { CustomersActions } from '@store/items/customers';

const mapState = ({ customers }: RootState) => ({
  lang: customers.lang,
});

const mapDispatch = {
  requestUpdateLangDetails: (payload: AvailableTranslationLang) =>
    CustomersActions.requestUpdateLangDetails(payload),
};

function Component({
  lang,
  requestUpdateLangDetails,
  shouldUseDarkTheme = false,
}: LangDropDownProps): JSX.Element {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);
  const { navigator } = useAdvancedNavigator();

  useEffect(() => {
    if (currentLang !== i18n.language) {
      requestUpdateLangDetails(currentLang as AvailableTranslationLang);
      navigator(null, currentLang as AvailableTranslationLang);
    }
  }, [currentLang]);

  return (
    <div className={`lang-dd ${shouldUseDarkTheme ? 'dark' : ''}`}>
      <div className="lang-dd__current">
        <img src={lang?.icon} alt={lang?.code} className="lang-dd__flag" />
        <span>{lang?.original}</span>
        <ArrowBottomIcon shouldUseDarkTheme={shouldUseDarkTheme} />
      </div>
      <div className="lang-dd__list">
        {AppDetails.langs
          .filter((l) => l.code !== lang?.code)
          .map((lang, i) => (
            <div
              key={lang.code + i}
              className="lang-dd__item"
              onClick={setCurrentLang.bind(null, lang.code)}
            >
              <img
                src={lang?.icon}
                alt={lang?.code}
                className="lang-dd__flag"
              />
              <span>{lang?.original}</span>
            </div>
          ))}
      </div>
    </div>
  );
}

export const LangDropDownConnector = connect(mapState, mapDispatch);

export const LangDropDown = LangDropDownConnector(Component);
