export function LabelIcon({
  data,
  icon,
  label,
  extraLabel,
}: LabelIconProps): JSX.Element {
  return (
    <div className="label-icon">
      <img src={icon} alt={label} />
      <span>
        {label} - <b>{`${data} ${extraLabel ? extraLabel : ''}`}</b>
      </span>
    </div>
  );
}
