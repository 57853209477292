import { call, put } from 'redux-saga/effects';

import { ApiRequests } from '@core/api_requests';
import { AppError, AppLoader } from '@project/enums';
import { AppActions } from '@store/items/app';
import { PropertiesActions } from '@store/items/properties';

export function* getPriorityProperties() {
  try {
    const properties: PropertyPreview[] = yield call(
      ApiRequests.getPriorityPropertiesPreview,
    );

    yield put(PropertiesActions.setData(properties));
  } catch (e: Exception) {
    yield put(
      AppActions.setError(AppError.UNABLE_TO_GET_PRIORITY_PROPERTY_PREVIEWS),
    );
  }
}

export function* getAllProperties() {
  try {
    const properties: PropertyPreview[] = yield call(
      ApiRequests.getAllPropertiesPreview,
    );

    yield put(PropertiesActions.setData(properties));
  } catch (e: Exception) {
    yield put(
      AppActions.setError(AppError.UNABLE_TO_GET_ALL_PROPERTY_PREVIEWS),
    );
  }
}

export function* getExactProperty(action: DispatchWithPayload<number>) {
  try {
    const property: ExactProperty = yield call(
      ApiRequests.getExactProperty,
      action.payload,
    );

    yield put(PropertiesActions.setExact(property));
  } catch (e: Exception) {
    yield put(
      AppActions.setError(AppError.UNABLE_TO_GET_EXACT_PROPERTY_PREVIEWS),
    );
  }
}

export function* getFavoritesProperty(action: DispatchWithPayload<number[]>) {
  try {
    yield put(AppActions.setLoader(AppLoader.FAVORITES_PROPERTIES_LOADING));

    const properties: PropertyPreview[] = yield call(
      ApiRequests.getFavoritesProperty,
      action.payload,
    );

    yield put(PropertiesActions.setFavorites(properties));
  } catch (e: Exception) {
    yield put(
      AppActions.setError(AppError.UNABLE_TO_GET_FAVORITES_PROPERTY_PREVIEWS),
    );
  }

  yield put(AppActions.resetLoader(AppLoader.FAVORITES_PROPERTIES_LOADING));
}
