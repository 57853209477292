export function ButtonIcon({
  label,
  icon,
  widget,
  onClick,
  extraClasses = 'default-filled-button',
}: ButtonIconProps): JSX.Element {
  return (
    <div
      className={`button-icon ${extraClasses}`}
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick(null);
      }}
    >
      <span>{label}</span>
      {icon ? <img src={icon} alt={label} /> : widget}
    </div>
  );
}
