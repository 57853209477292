import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ButtonCwu } from '@components/button_cwu';
import { ButtonSba } from '@components/button_sba';
import { Footer } from '@components/footer';
import { Navbar } from '@components/navbar';
import { PreFooter } from '@components/pre_footer';
import { PropertyItem } from '@components/property_item';
import { SearchPane } from '@components/search_pane';
import { TermsOfUsageToast } from '@components/terms_of_usage_toast';
import { useAdvancedNavigator } from '@hooks/advanced_navigator.hook';
import { AppLoader, AppRoutes, LocalStorageKey } from '@project/enums';
import { PropertiesActions } from '@store/items/properties';
import { TranslationsKeys } from '@translations/translations_keys';

const mapState = ({ properties, app }: RootState) => ({
  favorites: properties.favorites,
  wasLoaded: !app.loaders.includes(AppLoader.FAVORITES_PROPERTIES_LOADING),
});

const mapDispatch = {
  requestForFavorites: (ids: number[]) =>
    PropertiesActions.requestForFavorites(ids),
};

function Component({
  favorites,
  wasLoaded,
  requestForFavorites,
}: FavoritePropertiesPageProps): Nullable<JSX.Element> {
  const { t } = useTranslation();
  const { navigator } = useAdvancedNavigator();

  useEffect(() => {
    const data = localStorage.getItem(LocalStorageKey.FAVORITES);
    const ids = data ? JSON.parse(data) : [];

    if (ids.length) {
      requestForFavorites(ids);
    }
  }, []);

  if (!wasLoaded) {
    return null;
  }

  return (
    <>
      <Navbar shouldUseDarkTheme />
      <section className="properties">
        <h2 className={`properties__favorite ${!favorites ? 'no-data' : ''}`}>
          {t(TranslationsKeys.favoritePropertiesTitle)}
        </h2>
        {favorites ? (
          favorites!.map((property, i) => (
            <PropertyItem
              key={property.title}
              data={property}
              positionAtList={i + 1}
            />
          ))
        ) : (
          <div className="properties__no-data">
            <img
              src={
                require('../assets/svg/no_favorite_data_properties.svg').default
              }
              alt="send one"
            />
            <span>{t(TranslationsKeys.favoritePropertiesLabel)}</span>
            <ButtonSba
              onClick={navigator.bind(null, AppRoutes.PROPERTIES, null)}
              label={t(TranslationsKeys.mainPageWelcomePropertyGallery)}
            />
          </div>
        )}
      </section>
      <PreFooter />
      <Footer />
      <TermsOfUsageToast />
      <ButtonCwu />
      <SearchPane />
    </>
  );
}

export const FavoritePropertiesPageConnector = connect(mapState, mapDispatch);

export const FavoritePropertiesPage =
  FavoritePropertiesPageConnector(Component);
