import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function CommonQuestionItem({
  value,
  answer,
}: CommonQuestionItemProps): JSX.Element {
  const { i18n } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);

  return (
    <article className="faq-item">
      <header onClick={setIsOpened.bind(null, !isOpened)}>
        <h2>{value[i18n.language]}</h2>
        <img
          className={isOpened ? 'expanded' : ''}
          src={require('../assets/svg/icons/arrow_expandable.svg').default}
          alt="expand"
        />
      </header>
      {isOpened ? (
        <section
          dangerouslySetInnerHTML={{
            __html: answer[i18n.language],
          }}
        ></section>
      ) : null}
    </article>
  );
}
