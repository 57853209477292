import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ServiceItem } from '@components/service_item';
import { useAdvancedNavigator } from '@hooks/advanced_navigator.hook';
import { AppLoader, AppRoutes } from '@project/enums';
import { AppActions } from '@store/items/app';
import { ServicesActions } from '@store/items/services';
import { TranslationsKeys } from '@translations/translations_keys';

const mapState = ({ app, services }: RootState) => ({
  data: services.recommendations,
  wasLoaded: !app.loaders.includes(AppLoader.SERVICE_RECOMMENDATIONS_LOADING),
});

const mapDispatch = {
  resumeLoader: () =>
    AppActions.setLoader(AppLoader.SERVICE_RECOMMENDATIONS_LOADING),
  requestForGetRecommendations: (payload: GetServicesRecommendationsDto) =>
    ServicesActions.requestForGetRecommendations(payload),
};

function Component({
  data,
  wasLoaded,
  requestForGetRecommendations,
  resumeLoader,
}: ServicesRecommendationProps): Nullable<JSX.Element> {
  const { t, i18n } = useTranslation();
  const { navigator } = useAdvancedNavigator();

  useEffect(() => {
    const paths = location.pathname.split('/').filter((v) => v.trim());
    const [slug] = paths.slice(paths.length - 1);

    requestForGetRecommendations({
      slug,
    });

    return () => {
      resumeLoader();
    };
  }, []);

  if (wasLoaded) {
    return (
      <div className="services-recommendation">
        <h4>{t(TranslationsKeys.servicesRecommendationWidgetTitle)}</h4>
        <div className="services-recommendation__content">
          {wasLoaded
            ? data!.map((service) => (
                <ServiceItem
                  key={service.title[i18n.language]}
                  data={service}
                  onClick={() =>
                    navigator(
                      `/service/${service.slug[i18n.language]}` as AppRoutes,
                    )
                  }
                />
              ))
            : null}
        </div>
      </div>
    );
  }

  return null;
}

export const ServicesRecommendationConnector = connect(mapState, mapDispatch);

export const ServicesRecommendation =
  ServicesRecommendationConnector(Component);
