import { Alignment, Fit, Layout, useRive } from '@rive-app/react-canvas';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { RiveAssets } from '@core/rive_assets';
import { CustomersActions } from '@store/items/customers';

const mapState = ({ customers }: RootState) => ({
  favoritesProperties: customers.favoritesProperties,
});

const mapDispatch = {
  requestForAddFavorite: (id: number) =>
    CustomersActions.requestForAddFavorite(id),
  requestForDeleteFavorite: (id: number) =>
    CustomersActions.requestForDeleteFavorite(id),
};

function Component({
  id,
  favoritesProperties,
  requestForAddFavorite,
  requestForDeleteFavorite,
}: ButtonFavoriteProps): JSX.Element {
  const [isActive, setIsActive] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const { RiveComponent: Animation, rive } = useRive({
    src: RiveAssets.favoriteIcon,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
    autoplay: false,
  });

  const onToggle = () => {
    const value = !isActive;

    setIsActive(value);
    rive?.play(value.toString());

    value ? requestForAddFavorite(id) : requestForDeleteFavorite(id);
  };

  useEffect(() => {
    if (favoritesProperties) {
      setIsLoaded(true);
    }
  }, [favoritesProperties]);

  useEffect(() => {
    if (isLoaded && rive) {
      const isInFavorites = favoritesProperties.includes(id);

      setIsActive(isInFavorites);

      if (isInFavorites) {
        rive?.play(isInFavorites.toString());
      }
    }
  }, [isLoaded, rive]);

  return (
    <div className="button-favorite" onClick={onToggle}>
      <Animation />
    </div>
  );
}

export const ButtonFavoriteConnector = connect(mapState, mapDispatch);

export const ButtonFavorite = ButtonFavoriteConnector(Component);
