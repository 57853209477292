import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ButtonCwu } from '@components/button_cwu';
import { Footer } from '@components/footer';
import { Navbar } from '@components/navbar';
import { PreFooter } from '@components/pre_footer';
import { ServicesRecommendation } from '@components/services_recommendation';
import { ShareContentPopup } from '@components/share_content_popup';
import { AppDetails } from '@core/app_details';
import { useAdvancedNavigator } from '@hooks/advanced_navigator.hook';
import { AppError, AppLoader, AppRoutes, CommonDomEvent } from '@project/enums';
import { AppActions } from '@store/items/app';
import { ServicesActions } from '@store/items/services';

const mapState = ({ app, services }: RootState) => ({
  data: services.exact,
  wasLoaded: !app.loaders.includes(AppLoader.EXACT_SERVICE_LOADING),
  hasError:
    app.errors.filter(
      (error) => error === AppError.UNABLE_TO_GET_EXACT_SERVICE,
    )[0] ?? null,
});

const mapDispatch = {
  resetError: () => AppActions.resetError(AppError.UNABLE_TO_GET_EXACT_SERVICE),
  resumeLoader: () => AppActions.setLoader(AppLoader.EXACT_SERVICE_LOADING),
  requestForGetExact: (payload: GetExactServiceDto) =>
    ServicesActions.requestForGetExact(payload),
};

function Component({
  data,
  wasLoaded,
  hasError,
  resumeLoader,
  resetError,
  requestForGetExact,
}: ServicePageProps): Nullable<JSX.Element> {
  const { i18n } = useTranslation();
  const { navigator } = useAdvancedNavigator();
  const location = useLocation();

  const onScroll = () => {
    const statics = document.querySelectorAll('.static');
    const { innerHeight } = window;

    for (const item of statics) {
      const { top } = item.getBoundingClientRect();

      if (top < innerHeight) {
        item.classList.add('animation');
      }
    }
  };

  useEffect(() => {
    window.addEventListener(CommonDomEvent.SCROLL, onScroll);

    const paths = location.pathname.split('/').filter((v) => v.trim());
    const [slug] = paths.slice(paths.length - 1);

    requestForGetExact({
      slug,
    });

    return () => {
      resumeLoader();
      window.removeEventListener(CommonDomEvent.SCROLL, onScroll);
    };
  }, []);

  useEffect(() => {
    if (hasError) {
      navigator(AppRoutes.ROOT);
      resetError();
      resumeLoader();
    }
  }, [hasError]);

  useEffect(() => {
    if (wasLoaded) {
      onScroll();
    }
  }, [wasLoaded]);

  useEffect(() => {
    const paths = location.pathname.split('/').filter((v) => v.trim());
    const [slug] = paths.slice(paths.length - 1).map((v) => decodeURI(v));

    if (data) {
      const hasChanged = !Object.values(data!.slug).includes(slug);

      if (hasChanged) {
        resumeLoader();

        const paths = location.pathname.split('/').filter((v) => v.trim());
        const [slug] = paths.slice(paths.length - 1);

        requestForGetExact({
          slug,
        });
      }
    }
  }, [location]);

  if (wasLoaded) {
    return (
      <>
        <Navbar shouldUseDarkTheme />
        <ShareContentPopup message={data!.title[i18n.language]} />
        <article className="service-content">
          <h1 className="static">{data!.title[i18n.language]}</h1>
          <img
            className="service-content__cover static"
            src={`${AppDetails.apiHost}services/${data!.id}/cover.png`}
            alt={`cover of ${data!.title[i18n.language]}`}
          />
          <section
            className="service-content__data static"
            dangerouslySetInnerHTML={{
              __html: data!.content[i18n.language],
            }}
          ></section>
        </article>
        <ServicesRecommendation />
        <PreFooter />
        <Footer />
        <ButtonCwu />
      </>
    );
  }

  return null;
}

export const ServicePageConnector = connect(mapState, mapDispatch);

export const ServicePage = ServicePageConnector(Component);
