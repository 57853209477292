import { call, put } from 'redux-saga/effects';

import { showToast } from '@components/toast';
import { ApiRequests } from '@core/api_requests';
import { AppError, AppLoader, MessageType } from '@project/enums';
import { AppActions } from '@store/items/app';
import { ArticlesActions } from '@store/items/articles';
import { TranslationsModule } from '@translations/module';
import { TranslationsKeys } from '@translations/translations_keys';

export function* getAllNews() {
  yield put(AppActions.setLoader(AppLoader.ARTICLE_RECOMMENDATIONS_LOADING));

  try {
    const articlesPreviews: PriorityArticlePreview[] = yield call(
      ApiRequests.getAllNews,
    );

    yield put(ArticlesActions.setRecommendations(articlesPreviews));
  } catch (e: Exception) {
    yield put(
      AppActions.setError(AppError.UNABLE_TO_GET_RECOMMENDATIONS_ARTICLES),
    );
  }

  yield put(AppActions.resetLoader(AppLoader.ARTICLE_RECOMMENDATIONS_LOADING));
}

export function* getPriorityNews() {
  try {
    const priorityNews: PriorityNews = yield call(ApiRequests.getPriorityNews);

    yield put(ArticlesActions.setPriorityNews(priorityNews));
  } catch (e: Exception) {
    try {
      const messages = e.split(',').map((v: string) => v.trim());

      messages.length
        ? messages.forEach((v: string) =>
            showToast({
              type: MessageType.ERROR,
              message: TranslationsModule.t(
                TranslationsKeys.dynamicApiMessages(v),
              ),
            }),
          )
        : showToast({
            type: MessageType.ERROR,
            message: e as string,
          });

      yield put(AppActions.setError(AppError.UNABLE_TO_GET_PRIORITY_NEWS));
    } catch (e: Exception) {
      showToast({
        type: MessageType.ERROR,
        message: TranslationsModule.t(
          TranslationsKeys.dynamicApiMessages('INTERNAL_ERROR'),
        ),
      });
    }
  }
}

export function* getExactArticle(
  action: DispatchWithPayload<GetExactArticleDto>,
) {
  yield put(AppActions.setLoader(AppLoader.EXACT_ARTICLE_LOADING));

  try {
    yield put(AppActions.resetDynamicPath());

    const article: ExactArticle = yield call(
      ApiRequests.getExactArticle,
      action.payload,
    );

    const dynamicPaths: MapValueToTranslation = {};
    const langCodes = Object.keys(article.slug);

    for (const langCode of langCodes) {
      dynamicPaths[
        langCode
      ] = `${article.categorySlug[langCode]}/${article.slug[langCode]}`;
    }

    yield put(AppActions.setDynamicPath(dynamicPaths));
    yield put(ArticlesActions.setExactArticle(article));
  } catch (e: Exception) {
    yield put(AppActions.setError(AppError.UNABLE_TO_GET_EXACT_ARTICLE));
  }

  yield put(AppActions.resetLoader(AppLoader.EXACT_ARTICLE_LOADING));
}

export function* getArticlesRecommendations(
  action: DispatchWithPayload<GetArticlesRecommendationsDto>,
) {
  yield put(AppActions.setLoader(AppLoader.ARTICLE_RECOMMENDATIONS_LOADING));

  try {
    const articlesPreviews: PriorityArticlePreview[] = yield call(
      ApiRequests.getArticlesRecommendation,
      action.payload,
    );

    yield put(ArticlesActions.setRecommendations(articlesPreviews));
  } catch (e: Exception) {
    yield put(
      AppActions.setError(AppError.UNABLE_TO_GET_RECOMMENDATIONS_ARTICLES),
    );
  }

  yield put(AppActions.resetLoader(AppLoader.ARTICLE_RECOMMENDATIONS_LOADING));
}
