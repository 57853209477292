import { useEffect, useState } from 'react';

export function ReviewRate({ value }: ReviewRateProps): JSX.Element {
  const [rate, setRate] = useState(0);

  useEffect(() => {
    if (value >= 5) {
      setRate(100);
      return;
    }

    if (value <= 0) {
      setRate(0);
      return;
    }

    setRate((value * 100) / 5);
  }, [value]);

  return (
    <div className="rate">
      <div className="rate__active" style={{ width: `${rate.toFixed(2)}%` }}>
        <span>★★★★★</span>
      </div>
      <div className="rate__layout">
        <span>★★★★★</span>
      </div>
    </div>
  );
}
