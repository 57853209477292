export function EmailIcon({ extraClasses }: EmailIconProps): JSX.Element {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={extraClasses}
    >
      <path
        d="M1.5 14.625H16.5V9V3.375H9H1.5V9V14.625Z"
        stroke="#A09B97"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 3.375L9 9L16.5 3.375"
        stroke="#A09B97"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3.375H1.5V9"
        stroke="#A09B97"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 9V3.375H9"
        stroke="#A09B97"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
