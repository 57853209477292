import { call, put } from 'redux-saga/effects';

import { ApiRequests } from '@core/api_requests';
import { AppError } from '@project/enums';
import { AppActions } from '@store/items/app';
import { ReviewsActions } from '@store/items/reviews';

export function* getLatestReviews() {
  try {
    const reviews: CustomerReview[] = yield call(ApiRequests.getLatestReviews);

    yield put(ReviewsActions.setLatest(reviews));
  } catch (e: Exception) {
    yield put(AppActions.setError(AppError.UNABLE_TO_GET_LATEST_REVIEWS));
  }
}
