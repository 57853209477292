import { RouterAccessStatus } from '@project/enums';
import { defaultRouter } from '@routing/default.router';

export function useRoutes(accessStatus: RouterAccessStatus): JSX.Element {
  switch (accessStatus) {
    default:
    case RouterAccessStatus.UNAUTHORIZED:
      return defaultRouter();
  }
}
