/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { AskForViewing } from '@components/ask_for_viewing';
import { ButtonCwu } from '@components/button_cwu';
import { ButtonFavorite } from '@components/button_favorite';
import { ButtonIcon } from '@components/button_icon';
import { Footer } from '@components/footer';
import { CopyIcon } from '@components/icons/copy.icon';
import { DownloadIcon } from '@components/icons/download.icon';
import { EmailIcon } from '@components/icons/email.icon';
import { PrintIcon } from '@components/icons/print.icon';
import { LabelIcon } from '@components/label_icon';
import { Navbar } from '@components/navbar';
import { PreFooter } from '@components/pre_footer';
import { PropertyFeatures } from '@components/property_features';
import { SearchPane } from '@components/search_pane';
import { ServicesRecommendation } from '@components/services_recommendation';
import { ShareContentPopup } from '@components/share_content_popup';
import { TermsOfUsageToast } from '@components/terms_of_usage_toast';
import { showToast } from '@components/toast';
import { AppDetails } from '@core/app_details';
import { AppPassedAction, MessageType, PropertyType } from '@project/enums';
import { PropertiesActions } from '@store/items/properties';
import { TranslationsKeys } from '@translations/translations_keys';

const mapState = ({ app, properties }: RootState) => ({
  data: properties.exact,
  wasPassed: app.passedActions.includes(
    AppPassedAction.FORM_FOR_REQUEST_WAS_SENT,
  ),
});

const mapDispatch = {
  requestForExact: (id: number) => PropertiesActions.requestForExact(id),
};

const mapEnergyValueToName = {
  '1': 'A+++',
  '2': 'A++',
  '3': 'A+',
  '4': 'A',
  '5': 'B',
  '6': 'C',
  '7': 'D',
  '8': 'E',
  '9': 'F',
  '10': 'G',
};

const mapEnergyValueToColor = {
  '1': 'a_three_plus',
  '2': 'a_two_plus',
  '3': 'a_one_plus',
  '4': 'a',
  '5': 'b',
  '6': 'c',
  '7': 'd',
  '8': 'e',
  '9': 'f',
  '10': 'g',
};

function Component({
  data: fetchedData,
  requestForExact,
  wasPassed,
}: PropertyPageProps): Nullable<JSX.Element> {
  const { t, i18n } = useTranslation();

  const [isOpened, setIsOpened] = useState(false);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [data, setData] = useState<Nullable<ExactProperty>>(null);
  const [type, setType] = useState<Nullable<PropertyType>>(null);
  const [label, setLabel] = useState<Nullable<string>>(null);
  const [hasSold, setHasSold] = useState<Nullable<boolean>>(null);

  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    const paths = location.pathname.split('/').filter((v) => v.trim());
    const [id] = paths.slice(paths.length - 1);

    requestForExact(+id);
  }, []);

  const next = () => {
    // @ts-ignore
    sliderRef1.slickNext();
  };

  const previous = () => {
    // @ts-ignore
    sliderRef1.slickPrev();
  };

  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData);
      setType(
        fetchedData.priceWithDiscount
          ? PropertyType.SALE
          : fetchedData.isExternal
          ? PropertyType.NEW
          : PropertyType.NEW, // PropertyType.EXCLUSIVE
      );

      if (fetchedData.maxPreviewAmount > 1 && fetchedData.price === null) {
        setLabel('sold');
        setHasSold(true);
      } else if (fetchedData.maxPreviewAmount <= 1) {
        setLabel('secret property');
      }

      if (fetchedData.id === 8) {
        setLabel('reduced');
      }
    }
  }, [fetchedData]);

  useEffect(() => {
    if (wasPassed) {
      setIsOpened(false);
    }
  }, [wasPassed]);

  useEffect(() => {
    if (data) {
      setNav1(sliderRef1 as any);
      setNav2(sliderRef2 as any);
    }
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <>
      <Navbar shouldUseDarkTheme />
      {isOpened ? (
        <AskForViewing
          onClose={setIsOpened.bind(null, false)}
          propertyId={data.id}
        />
      ) : null}
      <section className="property-area">
        <div className="property-area__header">
          {hasSold ? (
            <header className="property-area__image-highlight">
              <h4>SOLD</h4>
            </header>
          ) : null}
          {data.maxPreviewAmount > 1 ? (
            <>
              <div
                className="property-area__controller left"
                onClick={previous}
              >
                <img
                  src={
                    require('../assets/svg/icons/slider_arrow_left.svg').default
                  }
                  alt="left"
                />
              </div>
              <div className="property-area__controller right" onClick={next}>
                <img
                  src={
                    require('../assets/svg/icons/slider_arrow_right.svg')
                      .default
                  }
                  alt="right"
                />
              </div>
            </>
          ) : null}
          {data.maxPreviewAmount > 1 ? (
            <>
              <Slider
                className="property-area__slider"
                asNavFor={nav2 as any}
                ref={(slider) => (sliderRef1 = slider as any)}
                arrows={false}
                slidesToShow={3}
                centerMode={true}
                variableWidth={true}
                infinite={true}
                responsive={[
                  {
                    breakpoint: 900,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: true,
                      dots: false,
                      adaptiveHeight: true,
                    },
                  },
                ]}
              >
                {[...Array(data.maxPreviewAmount).keys()]
                  .map((i) => i + 1)
                  .map((fileName) => (
                    <img
                      key={
                        `${AppDetails.apiHost}` +
                        `properties/${data.id}/${fileName}.jpg`
                      }
                      src={
                        `${AppDetails.apiHost}` +
                        `properties/${data.id}/${fileName}.jpg`
                      }
                      alt={`${data.title} - ${fileName}`}
                    />
                  ))}
              </Slider>
              <Slider
                asNavFor={nav1 as any}
                ref={(slider) => (sliderRef2 = slider as any)}
                slidesToShow={10}
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
                infinite={true}
                className="property-area__slider-navigation"
                responsive={[
                  {
                    breakpoint: 1800,
                    settings: {
                      slidesToShow: 9,
                    },
                  },
                  {
                    breakpoint: 1600,
                    settings: {
                      slidesToShow: 8,
                    },
                  },
                  {
                    breakpoint: 1400,
                    settings: {
                      slidesToShow: 7,
                    },
                  },
                  {
                    breakpoint: 1400,
                    settings: {
                      slidesToShow: 6,
                    },
                  },
                  {
                    breakpoint: 1100,
                    settings: {
                      slidesToShow: 5,
                    },
                  },
                  {
                    breakpoint: 800,
                    settings: {
                      slidesToShow: 4,
                    },
                  },
                  {
                    breakpoint: 550,
                    settings: {
                      slidesToShow: 3,
                    },
                  },
                  {
                    breakpoint: 350,
                    settings: {
                      slidesToShow: 2,
                    },
                  },
                ]}
              >
                {[...Array(data.maxPreviewAmount).keys()]
                  .map((i) => i + 1)
                  .map((fileName) => (
                    <img
                      key={
                        `${AppDetails.apiHost}` +
                        `properties/${data.id}/${fileName}.jpg`
                      }
                      src={
                        `${AppDetails.apiHost}` +
                        `properties/${data.id}/${fileName}.jpg`
                      }
                      alt={`${data.title} - ${fileName}`}
                    />
                  ))}
              </Slider>
            </>
          ) : (
            <img
              key={`${AppDetails.apiHost}properties/${data.id}/1.jpg`}
              src={`${AppDetails.apiHost}properties/${data.id}/1.jpg`}
              alt={`${data.title} - 1`}
            />
          )}
        </div>
        <hr className="header-top" />
        <div className="property-area__title">
          <div className="property-area__header-details">
            <span>
              {label
                ? label
                : t(TranslationsKeys.propertyPreviewWidgetTypeLabels(type!))}
            </span>
            <div className="property-area__info-header">
              <h1>{data.title}</h1>
              <span>
                REF — {`${data.isExternal ? 'EXT_' : 'ROSO_'}${data.id}`}
              </span>
            </div>
          </div>
          <div className="property-area__cost new">
            {data.price ? (
              <span>{`${`${data.price}`.toPrice()} ${data.currencyCode}`}</span>
            ) : null}
            {label === 'secret property' && data.id === 16 ? (
              <span className={`property__price ${type}`}>POA</span>
            ) : null}
            <div className="property-area__info-button">
              <img
                src={require('../assets/svg/icons/info.svg').default}
                alt="info"
              />
              <div className="property-area__info">
                <div className="property-area__info__details">
                  <div className="triangle"></div>
                  <div
                    className="property-area__popup"
                    dangerouslySetInnerHTML={{
                      __html: t(TranslationsKeys.propertyPageTaxDetails),
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <ButtonFavorite id={data.id} />
          </div>
        </div>
        <hr className="header-bottom" />

        <div className="property-area__details">
          <div className="property-area__details__description">
            <div className="property-area__controllers">
              <ButtonIcon
                label={t(
                  TranslationsKeys.propertyPreviewWidgetControllersPrint,
                )}
                extraClasses="default-outline-button"
                widget={<PrintIcon />}
                onClick={() =>
                  // @ts-ignore
                  printJS(
                    `${AppDetails.apiHost}properties/${data.id}` +
                      `/presentation.pdf`,
                  )
                }
              />
              <ButtonIcon
                label={t(
                  TranslationsKeys.propertyPreviewWidgetControllersCopyLink,
                )}
                extraClasses="default-outline-button"
                widget={<CopyIcon />}
                onClick={() => {
                  window.navigator.clipboard.writeText(location.href);

                  showToast({
                    type: MessageType.SUCCESS,
                    message: t(TranslationsKeys.toasterMessagesLinkWasCopied),
                  });
                }}
              />
              <ButtonIcon
                label={t(
                  TranslationsKeys.propertyPreviewWidgetControllersPresentation,
                )}
                extraClasses="default-outline-button"
                widget={<DownloadIcon />}
                onClick={() =>
                  fetch(
                    `${AppDetails.apiHost}properties/${data.id}/presentation.pdf`,
                  ).then((response) => {
                    response.blob().then((blob) => {
                      let url = window.URL.createObjectURL(blob);
                      let a = document.createElement('a');
                      a.href = url;
                      a.download = data.title;
                      a.click();
                    });
                  })
                }
              />
              <ButtonIcon
                label={t(
                  // eslint-disable-next-line max-len
                  TranslationsKeys.propertyPreviewWidgetControllersAskForViewing,
                )}
                extraClasses="default-outline-button"
                widget={<EmailIcon />}
                onClick={setIsOpened.bind(null, true)}
              />
            </div>
            <div
              className="property-area__description"
              dangerouslySetInnerHTML={{
                __html: data.description[i18n.language],
              }}
            ></div>
            <div className="property-area__features">
              <h2>{t(TranslationsKeys.propertyPageFeaturesTitle)}</h2>
              <div className="property-area__features-grid">
                {data.hasWashingMachine ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesWashingMachine,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/washing_machine.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasDishwasher ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesDishwasher)}
                    icon={
                      require('../assets/svg/icons/property_features/dishwasher.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasAirConditioning ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesAirConditioning,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/air_conditioning.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasFittedWardrobes ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesFittedWardrobes,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/fitted_wardrobes.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasUnderfloorHeating ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesUnderfloorHeating,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/underfloor_heating.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasWalkInWardRope ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesWalkInWardRope,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/walk_in_wardrope.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasCinemaRoom ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesTelevisionRoom,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/television_room.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasFeatureFireplace ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesFeatureFireplace,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/feature_fireplace.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasOpenPlanLivingArea ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesOpenPlanLivingArea,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/open_plan_living_area.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasVideoEntrySystem ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesVideoEntrySystem,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/video_entry_system.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasLift ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesLift)}
                    icon={
                      require('../assets/svg/icons/property_features/lift.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasTennisCourt ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesTennisCourt)}
                    icon={
                      require('../assets/svg/icons/property_features/tennis_court.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasElectricPoolCover ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesElectricPoolCover,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/electric_pool_cover.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasLargePlot ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesLargePlot)}
                    icon={
                      require('../assets/svg/icons/property_features/large_plot.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasSouthFacingTerraces ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesSouthFacingTerraces,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/south_facing_terraces.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasCoveredAlFrescoDining ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesCoveredAlFrescoDining,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/covered_al_fresco_dining.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasLandScapedGardens ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesLandscapedGardens,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/landscaped_gardens.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasExteriorEntertainingLounge ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesExteriorEntertainingLounge,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/exterior_entertaining_lounge.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasLaundry ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesLaundry)}
                    icon={
                      require('../assets/svg/icons/property_features/laundry.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasDriveWay ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesDriveWay)}
                    icon={
                      require('../assets/svg/icons/property_features/drive_way.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasBasement ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesBasement)}
                    icon={
                      require('../assets/svg/icons/property_features/basement.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasGreatRentalPotential ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesGreatRentalPotential,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/great_rental_potential.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasViewToSea ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesViewToSea)}
                    icon={
                      require('../assets/svg/icons/property_features/view_to_sea.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasAnimals ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesAnimals)}
                    icon={
                      require('../assets/svg/icons/property_features/animals.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasPond ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesPond)}
                    icon={
                      require('../assets/svg/icons/property_features/pond.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasChildrenGround ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesChildrenGround,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/children_ground.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasOrangePlantation ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesOrangePlantation,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/orange_plantation.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasCarport ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesCarport)}
                    icon={
                      require('../assets/svg/icons/property_features/carport.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasPadelCourt ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesPadelCourt)}
                    icon={
                      require('../assets/svg/icons/property_features/padel_court.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasJacuzzi ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesJacuzzi)}
                    icon={
                      require('../assets/svg/icons/property_features/jacuzzi.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasAreaForBBQ ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesBbqArea)}
                    icon={
                      require('../assets/svg/icons/property_features/bbq_area.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasCommunalPool ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesCommunalPool)}
                    icon={
                      require('../assets/svg/icons/property_features/communal_pool.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasGatedArea ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesGatedArea)}
                    icon={
                      require('../assets/svg/icons/property_features/gated_area.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasIBC ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesIbc)}
                    icon={
                      require('../assets/svg/icons/property_features/ibc.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasHeatedPool ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesHeatedPool)}
                    icon={
                      require('../assets/svg/icons/property_features/hit_pool.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasGym ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesGym)}
                    icon={
                      require('../assets/svg/icons/property_features/gym.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasCentralMusicSystem ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesCentralMusicSystem,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/central_music_system.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasCentralLocation ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesCentralMusicSystem,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/central_location.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasSolarHeating ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesSolarHeating)}
                    icon={
                      require('../assets/svg/icons/property_features/solar_heating.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasBalcony ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesBalcony)}
                    icon={
                      require('../assets/svg/icons/property_features/balcony.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasElectricShutters ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesElectricShutters,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/electric_shutters.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasHomeOffice ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesHomeOffice)}
                    icon={
                      require('../assets/svg/icons/property_features/home_office.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasMultipleBespoke ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesMultipleBespoke,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/multiple_bespoke.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasFurnished ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesFurnished)}
                    icon={
                      require('../assets/svg/icons/property_features/furnished.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasReception ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesFurnished)}
                    icon={
                      require('../assets/svg/icons/property_features/reception.svg')
                        .default
                    }
                  />
                ) : null}
                {data.yearOfBuild ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesFurnished, {
                      value: data.yearOfBuild,
                    })}
                    icon={
                      require('../assets/svg/icons/property_features/year_building.svg')
                        .default
                    }
                  />
                ) : null}
                {data.solarOrientation ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesSolarOrientation,
                      {
                        value:
                          TranslationsKeys.propertyPageFeaturesSolarOrientationValues(
                            data.solarOrientation,
                          ),
                      },
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/solar_orientation.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasWiFi ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesWiFi)}
                    icon={
                      require('../assets/svg/icons/property_features/wi_fi.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasBar ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesBar)}
                    icon={
                      require('../assets/svg/icons/property_features/bar.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasInsidePool ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesInsidePool)}
                    icon={
                      require('../assets/svg/icons/property_features/inside_pool.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasSauna ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesSauna)}
                    icon={
                      require('../assets/svg/icons/property_features/sauna.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasOven ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesOven)}
                    icon={
                      require('../assets/svg/icons/property_features/oven.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasDoubleGlazed ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesDoubleGlazed)}
                    icon={
                      require('../assets/svg/icons/property_features/double_glazed.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasMicrowave ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesMicrowave)}
                    icon={
                      require('../assets/svg/icons/property_features/microwave.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasOutdoorParking ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesOutdoorParking,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/outdoor_parking.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasRoofTop ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesRoofTop)}
                    icon={
                      require('../assets/svg/icons/property_features/roof_top.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasShutters ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesShutters)}
                    icon={
                      require('../assets/svg/icons/property_features/shutters.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasSmartDoorKey ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesSmartDoorKey)}
                    icon={
                      require('../assets/svg/icons/property_features/smartdoor_key.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasParkingSpace ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesParkingSpace)}
                    icon={
                      require('../assets/svg/icons/property_features/parking_space.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasSecurityAlarm ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesSecurityAlarm,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/security_alarm.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasTerrace ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesTerrace)}
                    icon={
                      require('../assets/svg/icons/property_features/terrace.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasPrivateCondominium ? (
                  <PropertyFeatures
                    label={t(
                      TranslationsKeys.propertyPageFeaturesPrivateCondominium,
                    )}
                    icon={
                      require('../assets/svg/icons/property_features/private_condominium.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasFridge ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesFridge)}
                    icon={
                      require('../assets/svg/icons/property_features/fridge.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasNearTheBeach ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesNearTheBeach)}
                    icon={
                      require('../assets/svg/icons/property_features/near_the_beach.svg')
                        .default
                    }
                  />
                ) : null}
                {data.hasElectricGate ? (
                  <PropertyFeatures
                    label={t(TranslationsKeys.propertyPageFeaturesElectricGate)}
                    icon={
                      require('../assets/svg/icons/property_features/electric_gate.svg')
                        .default
                    }
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="property-area__details__info">
            <div className="property-area__details__info__grade">
              <h2>{t(TranslationsKeys.propertyPageInfo)}</h2>
              <div
                className={`property-area__energy-grade ${
                  mapEnergyValueToColor[data.energyCertificate]
                }`}
              >
                <span>{t(TranslationsKeys.propertyPageEnergyClass)}</span>
                <span>{mapEnergyValueToName[data.energyCertificate]}</span>
              </div>
            </div>
            <div className="property-area__details__info__grid">
              <LabelIcon
                data={t(
                  TranslationsKeys.propertyPageLabelsTypeValues(data.type),
                )}
                label={t(TranslationsKeys.propertyPageLabelsType)}
                icon={require('../assets/svg/icons/type.svg').default}
              />
              <LabelIcon
                data={data.buildArea.toString()}
                label={t(
                  TranslationsKeys.propertyPreviewWidgetCommonFieldsBuildingArea,
                )}
                extraLabel={data.measurementUnitSymbol.toUpperCase()}
                icon={
                  require('../assets/svg/icons/property_item/build_area.svg')
                    .default
                }
              />
              <LabelIcon
                data={data.bedrooms.toString()}
                label={t(
                  TranslationsKeys.propertyPreviewWidgetCommonFieldsBedroom,
                )}
                icon={
                  require('../assets/svg/icons/property_item/bedroom.svg')
                    .default
                }
              />
              <LabelIcon
                data={data.hasSwimmingPool ? 'YES' : 'NO'}
                label={t(
                  TranslationsKeys.propertyPreviewWidgetCommonFieldsSwimmingPool,
                )}
                icon={
                  require('../assets/svg/icons/property_item/swimming_pool.svg')
                    .default
                }
              />
              <LabelIcon
                data={data.bathrooms.toString()}
                label={t(
                  TranslationsKeys.propertyPreviewWidgetCommonFieldsBathroom,
                )}
                icon={
                  require('../assets/svg/icons/property_item/bathroom.svg')
                    .default
                }
              />
              <LabelIcon
                data={data.hasGarage ? 'YES' : 'NO'}
                label={t(
                  TranslationsKeys.propertyPreviewWidgetCommonFieldsGarage,
                )}
                icon={
                  require('../assets/svg/icons/property_item/garage.svg')
                    .default
                }
              />
              {data.landArea ? (
                <LabelIcon
                  data={data.landArea.toString()}
                  label={t(
                    TranslationsKeys.propertyPreviewWidgetCommonFieldsLandArea,
                  )}
                  extraLabel={data.measurementUnitSymbol.toUpperCase()}
                  icon={
                    require('../assets/svg/icons/property_item/land_area.svg')
                      .default
                  }
                />
              ) : null}
              <LabelIcon
                data={t(
                  TranslationsKeys.propertyPageLabelsOwnerShipValues(
                    data.ownership,
                  ),
                )}
                label={t(TranslationsKeys.propertyPageLabelsOwnerShip)}
                icon={require('../assets/svg/icons/ownership.svg').default}
              />
            </div>
          </div>
        </div>
      </section>
      <ServicesRecommendation />
      <ShareContentPopup message={data.title} />
      <PreFooter />
      <Footer />
      <TermsOfUsageToast />
      <ButtonCwu />
      <SearchPane />
    </>
  );
}

export const PropertyPageConnector = connect(mapState, mapDispatch);

export const PropertyPage = PropertyPageConnector(Component);
