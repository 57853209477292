export function PropertyFeatures({
  label,
  icon,
}: PropertyFeaturesProps): JSX.Element {
  return (
    <div className="property-features">
      <img src={icon} alt="info" />
      <span>{label}</span>
    </div>
  );
}
