import {
  FetchRequestType,
  RequestHeader,
  RequestHeaderValues,
  ResponseStatusCode,
} from '@project/enums';

export async function fetchAdvanced<T>({
  url,
  body = {},
  method = FetchRequestType.GET,
}: FetchAdvancedData): Promise<T> {
  const headers: HeadersInit = {
    [RequestHeader.CONTENT_TYPE]: RequestHeaderValues.APPLICATION_JSON,
  };

  const requestOptions: RequestInit = {
    method,
    headers,
    signal: AbortSignal.timeout(10 * 1000),
  };

  if (Object.keys(body).length) {
    requestOptions.body = JSON.stringify(body);
  }

  const response: Response = await fetch(url, requestOptions);
  const data = await response.json();

  if (response.status >= ResponseStatusCode.INTERNAL_ERROR) {
    // eslint-disable-next-line no-throw-literal
    throw 'INTERNAL_ERROR';
  } else if (response.status >= ResponseStatusCode.BAD_REQUEST) {
    throw data.message;
  }

  return data as T;
}
