import { Alignment, Fit, Layout, useRive } from '@rive-app/react-canvas';
import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { AppDetails } from '@core/app_details';
import { RiveAssets } from '@core/rive_assets';
import { AppLoader } from '@project/enums';
import { AppActions } from '@store/items/app';
import { TranslationsKeys } from '@translations/translations_keys';

const DEFAULT_DELAY_SPLASH_ANIMATION_IN_SECONDS = 1.5;
const DEFAULT_SPLASH_BACK_ANIMATION_IN_MS = 1400;
const MAX_LOADING_VALUE = 100;

const mapDispatch = {
  resetSplashLoader: () => AppActions.resetLoader(AppLoader.SPLASH_LOADING),
};

function Component({ resetSplashLoader }: SplashLoaderProps): JSX.Element {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const { RiveComponent: ComingSoonAnimation } = useRive({
    src: RiveAssets.splashLoading,
    layout: new Layout({
      fit: Fit.Cover,
      alignment: Alignment.BottomCenter,
    }),
    autoplay: true,
  });

  const { RiveComponent: MusicalNote } = useRive({
    src: RiveAssets.musicNote,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
    autoplay: true,
  });

  useEffect(() => {
    if (isLoaded) {
      setTimeout(resetSplashLoader, DEFAULT_SPLASH_BACK_ANIMATION_IN_MS);
    }
  }, [isLoaded]);

  return (
    <div className="splash-loader">
      <div
        className={`splash-loader__progress ${
          isLoaded ? 'animation-back' : ''
        }`}
      >
        <CountUp
          className="splash-loader__value"
          delay={DEFAULT_DELAY_SPLASH_ANIMATION_IN_SECONDS}
          end={MAX_LOADING_VALUE}
          duration={AppDetails.defaultCountersDuration}
          suffix="%"
          separator=""
          onEnd={() => setIsLoaded(true)}
        />
        <span className="splash-loader__separator">—</span>
        <span className="splash-loader__label">
          {t(TranslationsKeys.splashLoaderLabel)}
        </span>
        <MusicalNote className="splash-loader__mn" />
      </div>
      <ComingSoonAnimation
        className={`splash-loader__loader ${isLoaded ? 'animation-back' : ''}`}
      />
    </div>
  );
}

export const SplashLoaderConnector = connect(null, mapDispatch);

export const SplashLoader = SplashLoaderConnector(Component);
