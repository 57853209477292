export function CheckBoxIcon({
  isChecked,
  disabled,
  secondary = false,
}: CheckBoxIconProps): JSX.Element {
  if (isChecked) {
    if (secondary) {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={disabled ? 'disabled' : ''}
        >
          <rect x="3" y="3" width="18" height="18" rx="3" fill="#BF9A6D" />
          <path
            d="M6.46288 11.8559L10.5085 15.7016L17.565 8.32771"
            stroke="#F3F3F3"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }

    return (
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={disabled ? 'disabled' : ''}
      >
        <rect
          x="3"
          y="3.5"
          width="18"
          height="18"
          rx="3"
          stroke="#403B37"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M6.46288 12.3559L10.5085 16.2016L17.565 8.82771"
          stroke="#403B37"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={disabled ? 'disabled' : ''}
    >
      <rect
        x="3.75"
        y="3.75"
        width="16.5"
        height="16.5"
        rx="2.25"
        stroke="#403B37"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
