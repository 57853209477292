import { useTranslation } from 'react-i18next';

import { ButtonIcon } from '@components/button_icon';
import { MoreIcon } from '@components/icons/more.icon';
import { TranslationsKeys } from '@translations/translations_keys';

export function ContentHeader({
  subTitle,
  title,
  hasMoreButton = true,
  actionWidget,
  onMore,
}: ContentHeaderProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="content-header">
      <div className="content-header__left-side static">
        <div className="content-header__sub-title">
          <hr />
          <h4>{subTitle}</h4>
        </div>
        <h3>{title}</h3>
      </div>
      {actionWidget ? (
        actionWidget
      ) : hasMoreButton ? (
        <ButtonIcon
          label={t(TranslationsKeys.formsButtonsSeeMore)}
          extraClasses="default-filled-button static"
          widget={<MoreIcon />}
          onClick={onMore}
        />
      ) : null}
    </div>
  );
}
