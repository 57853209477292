export function CopyIcon(): JSX.Element {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M10.3399 6.13943L6.91243 2.71193C5.91442 1.71392 4.27435 1.73591 3.24922 2.76103C2.2241 3.78616 2.20211 5.42623 3.20012 6.42424L6.17586 9.39998"
        stroke="#A09B97"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        // eslint-disable-next-line max-len
        d="M12.8387 8.62599L15.8144 11.6017C16.8124 12.5997 16.7905 14.2398 15.7653 15.2649C14.7402 16.2901 13.1001 16.312 12.1021 15.314L8.67463 11.8865"
        stroke="#A09B97"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2909 9.80217C11.3161 8.77704 11.338 7.13697 10.34 6.13896"
        stroke="#A09B97"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.6744 8.17439C7.64927 9.19952 7.62728 10.8396 8.62529 11.8376"
        stroke="#A09B97"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
