import { useTranslation } from 'react-i18next';

import { ButtonIcon } from '@components/button_icon';
import { MoreIcon } from '@components/icons/more.icon';
import { TranslationsKeys } from '@translations/translations_keys';

export function ServiceItem({ data, onClick }: ServiceItemProps): JSX.Element {
  const { t, i18n } = useTranslation();

  return (
    <article className="service-item static" onClick={onClick}>
      <h2 className="service-item__title">{data.title[i18n.language]}</h2>
      <p>{data.intro[i18n.language]}</p>
      <ButtonIcon
        label={t(TranslationsKeys.formsButtonsReadMore)}
        extraClasses="default-outline-button-without-border static"
        widget={<MoreIcon extraClasses="more-icon yellow" />}
        onClick={onClick}
      />
    </article>
  );
}
