import { useTranslation } from 'react-i18next';

import { AppDetails } from '@core/app_details';
import { TranslationsKeys } from '@translations/translations_keys';

export function Footer(): JSX.Element {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="footer__layout">
        <span>
          {t(TranslationsKeys.footerRights, {
            year: new Date().getFullYear(),
            ami: AppDetails.licenseAmi,
          })}
        </span>
        <div
          className="footer__developers"
          onClick={() => window.open('https://impecc.team/')}
        >
          <span>{t(TranslationsKeys.footerPoweredBy)}</span>
          <img
            src={require('../assets/svg/icons/impecc.svg').default}
            alt="impecc"
          />
        </div>
      </div>
    </footer>
  );
}
