import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ArticlesRecommendation } from '@components/articles_recommendation';
import { ButtonCwu } from '@components/button_cwu';
import { ButtonIcon } from '@components/button_icon';
import { Footer } from '@components/footer';
import { Navbar } from '@components/navbar';
import { PreFooter } from '@components/pre_footer';
import { SearchPane } from '@components/search_pane';
import { ShareContentPopup } from '@components/share_content_popup';
import { TermsOfUsageToast } from '@components/terms_of_usage_toast';
import { AppDetails } from '@core/app_details';
import { useAdvancedNavigator } from '@hooks/advanced_navigator.hook';
import { AppError, AppLoader, AppRoutes, CommonDomEvent } from '@project/enums';
import { AppActions } from '@store/items/app';
import { ArticlesActions } from '@store/items/articles';
import { TranslationsKeys } from '@translations/translations_keys';

const mapState = ({ app, articles }: RootState) => ({
  data: articles.exactArticle,
  wasLoaded: !app.loaders.includes(AppLoader.EXACT_ARTICLE_LOADING),
  hasError:
    app.errors.filter(
      (error) => error === AppError.UNABLE_TO_GET_EXACT_ARTICLE,
    )[0] ?? null,
});

const mapDispatch = {
  resetError: () => AppActions.resetError(AppError.UNABLE_TO_GET_EXACT_ARTICLE),
  resumeLoader: () => AppActions.setLoader(AppLoader.EXACT_ARTICLE_LOADING),
  requestForGetExactArticle: (payload: GetExactArticleDto) =>
    ArticlesActions.requestForGetExactArticle(payload),
};

function Component({
  data,
  wasLoaded,
  hasError,
  resumeLoader,
  resetError,
  requestForGetExactArticle,
}: ArticlePageProps): Nullable<JSX.Element> {
  const { t, i18n } = useTranslation();
  const { navigator } = useAdvancedNavigator();
  const location = useLocation();

  const onScroll = () => {
    const statics = document.querySelectorAll('.static');
    const { innerHeight } = window;

    for (const item of statics) {
      const { top } = item.getBoundingClientRect();

      if (top < innerHeight) {
        item.classList.add('animation');
      }
    }
  };

  useEffect(() => {
    window.addEventListener(CommonDomEvent.SCROLL, onScroll);

    const paths = location.pathname.split('/').filter((v) => v.trim());
    const [category, slug] = paths.slice(paths.length - 2);

    requestForGetExactArticle({
      category,
      slug,
    });

    return () => {
      resumeLoader();
      window.removeEventListener(CommonDomEvent.SCROLL, onScroll);
    };
  }, []);

  useEffect(() => {
    if (hasError) {
      navigator(AppRoutes.ROOT);
      resetError();
      resumeLoader();
    }
  }, [hasError]);

  useEffect(() => {
    if (wasLoaded) {
      onScroll();
    }
  }, [wasLoaded]);

  useEffect(() => {
    const paths = location.pathname.split('/').filter((v) => v.trim());
    const [category, slug] = paths
      .slice(paths.length - 2)
      .map((v) => decodeURI(v));

    if (data) {
      const hasChanged =
        !Object.values(data!.categorySlug).includes(category) ||
        !Object.values(data!.slug).includes(slug);

      if (hasChanged) {
        resumeLoader();

        const paths = location.pathname.split('/').filter((v) => v.trim());
        const [category, slug] = paths.slice(paths.length - 2);

        requestForGetExactArticle({
          category,
          slug,
        });
      }
    }
  }, [location]);

  if (wasLoaded) {
    return (
      <>
        <Navbar shouldUseDarkTheme />
        <ShareContentPopup message={data!.title[i18n.language]} />
        <article className="article-content">
          {/* @ts-ignore */}
          {/* <JsxParser
            bindings={{
              opts: {
                playerVars: {
                  autoplay: 1,
                },
              },
            }}
            // @ts-ignore
            components={{ YouTube }}
            jsx={`
            <span>test</span>
            <YouTube
            videoId="xdCyFExt8KU"
            opts={opts}
          />
    `}
          /> */}
          <header className="static">
            <div>
              <span className="article-content__category">
                {data!.categoryName[i18n.language]}
              </span>
              <span className="article-content__published">
                {data!.updatedAt.toPrettyDate()}
              </span>
            </div>
            {data!.websiteUrl ? (
              <ButtonIcon
                label={t(TranslationsKeys.articlePageVisitWebSite)}
                onClick={() => window.open(data!.websiteUrl!)}
              />
            ) : null}
          </header>
          <h1 className="static">{data!.title[i18n.language]}</h1>
          <img
            className="article-content__cover static"
            src={`${AppDetails.apiHost}articles/${data!.id}/cover.png`}
            alt={`cover of ${data!.title[i18n.language]}`}
          />
          <section
            className="article-content__data static"
            dangerouslySetInnerHTML={{
              __html: data!.content[i18n.language].replaceAll(
                '{{host}}',
                AppDetails.apiHost,
              ),
            }}
          ></section>
        </article>
        <ArticlesRecommendation />
        <PreFooter />
        <Footer />
        <TermsOfUsageToast />
        <ButtonCwu />
        <SearchPane />
      </>
    );
  }

  return null;
}

export const ArticlePageConnector = connect(mapState, mapDispatch);

export const ArticlePage = ArticlePageConnector(Component);
