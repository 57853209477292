import toast from 'react-hot-toast';

import ErrorIconSvg from '@assets/svg/icons/error.svg';
import SuccessIconSvg from '@assets/svg/icons/success.svg';
import WarningIconSvg from '@assets/svg/icons/warning.svg';
import { AppDetails } from '@core/app_details';
import { MessageType } from '@project/enums';

const mapMessageTypeToImage = {
  [MessageType.SUCCESS]: SuccessIconSvg,
  [MessageType.WARNING]: WarningIconSvg,
  [MessageType.ERROR]: ErrorIconSvg,
};

export function showToast(props: ToastProps): void {
  toast.custom(
    (controller) => (
      <div
        className={`toast ${props.type.toLowerCase()} ${
          controller.visible ? 'toast-enter' : ''
        }`}
      >
        <img src={mapMessageTypeToImage[props.type]} alt="?" />
        <span>{props.message}</span>
      </div>
    ),
    {
      position: 'bottom-center',
      duration: AppDetails.defaultAppearanceDuration,
    },
  );
}
