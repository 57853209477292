export class ApiEndpoints {
  public static customerSubscribe = 'customers/subscribe';
  public static customerRequest = 'customers/request';

  public static articlesAll = 'articles/all';
  public static articlesPriorityNews = 'articles/priority-news';
  public static articlesExact = 'articles/exact';
  public static articlesRecommendations = 'articles/recommendations';

  public static reviewsLatest = 'reviews/latest';

  public static servicesAll = 'services/all';
  public static servicesPriority = 'services/priority';
  public static servicesExact = 'services/exact';
  public static servicesRecommendations = 'services/recommendations';

  public static commonQuestionsAll = 'common-questions/all';

  public static propertiesPriority = 'properties/priority';
  public static propertiesAll = 'properties/all';
  public static propertiesExact = 'properties/exact';
  public static propertiesFavorites = 'properties/favorites';
}
