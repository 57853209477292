import { useState } from 'react';

export function CategoriesController({
  values,
  onChanged,
}: CategoriesControllerProps): JSX.Element {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onClick = (newIndex: number) => {
    setSelectedIndex(newIndex);
    onChanged(newIndex);
  };

  return (
    <div className="categories-controller static">
      {values.map((value, index) => (
        <span
          key={value + index}
          className={selectedIndex === index ? 'active' : ''}
          onClick={() => onClick(index)}
        >
          {value}
        </span>
      ))}
    </div>
  );
}
