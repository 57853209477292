import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { AppDetails } from '@core/app_details';
import { appReducer } from '@store/items/app';
import { articlesReducer } from '@store/items/articles';
import { commonQuestionsReducer } from '@store/items/common_questions';
import { customersReducer } from '@store/items/customers';
import { propertiesReducer } from '@store/items/properties';
import { reviewsReducer } from '@store/items/reviews';
import { servicesReducer } from '@store/items/services';
import { rootSagaWatcher } from '@store/side_effects';

const sagaMiddleware = createSagaMiddleware();
const reducer = combineReducers({
  app: appReducer,
  customers: customersReducer,
  articles: articlesReducer,
  reviews: reviewsReducer,
  services: servicesReducer,
  commonQuestions: commonQuestionsReducer,
  properties: propertiesReducer,
});

const configuredStore = configureStore({
  reducer,
  middleware: [sagaMiddleware],
  devTools: AppDetails.isDevMode,
});

sagaMiddleware.run(rootSagaWatcher);

export const store = configuredStore;
