export function DownloadIcon(): JSX.Element {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M9.50019 10.875L5.00024 6.37498L8.00019 6.37498L8.00019 2.24998L11.0002 2.24998L11.0002 6.37498L14.0002 6.37498L9.50019 10.875Z"
        stroke="#A09B97"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 13.875L2.75 13.875"
        stroke="#A09B97"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.25 16.5L5.75 16.5"
        stroke="#A09B97"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
