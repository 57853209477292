import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ButtonIcon } from '@components/button_icon';
import { FormInput } from '@components/form_input';
import { TouAgreement } from '@components/tou_agreement';
import { AppDetails } from '@core/app_details';
import {
  AppError,
  AppLoader,
  AppPassedAction,
  CustomerRequestType,
  FormForRequestType,
  FormInputType,
  FormName,
} from '@project/enums';
import { AppActions } from '@store/items/app';
import { CustomersActions } from '@store/items/customers';
import { TranslationsKeys } from '@translations/translations_keys';

const initialState = {
  [FormName.FULL_NAME]: '',
  [FormName.EMAIL]: '',
  [FormName.SUBJECT]: '',
  [FormName.MESSAGE]: '',
};

const mapState = ({ app }: RootState) => ({
  hasUiLocked: app.hasUiLocked,
  wasLoaded: !app.loaders.includes(AppLoader.SPLASH_LOADING),
  wasPassed: app.passedActions.includes(
    AppPassedAction.FORM_FOR_REQUEST_WAS_SENT,
  ),
  error:
    app.errors.filter(
      (error) => error === AppError.FORM_FOR_REQUEST_ISSUE,
    )[0] ?? null,
});

const mapDispatch = {
  resetError: (payload: AppError) => AppActions.resetError(payload),
  resetPassedAction: () =>
    AppActions.resetPassedAction(AppPassedAction.FORM_FOR_REQUEST_WAS_SENT),
  requestSubscribe: (payload: CreateCustomerDto) =>
    CustomersActions.requestSubscribe(payload),
  requestForm: (payload: CustomerRequest) =>
    CustomersActions.requestForCallback(payload),
};

const mapLocalTypeToServer: any = {
  [FormForRequestType.SELL_WITH_US]: CustomerRequestType.SELL_WITH_US,
  [FormForRequestType.ASK_QUESTION]: CustomerRequestType.ASK_QUESTION,
  [FormForRequestType.ASK_FOR_FREE_VALUATION]:
    CustomerRequestType.ASK_FOR_FREE_VALUATION,
};

export function Component({
  type,
  propertyId,
  serviceId,
  wasPassed,
  resetError,
  resetPassedAction,
  hasUiLocked,
  requestSubscribe,
  requestForm,
  error,
}: FormForRequestProps): JSX.Element {
  const { t } = useTranslation();

  const [isAgreed, setIsAgreed] = useState(false);
  const [payload, setPayload] = useState(initialState);

  const hasShortData = ![FormForRequestType.SUBSCRIPTION].includes(type);
  const shouldSubscribe = type === FormForRequestType.SUBSCRIPTION;

  const handleFormChanges = (name: string, value: string) => {
    resetError(AppError.FORM_FOR_REQUEST_ISSUE);

    setPayload({
      ...payload,
      [name]: value,
    });
  };

  const handleSubmit = (
    e: Nullable<React.FormEvent<HTMLFormElement>>,
  ): void => {
    e?.preventDefault();

    switch (type) {
      case FormForRequestType.SUBSCRIPTION:
        requestSubscribe({
          fullName: payload[FormName.FULL_NAME],
          email: payload[FormName.EMAIL],
        });
        break;

      case FormForRequestType.ASK_FOR_VIEWING:
        requestForm({
          type: CustomerRequestType.ASK_FOR_VIEWING,
          fullName: payload[FormName.FULL_NAME],
          email: payload[FormName.EMAIL],
          subject: payload[FormName.SUBJECT],
          message: payload[FormName.MESSAGE],
          propertyId: propertyId ?? null,
          serviceId: serviceId ?? null,
        });
        break;

      case FormForRequestType.SELL_WITH_US:
      case FormForRequestType.ASK_QUESTION:
      case FormForRequestType.ASK_FOR_FREE_VALUATION:
        requestForm({
          type: mapLocalTypeToServer[type],
          fullName: payload[FormName.FULL_NAME],
          email: payload[FormName.EMAIL],
          subject: payload[FormName.SUBJECT],
          message: payload[FormName.MESSAGE],
          propertyId: null,
          serviceId: null,
        });
        break;
    }
  };

  useEffect(() => {
    if (wasPassed) {
      setPayload(initialState);
      setIsAgreed(false);
      resetPassedAction();
    }
  }, [wasPassed]);

  return (
    <form className="form-for-request static" onSubmit={handleSubmit}>
      <FormInput
        name={FormName.FULL_NAME}
        type={FormInputType.TEXT}
        label={t(TranslationsKeys.formsLabelsFullName)}
        placeholder={AppDetails.fullNamePlaceholder}
        value={payload.fullName}
        error={error}
        onValueChanged={handleFormChanges}
      />
      <FormInput
        name={FormName.EMAIL}
        type={FormInputType.EMAIL}
        label={t(TranslationsKeys.formsLabelsEmail)}
        placeholder={AppDetails.emailPlaceholder}
        value={payload.email}
        error={error}
        onValueChanged={handleFormChanges}
      />
      {hasShortData ? (
        <FormInput
          name={FormName.SUBJECT}
          type={FormInputType.TEXT}
          label={t(TranslationsKeys.formsLabelsSubject)}
          placeholder={t(TranslationsKeys.formsLabelsSubject)}
          value={payload.subject}
          error={error}
          onValueChanged={handleFormChanges}
        />
      ) : null}
      {hasShortData ? (
        <FormInput
          multiline
          name={FormName.MESSAGE}
          type={FormInputType.TEXT}
          label={t(TranslationsKeys.formsLabelsDescription)}
          placeholder={t(TranslationsKeys.formsLabelsDescription)}
          value={payload.message}
          error={error}
          onValueChanged={handleFormChanges}
        />
      ) : null}
      <TouAgreement managedState={isAgreed} onToggle={(v) => setIsAgreed(v)} />
      <ButtonIcon
        label={t(
          shouldSubscribe
            ? TranslationsKeys.formsButtonsSubscribe
            : TranslationsKeys.formsButtonsSend,
        )}
        extraClasses={`default-filled-button ${
          !isAgreed || hasUiLocked ? 'disabled' : ''
        }`}
        onClick={!isAgreed ? undefined : handleSubmit}
      />
    </form>
  );
}

export const FormForRequestConnector = connect(mapState, mapDispatch);

export const FormForRequest = FormForRequestConnector(Component);
