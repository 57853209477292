import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { CheckBoxIcon } from '@components/icons/checkbox.icon';
import { useAdvancedNavigator } from '@hooks/advanced_navigator.hook';
import { AppRoutes } from '@project/enums';
import { TranslationsKeys } from '@translations/translations_keys';

const mapState = ({ app }: RootState) => ({
  hasUiLocked: app.hasUiLocked,
});

function Component({
  hasUiLocked,
  initialState,
  managedState,
  onToggle,
}: TouAgreementProps): JSX.Element {
  const { t } = useTranslation();
  const { navigator } = useAdvancedNavigator();

  const [isAgreed, setIsAgreed] = useState(initialState ?? false);

  useEffect(() => {
    onToggle(isAgreed);
  }, [isAgreed]);

  useEffect(() => {
    if (managedState !== undefined) {
      setIsAgreed(managedState);
    }
  }, [managedState]);

  return (
    <div
      className={`tou-agreement ${hasUiLocked ? 'disabled' : ''}`}
      onClick={hasUiLocked ? undefined : setIsAgreed.bind(null, !isAgreed)}
    >
      <CheckBoxIcon isChecked={isAgreed} disabled={hasUiLocked} />
      {`${t(TranslationsKeys.mainPageStnlTitleAgreementLabel)} `}
      <span
        className="tou-agreement__link"
        onClick={() => navigator(AppRoutes.TNC)}
      >
        {t(TranslationsKeys.mainPageStnlTitleAgreementTerms)}
      </span>
      {` ${t(TranslationsKeys.mainPageStnlTitleAgreementAnd)} `}
      <span
        className="tou-agreement__link"
        onClick={() => navigator(AppRoutes.PRIVACY_POLICY)}
      >
        {t(TranslationsKeys.mainPageStnlTitleAgreementPrivacyPolicy)}
      </span>
      {`${t(TranslationsKeys.mainPageStnlTitleAgreementGetting)}`}
    </div>
  );
}

export const TouAgreementConnector = connect(mapState, null);

export const TouAgreement = TouAgreementConnector(Component);
