import { ReduxActionType } from '@project/enums';

const initialState: ServicesState = {
  priority: null,
  exact: null,
  recommendations: null,
};

export function servicesReducer(
  state: ServicesState = initialState,
  action: DispatchWithPayload<ExactService | PriorityService[]>,
): ServicesState {
  switch (action.type) {
    default:
      return state;

    case ReduxActionType.SERVICES_SET_PRIORITY:
      return {
        ...state,
        priority: [...(action.payload as PriorityService[])],
      };

    case ReduxActionType.SERVICES_SET_EXACT:
      return {
        ...state,
        exact: action.payload as ExactService,
      };

    case ReduxActionType.SERVICES_SET_RECOMMENDATION:
      return {
        ...state,
        recommendations: [...(action.payload as PriorityService[])],
      };
  }
}

export class ServicesActions {
  public static requestForAll(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.SERVICES_REQUEST_GET_ALL,
    };
  }

  public static requestForPriority(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.SERVICES_REQUEST_GET_PRIORITY,
    };
  }

  public static setPriority(
    payload: PriorityService[],
  ): DispatchWithPayload<PriorityService[]> {
    return {
      type: ReduxActionType.SERVICES_SET_PRIORITY,
      payload,
    };
  }

  public static requestForGetExact(
    payload: GetExactServiceDto,
  ): DispatchWithPayload<GetExactServiceDto> {
    return {
      type: ReduxActionType.SERVICES_REQUEST_GET_EXACT,
      payload,
    };
  }

  public static setExact(
    payload: ExactService,
  ): DispatchWithPayload<ExactService> {
    return {
      type: ReduxActionType.SERVICES_SET_EXACT,
      payload,
    };
  }

  public static requestForGetRecommendations(
    payload: GetServicesRecommendationsDto,
  ): DispatchWithPayload<GetServicesRecommendationsDto> {
    return {
      type: ReduxActionType.SERVICES_REQUEST_GET_RECOMMENDATION,
      payload,
    };
  }

  public static setRecommendations(
    payload: PriorityService[],
  ): DispatchWithPayload<PriorityService[]> {
    return {
      type: ReduxActionType.SERVICES_SET_RECOMMENDATION,
      payload,
    };
  }
}
