export function SearchIcon({
  onClick,
  shouldUseDarkTheme = false,
}: NavbarIconProps): JSX.Element {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={shouldUseDarkTheme ? 'icon-dark' : 'icon-light'}
      onClick={onClick}
    >
      <path
        // eslint-disable-next-line max-len
        d="M12.8264 22.6528C18.2533 22.6528 22.6527 18.2534 22.6527 12.8264C22.6527 7.39943 18.2533 3 12.8264 3C7.39941 3 3 7.39943 3 12.8264C3 18.2534 7.39941 22.6528 12.8264 22.6528Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        // eslint-disable-next-line max-len
        d="M16.0962 8.97854C15.2594 8.14173 14.1033 7.62415 12.8264 7.62415C11.5495 7.62415 10.3935 8.14173 9.55664 8.97854"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8909 19.8909L24.7955 24.7956"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
