import { Navigate, Route, Routes } from 'react-router-dom';

import { AppDetails } from '@core/app_details';
import { AboutAlgarvePage } from '@pages/about_algarve.page';
import { ArticlePage } from '@pages/article.page';
import { FaqPage } from '@pages/faq.page';
import { FavoritePropertiesPage } from '@pages/favorite_properties.page';
import { MainPage } from '@pages/main.page';
import { NewsPage } from '@pages/news.page';
import { PrivacyPolicyPage } from '@pages/privacy_policy.page';
import { PropertiesPage } from '@pages/properties.page';
import { PropertyPage } from '@pages/property.page';
import { ServicePage } from '@pages/service.page';
import { ServicesPage } from '@pages/services.page';
import { TncPage } from '@pages/tnc.page';
import { WhoIsNrPage } from '@pages/who_is_nr.page';
import { AppRoutes } from '@project/enums';

export function defaultRouter(): JSX.Element {
  const supportedLangsWithoutDetails = AppDetails.supportedLangs.filter(
    (langCode) => langCode !== AppDetails.fallbackAppLang,
  );

  let mapPathToElementMultiLangs: RouterPathToComponentMultiLangMapper = {};
  const mapPathToElement: RouterPathToComponentMapper = {
    [AppRoutes.ROOT]: <MainPage />,
    [AppRoutes.PROPERTIES]: <PropertiesPage />,
    [AppRoutes.ARTICLE]: <ArticlePage />,
    [AppRoutes.SERVICE]: <ServicePage />,
    [AppRoutes.SERVICES]: <ServicesPage />,
    [AppRoutes.NEWS]: <NewsPage />,
    [AppRoutes.PROPERTY]: <PropertyPage />,
    [AppRoutes.WHO_IS_NR]: <WhoIsNrPage />,
    [AppRoutes.FAQ]: <FaqPage />,
    [AppRoutes.ABOUT_ALGARVE]: <AboutAlgarvePage />,
    [AppRoutes.FAVORITE_PROPERTIES]: <FavoritePropertiesPage />,
    [AppRoutes.TNC]: <TncPage />,
    [AppRoutes.PRIVACY_POLICY]: <PrivacyPolicyPage />,
  };

  for (const [path, element] of Object.entries(mapPathToElement)) {
    for (const langCode of supportedLangsWithoutDetails) {
      mapPathToElementMultiLangs[`/${langCode}${path}`] = element;
    }

    mapPathToElementMultiLangs[path] = element;
  }

  return (
    <Routes>
      {Object.entries(mapPathToElementMultiLangs).map(([path, element]) => (
        <Route key={path} path={path} element={element} />
      ))}
      <Route path={AppRoutes.ANY} element={<Navigate to={AppRoutes.ROOT} />} />
    </Routes>
  );
}
