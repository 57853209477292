import { ApiEndpoints } from '@core/api_endpoints';
import { AppDetails } from '@core/app_details';
import { FetchRequestType } from '@project/enums';
import { fetchAdvanced } from '@utils/fetch_advanced';

export class ApiRequests {
  public static async subscribeCustomer(
    body: CreateCustomerDto,
  ): Promise<ApiMessage> {
    return fetchAdvanced<ApiMessage>({
      url: `${AppDetails.apiHost}${ApiEndpoints.customerSubscribe}`,
      method: FetchRequestType.POST,
      body,
    });
  }

  public static async requestFromCustomer(
    body: CustomerRequest,
  ): Promise<ApiMessage> {
    return fetchAdvanced<ApiMessage>({
      url: `${AppDetails.apiHost}${ApiEndpoints.customerRequest}`,
      method: FetchRequestType.POST,
      body,
    });
  }

  public static async getAllNews(): Promise<PriorityNews> {
    return fetchAdvanced<PriorityNews>({
      url: `${AppDetails.apiHost}${ApiEndpoints.articlesAll}`,
    });
  }

  public static async getPriorityNews(): Promise<PriorityNews> {
    return fetchAdvanced<PriorityNews>({
      url: `${AppDetails.apiHost}${ApiEndpoints.articlesPriorityNews}`,
    });
  }

  public static async getExactArticle({
    category,
    slug,
  }: GetExactArticleDto): Promise<ExactArticle> {
    return fetchAdvanced<ExactArticle>({
      url:
        `${AppDetails.apiHost}${ApiEndpoints.articlesExact}` +
        `?category=${category}&slug=${slug}`,
    });
  }

  public static async getArticlesRecommendation({
    category,
    slug,
  }: GetArticlesRecommendationsDto): Promise<PriorityArticlePreview[]> {
    return fetchAdvanced<PriorityArticlePreview[]>({
      url:
        `${AppDetails.apiHost}${ApiEndpoints.articlesRecommendations}` +
        `?category=${category}&slug=${slug}`,
    });
  }

  public static async getLatestReviews(): Promise<CustomerReview[]> {
    return fetchAdvanced<CustomerReview[]>({
      url: `${AppDetails.apiHost}${ApiEndpoints.reviewsLatest}`,
    });
  }

  public static async getAllServices(): Promise<PriorityService[]> {
    return fetchAdvanced<PriorityService[]>({
      url: `${AppDetails.apiHost}${ApiEndpoints.servicesAll}`,
    });
  }

  public static async getPriorityServices(): Promise<PriorityService[]> {
    return fetchAdvanced<PriorityService[]>({
      url: `${AppDetails.apiHost}${ApiEndpoints.servicesPriority}`,
    });
  }

  public static async getExactService({
    slug,
  }: GetExactServiceDto): Promise<ExactService> {
    return fetchAdvanced<ExactService>({
      url: `${AppDetails.apiHost}${ApiEndpoints.servicesExact}?slug=${slug}`,
    });
  }

  public static async getServicesRecommendation({
    slug,
  }: GetServicesRecommendationsDto): Promise<PriorityService[]> {
    return fetchAdvanced<PriorityService[]>({
      url:
        `${AppDetails.apiHost}${ApiEndpoints.servicesRecommendations}` +
        `?slug=${slug}`,
    });
  }

  public static async getAllCommonQuestions(): Promise<CommonQuestion[]> {
    return fetchAdvanced<CommonQuestion[]>({
      url: `${AppDetails.apiHost}${ApiEndpoints.commonQuestionsAll}`,
    });
  }

  public static async getPriorityPropertiesPreview(): Promise<
    PropertyPreview[]
  > {
    return fetchAdvanced<PropertyPreview[]>({
      url: `${AppDetails.apiHost}${ApiEndpoints.propertiesPriority}`,
    });
  }

  public static async getAllPropertiesPreview(): Promise<PropertyPreview[]> {
    return fetchAdvanced<PropertyPreview[]>({
      url: `${AppDetails.apiHost}${ApiEndpoints.propertiesAll}`,
    });
  }

  public static async getExactProperty(id: number): Promise<ExactProperty> {
    return fetchAdvanced<ExactProperty>({
      url: `${AppDetails.apiHost}${ApiEndpoints.propertiesExact}?id=${id}`,
    });
  }

  public static async getFavoritesProperty(
    ids: number[],
  ): Promise<PropertyPreview[]> {
    return fetchAdvanced<PropertyPreview[]>({
      url: `${AppDetails.apiHost}${
        ApiEndpoints.propertiesFavorites
      }?ids=${ids.join(',')}`,
    });
  }
}
