import { AvailableTranslationLang } from '@project/enums';

Object.defineProperties(Number.prototype, {
  between: {
    value: function (range: DataRange): boolean {
      return this >= range[0] && this <= range[1];
    },
  },
  toDataRange: {
    value: function (): string {
      return this.toLocaleString().replaceAll(',', ' ');
    },
  },
  toUserFriendlyPrice: {
    value: function (): string {
      const value = this as number;
      const displayingValue: string = this.toString();

      if (value >= 10000000) {
        return `${displayingValue.slice(0, 2)}M`;
      }

      if (value >= 1000000) {
        return `${displayingValue.slice(0, 1)}M`;
      }

      if (value >= 100000) {
        return `${displayingValue.slice(0, 3)}K`;
      }

      if (value >= 10000) {
        return `${displayingValue.slice(0, 2)}K`;
      }

      if (value >= 1000) {
        return `${displayingValue.slice(0, 1)}K`;
      }

      return displayingValue;
    },
  },
});

Object.defineProperties(String.prototype, {
  toPrice: {
    value: function (): string {
      const priceData = this.split('.');
      const priceIntegerPart = parseInt(priceData[0])
        .toLocaleString(AvailableTranslationLang.EN_GB)
        .replaceAll(',', '.');

      return `${priceIntegerPart}${priceData[1] ? `,${priceData[1]}` : ''}`;
    },
  },
  toPrettyDate: {
    value: function (): string {
      return new Date(this).toLocaleString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    },
  },
});
