/* eslint-disable max-len */
import { AppDetails } from '@core/app_details';

export class RiveAssets {
  private static routePrefix = `${AppDetails.apiHost}/website-content/rive`;

  public static splashLoading = `${RiveAssets.routePrefix}/splash_loading.riv`;
  public static musicNote = `${RiveAssets.routePrefix}/music_note.riv`;
  public static burgerWb = `${RiveAssets.routePrefix}/burger_wb.riv`;
  public static burgerBb = `${RiveAssets.routePrefix}/burger_bb.riv`;

  public static signatureWhite = `${RiveAssets.routePrefix}/signature_white.riv`;
  public static signatureBlack = `${RiveAssets.routePrefix}/signature_black.riv`;
  public static spinBorderYellow = `${RiveAssets.routePrefix}/spin_border_yellow.riv`;
  public static buttonCwu = `${RiveAssets.routePrefix}/button_cwu.riv`;
  public static favoriteIcon = `${RiveAssets.routePrefix}/favorite.riv`;
  public static towerBg = `${RiveAssets.routePrefix}/townFaq.riv`;
}
