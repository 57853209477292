export function PrintIcon(): JSX.Element {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1099_1411)">
        <path
          d="M13.875 12H4.125V16.5H13.875V12Z"
          stroke="#A09B97"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 7.5H16.5V14.25H13.8815V12H4.11769V14.25H1.5V7.5Z"
          stroke="#A09B97"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.25 1.5H3.75V7.5H14.25V1.5Z"
          stroke="#A09B97"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1099_1411">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
