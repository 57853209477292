import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { FavoritesIndicators } from '@components/favorites_indicator';
import { BurgerIcon } from '@components/icons/burger.icon';
import { SearchIcon } from '@components/icons/search.icon';
import { LangDropDown } from '@components/lang_drop_down';
import { Signature } from '@components/signature';
import { AppDetails } from '@core/app_details';
import { useAdvancedNavigator } from '@hooks/advanced_navigator.hook';
import { AppLoader, AppRoutes, CommonDomEvent } from '@project/enums';
import { AppActions } from '@store/items/app';
import { CustomersActions } from '@store/items/customers';
import { TranslationsKeys } from '@translations/translations_keys';

const DEFAULT_ANIMATION_IN_MS = 2000;
const DEFAULT_OPEN_ANIMATION_IN_MS = 1000;

const mapState = ({ app, customers }: RootState) => ({
  wasLoaded: !app.loaders.includes(AppLoader.NAVBAR_LOADING),
  isInSearchMode: customers.isInSearchMode,
});

const mapDispatch = {
  resetNavbarLoader: () => AppActions.resetLoader(AppLoader.NAVBAR_LOADING),
  setSearchMode: (value: boolean) => CustomersActions.setSearchMode(value),
};

const legalAdviceRoutes: any = {
  cn: '/service/fa-lu-jian-yi',
  de: '/service/rechtsberatung',
  ie: '/service/comhairle-dlithiuil',
  fr: '/service/conseil-juridique',
  uk: '/service/yuridichna-konsultatsiya',
  pt: '/service/conselho-juridico',
  'en-US': '/service/legal-advice',
  'en-GB': '/service/legal-advice',
};

const propertyValuationRoutes: any = {
  cn: '/service/cai-wu-ping-gu',
  de: '/service/immobilienbewertung',
  ie: '/service/measunacht-maoine',
  fr: '/service/evaluation-immobiliere',
  uk: '/service/otsinka-nemovlenosti',
  pt: '/service/avaliacao-imobiliaria',
  'en-US': '/service/property-valuation',
  'en-GB': '/service/property-valuation',
};

function Component({
  wasLoaded,
  resetNavbarLoader,
  isInSearchMode,
  setSearchMode,
  shouldUseDarkTheme = false,
}: NavbarProps): JSX.Element {
  const [isOpened, setIsOpened] = useState(false);
  const [shouldHideSmooth, setShouldHideSmooth] = useState(false);
  const [hasBackground, setHasBackground] = useState(false);
  const [enableDarkTheme, setEnableDarkTheme] = useState(shouldUseDarkTheme);

  const { t, i18n } = useTranslation();
  const { navigator } = useAdvancedNavigator();
  const location = useLocation();

  const onPostProcessAfterToggle = () => {
    if (isOpened) {
      setShouldHideSmooth(true);
    }

    setTimeout(() => {
      setIsOpened(!isOpened);
    }, DEFAULT_OPEN_ANIMATION_IN_MS);
  };

  function onScroll() {
    setHasBackground(scrollY > 70);
    setEnableDarkTheme(scrollY < 70 && shouldUseDarkTheme);
  }

  useEffect(() => {
    setTimeout(resetNavbarLoader, DEFAULT_ANIMATION_IN_MS);
    onScroll();

    window.addEventListener(CommonDomEvent.SCROLL, onScroll);

    return () => {
      window.removeEventListener(CommonDomEvent.SCROLL, onScroll);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = isOpened ? 'hidden' : 'unset';

    if (!isOpened) {
      setShouldHideSmooth(false);
    }

    setEnableDarkTheme(isOpened || shouldUseDarkTheme);
  }, [isOpened]);

  return (
    <nav
      className={`navbar ${!wasLoaded ? 'animate' : ''} ${
        hasBackground ? 'scrolled' : ''
      }`}
    >
      <div className="navbar__layout">
        <LangDropDown shouldUseDarkTheme={enableDarkTheme} />
        <div className="navbar__controllers">
          {isInSearchMode ? null : (
            <SearchIcon
              shouldUseDarkTheme={enableDarkTheme}
              onClick={setSearchMode.bind(null, true)}
            />
          )}
          <FavoritesIndicators shouldUseDarkTheme={enableDarkTheme} />
          <BurgerIcon
            shouldUseDarkTheme={enableDarkTheme}
            onClick={onPostProcessAfterToggle}
          />
        </div>
      </div>
      {isOpened ? (
        <div
          className={`navbar__content ${
            shouldHideSmooth ? 'animation-back' : ''
          }`}
        >
          <Signature reversed />
          <div className="navbar__options">
            <span
              className={`${
                location.pathname.endsWith(AppRoutes.ROOT) ||
                location.pathname.endsWith(AppRoutes.TNC) ||
                location.pathname.endsWith(AppRoutes.PRIVACY_POLICY) ||
                AppDetails.supportedLangs.filter((v) =>
                  location.pathname.endsWith(AppRoutes.ROOT + v),
                ).length
                  ? 'active'
                  : ''
              }`}
              onClick={() => navigator(AppRoutes.ROOT)}
            >
              {t(TranslationsKeys.navbarHome)}
            </span>
            <span
              className={`${
                location.pathname.endsWith(AppRoutes.PROPERTIES) ||
                location.pathname.includes('/property/')
                  ? 'active'
                  : ''
              }`}
              onClick={() => navigator(AppRoutes.PROPERTIES)}
            >
              {t(TranslationsKeys.navbarProperties)}
            </span>
            <span
              className={`${
                location.pathname.endsWith(AppRoutes.ABOUT_ALGARVE)
                  ? 'active'
                  : ''
              }`}
              onClick={() => navigator(AppRoutes.ABOUT_ALGARVE)}
            >
              {t(TranslationsKeys.navbarAboutAlgarve)}
            </span>
            <span
              className={`${
                location.pathname.endsWith(AppRoutes.WHO_IS_NR) ? 'active' : ''
              }`}
              onClick={() => navigator(AppRoutes.WHO_IS_NR)}
            >
              {t(TranslationsKeys.navbarWhoIsNr)}
            </span>
            <span
              className={`${
                location.pathname.endsWith(AppRoutes.NEWS) ||
                location.pathname.includes(`/article/`)
                  ? 'active'
                  : ''
              }`}
              onClick={() => navigator(AppRoutes.NEWS)}
            >
              {t(TranslationsKeys.navbarNews)}
            </span>
            <span
              className={`${
                location.pathname.endsWith(AppRoutes.SERVICES) ||
                (location.pathname.includes('/service') &&
                  !Object.values(legalAdviceRoutes).filter((v) =>
                    location.pathname.includes(v as string),
                  ).length &&
                  !Object.values(propertyValuationRoutes).filter((v) =>
                    location.pathname.includes(v as string),
                  ).length)
                  ? 'active'
                  : ''
              }`}
              onClick={() => navigator(AppRoutes.SERVICES)}
            >
              {t(TranslationsKeys.navbarServices)}
            </span>
            <span
              className={`${
                Object.values(legalAdviceRoutes).filter((v) =>
                  location.pathname.includes(v as string),
                ).length
                  ? 'active'
                  : ''
              }`}
              onClick={() =>
                navigator(legalAdviceRoutes[i18n.language] as AppRoutes)
              }
            >
              {t(TranslationsKeys.navbarLegalAdvice)}
            </span>
            <span
              className={`${
                Object.values(propertyValuationRoutes).filter((v) =>
                  location.pathname.includes(v as string),
                ).length
                  ? 'active'
                  : ''
              }`}
              onClick={() =>
                navigator(propertyValuationRoutes[i18n.language] as AppRoutes)
              }
            >
              {t(TranslationsKeys.navbarFreeValuation)}
            </span>
            <span
              className={`${
                location.pathname.endsWith(AppRoutes.FAQ) ? 'active' : ''
              }`}
              onClick={() => navigator(AppRoutes.FAQ)}
            >
              {t(TranslationsKeys.navbarFaq)}
            </span>
          </div>
        </div>
      ) : null}
    </nav>
  );
}

export const NavbarConnector = connect(mapState, mapDispatch);

export const Navbar = NavbarConnector(Component);
