import { ReduxActionType } from '@project/enums';

const initialState: CommonQuestionsState = {
  data: null,
};

export function commonQuestionsReducer(
  state: CommonQuestionsState = initialState,
  action: DispatchWithPayload<CommonQuestion[]>,
): CommonQuestionsState {
  switch (action.type) {
    default:
      return state;

    case ReduxActionType.COMMON_QUESTIONS_SET_DATA:
      return {
        ...state,
        data: [...(action.payload as CommonQuestion[])],
      };
  }
}

export class CommonQuestionsActions {
  public static requestForAll(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.COMMON_QUESTIONS_REQUEST_GET_ALL,
    };
  }

  public static setData(
    payload: CommonQuestion[],
  ): DispatchWithPayload<CommonQuestion[]> {
    return {
      type: ReduxActionType.COMMON_QUESTIONS_SET_DATA,
      payload,
    };
  }
}
