import { ReduxActionType } from '@project/enums';

const initialState: PropertiesState = {
  data: null,
  favorites: null,
  exact: null,
};

export function propertiesReducer(
  state: PropertiesState = initialState,
  action: DispatchWithPayload<PropertyPreview[] | ExactProperty>,
): PropertiesState {
  switch (action.type) {
    default:
      return state;

    case ReduxActionType.PROPERTIES_SET_DATA:
      return {
        ...state,
        data: [...(action.payload as PropertyPreview[])],
      };

    case ReduxActionType.PROPERTIES_SET_FAVORITES:
      return {
        ...state,
        favorites: [...(action.payload as PropertyPreview[])],
      };

    case ReduxActionType.PROPERTIES_SET_EXACT:
      return {
        ...state,
        exact: action.payload as ExactProperty,
      };
  }
}

export class PropertiesActions {
  public static requestForPriority(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.PROPERTIES_REQUEST_GET_PRIORITY,
    };
  }

  public static requestForAll(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.PROPERTIES_REQUEST_GET_ALL,
    };
  }

  public static requestForExact(payload: number): DispatchWithPayload<number> {
    return {
      type: ReduxActionType.PROPERTIES_REQUEST_GET_EXACT,
      payload,
    };
  }

  public static requestForFavorites(
    payload: number[],
  ): DispatchWithPayload<number[]> {
    return {
      type: ReduxActionType.PROPERTIES_REQUEST_GET_FAVORITES,
      payload,
    };
  }

  public static setData(
    payload: PropertyPreview[],
  ): DispatchWithPayload<PropertyPreview[]> {
    return {
      type: ReduxActionType.PROPERTIES_SET_DATA,
      payload,
    };
  }

  public static setFavorites(
    payload: PropertyPreview[],
  ): DispatchWithPayload<PropertyPreview[]> {
    return {
      type: ReduxActionType.PROPERTIES_SET_FAVORITES,
      payload,
    };
  }

  public static setExact(
    payload: ExactProperty,
  ): DispatchWithPayload<ExactProperty> {
    return {
      type: ReduxActionType.PROPERTIES_SET_EXACT,
      payload,
    };
  }
}
