import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Achievements } from '@components/achievements';
import { ButtonCwu } from '@components/button_cwu';
import { ButtonSba } from '@components/button_sba';
import { CategoriesController } from '@components/categories_controller';
import { ContentHeader } from '@components/content_header';
import { Footer } from '@components/footer';
import { FormForRequest } from '@components/form_for_request';
import { Navbar } from '@components/navbar';
import { PreFooter } from '@components/pre_footer';
import { PriorityArticlePreview } from '@components/priority_article_preview';
import { PropertyItem } from '@components/property_item';
import { ReviewItem } from '@components/review_item';
import { SearchPane } from '@components/search_pane';
import { ServiceItem } from '@components/service_item';
import { Signature } from '@components/signature';
import { SplashLoader } from '@components/splash_loader';
import { TermsOfUsageToast } from '@components/terms_of_usage_toast';
import { AppDetails } from '@core/app_details';
import { useAdvancedNavigator } from '@hooks/advanced_navigator.hook';
import {
  AppLoader,
  AppRoutes,
  CommonDomEvent,
  FormForRequestType,
} from '@project/enums';
import { ArticlesActions } from '@store/items/articles';
import { CustomersActions } from '@store/items/customers';
import { PropertiesActions } from '@store/items/properties';
import { ReviewsActions } from '@store/items/reviews';
import { ServicesActions } from '@store/items/services';
import { TranslationsKeys } from '@translations/translations_keys';

const mapState = ({
  app,
  articles,
  reviews,
  services,
  properties,
  customers,
}: RootState) => ({
  customersReviews: reviews.customersReviews,
  priorityNews: articles.priorityNews,
  priorityServices: services.priority,
  hasUiLocked: app.hasUiLocked,
  properties: properties.data,
  wasLoaded: !app.loaders.includes(AppLoader.SPLASH_LOADING),
  isInSearchMode: customers.isInSearchMode,
});

const mapDispatch = {
  requestForPriorityNews: () => ArticlesActions.requestForPriorityNews(),
  requestForPriorityServices: () => ServicesActions.requestForPriority(),
  requestForLatestReviews: () => ReviewsActions.requestForLatest(),
  requestForPriorityProperties: () => PropertiesActions.requestForPriority(),
  setSearchMode: (value: boolean) => CustomersActions.setSearchMode(value),
};

function Component({
  customersReviews,
  priorityNews,
  priorityServices,
  wasLoaded,
  properties,
  requestForPriorityServices,
  requestForPriorityNews,
  requestForLatestReviews,
  requestForPriorityProperties,
}: MainPageProps): JSX.Element {
  const { t, i18n } = useTranslation();
  const { navigator } = useAdvancedNavigator();

  const [category, setCategory] =
    useState<Nullable<PriorityNewsCategory>>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const onScroll = () => {
    const statics = document.querySelectorAll('.static');
    const { innerHeight } = window;

    for (const item of statics) {
      const { top } = item.getBoundingClientRect();

      if (top < innerHeight) {
        item.classList.add('animation');
      }
    }
  };

  useEffect(() => {
    requestForPriorityProperties();
    requestForPriorityNews();
    requestForLatestReviews();
    requestForPriorityServices();

    window.addEventListener(CommonDomEvent.SCROLL, onScroll);

    return () => {
      window.removeEventListener(CommonDomEvent.SCROLL, onScroll);
    };
  }, []);

  useEffect(() => {
    if (priorityNews && priorityServices) {
      setCategory(priorityNews.categories[0]);
      setIsLoaded(true);
    }
  }, [priorityNews, priorityServices]);

  useEffect(() => {
    if (wasLoaded) {
      onScroll();
    }
  }, [wasLoaded]);

  return !wasLoaded ? (
    <SplashLoader />
  ) : (
    <>
      <Navbar />
      <section className="welcome">
        <div className="welcome__bg">
          <div className="welcome__video-layout">
            <video className="full-block-video" autoPlay muted loop playsInline>
              <source
                src={require('../assets/video/welcome.mp4')}
                type="video/mp4"
              />
            </video>
          </div>
          <div className="welcome__video-backdrop"></div>
        </div>
        <div className="welcome__fg">
          <Signature />
          <h3>ALGARVE — PORTUGAL</h3>
          <ButtonSba
            onClick={navigator.bind(null, AppRoutes.PROPERTIES, null)}
            label={t(TranslationsKeys.mainPageWelcomePropertyGallery)}
          />
        </div>
      </section>
      <section className="properties">
        <ContentHeader
          subTitle={t(TranslationsKeys.mainPagePropertiesPreTitle)}
          title={t(TranslationsKeys.mainPagePropertiesTitle)}
          onMore={() => navigator(AppRoutes.PROPERTIES)}
        />
        {properties
          ? properties!.map((property, i) => (
              <PropertyItem
                key={property.title}
                data={property}
                positionAtList={i + 1}
              />
            ))
          : null}
      </section>
      <section className="services">
        <ContentHeader
          subTitle={t(TranslationsKeys.mainPageServicesPreTitle)}
          title={t(TranslationsKeys.mainPageServicesTitle)}
          onMore={() => navigator(AppRoutes.SERVICES)}
        />
        <div className="services__bg">
          <img
            src={require('../assets/png/services.png')}
            alt={t(TranslationsKeys.mainPageServicesTitle)}
            className="static"
          />
        </div>
        <div className="services__fg">
          {isLoaded
            ? priorityServices!.map((service) => (
                <ServiceItem
                  key={service.title[i18n.language]}
                  data={service}
                  onClick={() =>
                    navigator(
                      `/service/${service.slug[i18n.language]}` as AppRoutes,
                    )
                  }
                />
              ))
            : null}
        </div>
      </section>
      <section className="reviews">
        <ContentHeader
          subTitle={t(TranslationsKeys.mainPageReviewsPreTitle)}
          title={t(TranslationsKeys.mainPageReviewsTitle)}
          hasMoreButton={false}
        />
        <div className="reviews__content">
          {customersReviews
            ? customersReviews!.map((v, i) => (
                <ReviewItem key={v.subject + i} data={v} />
              ))
            : null}
        </div>
      </section>
      <section className="priority-news">
        <ContentHeader
          subTitle={t(TranslationsKeys.mainPageNewsPreTitle)}
          title={t(TranslationsKeys.mainPageNewsTitle)}
          hasMoreButton={false}
          actionWidget={
            isLoaded ? (
              <CategoriesController
                onChanged={(currentIndex) =>
                  setCategory(priorityNews!.categories[currentIndex])
                }
                values={priorityNews!.categories.map(
                  (v) => v.name[i18n.language],
                )}
              />
            ) : undefined
          }
        />
        <div className="priority-news__content">
          {isLoaded
            ? priorityNews!.articlesPreviews
                .filter((article) => article.categoryId === category!.id)
                .map((article) => (
                  <PriorityArticlePreview
                    key={article.id}
                    data={article}
                    onClick={() =>
                      navigator(
                        `/article/${article.categorySlug[i18n.language]}/${
                          article.slug[i18n.language]
                        }` as AppRoutes,
                      )
                    }
                  />
                ))
            : null}
        </div>
        <div className="priority-news__bottom">
          <div></div>
          <div>
            <ButtonSba
              label={t(TranslationsKeys.formsButtonsMore)}
              onClick={() => navigator(AppRoutes.NEWS)}
            />
          </div>
        </div>
      </section>
      <section className="why-is">
        <div className="why-is__layout">
          <div className="why-is__description static">
            <ContentHeader
              subTitle={t(TranslationsKeys.mainPageWhyIsNataliaRosoPreTitle)}
              title={t(TranslationsKeys.mainPageWhyIsNataliaRosoTitle)}
              hasMoreButton={false}
            />
            <div className="why-is__content">
              {(
                t(TranslationsKeys.mainPageWhyIsNataliaRosoDescription, {
                  returnObjects: true,
                }) as string[]
              ).map((v, i) => (
                <p key={v + i}>{v}</p>
              ))}
            </div>
            <Achievements data={AppDetails.achievements} />
          </div>
          <div className="why-is__owner static">
            <div
              className="why-is__forward"
              onClick={() => navigator(AppRoutes.WHO_IS_NR)}
            >
              <span>{t(TranslationsKeys.formsButtonsReadMore)}</span>
              <img
                src={require('../assets/svg/icons/read_more_arrow.svg').default}
                alt="forward"
              />
            </div>
            <img
              src={require('../assets/png/nataliya_bg_black.png')}
              alt="nataliya"
            />
            <Signature />
          </div>
        </div>
      </section>
      <section className="subscribe">
        <img
          src={require('../assets/png/subscribe_bg.png')}
          alt="subscribe bg"
        />
        <div className="subscribe__header">
          <span className="subscribe__title static">
            {t(TranslationsKeys.mainPageSubscribedToNewslettersPreTitle)}
          </span>
          <span className="subscribe__subtitle static">
            {t(TranslationsKeys.mainPageSubscribedToNewslettersTitle)}
          </span>
        </div>
        <div className="subscribe__form">
          <FormForRequest type={FormForRequestType.SUBSCRIPTION} />
        </div>
      </section>
      <PreFooter />
      <Footer />
      <TermsOfUsageToast />
      <ButtonCwu />
      <SearchPane />
    </>
  );
}

export const MainPageConnector = connect(mapState, mapDispatch);

export const MainPage = MainPageConnector(Component);
