import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { Slider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { CheckBoxIcon } from '@components/icons/checkbox.icon';
import { AppDetails } from '@core/app_details';
import { useAdvancedNavigator } from '@hooks/advanced_navigator.hook';
import {
  AppRoutes,
  DropDownName,
  FilterPopupAnchor,
  FormInputType,
  LocationId,
  PropertyObjectType,
  SwitcherName,
} from '@project/enums';
import { CustomersActions } from '@store/items/customers';
import { TranslationsKeys } from '@translations/translations_keys';

const mapState = ({ customers }: RootState) => ({
  isInSearchMode: customers.isInSearchMode,
  filters: customers.filters,
});

const mapDispatch = {
  setSearchMode: (value: boolean) => CustomersActions.setSearchMode(value),
  setFilters: (value: Nullable<CustomerFilterSettings>) =>
    CustomersActions.setFilters(value),
};

const landAreaLimitation: DataRange = AppDetails.landAreaLimitation;
const buildingAreaLimitation: DataRange = AppDetails.buildingAreaLimitation;
const bathroomsLimitation: DataRange = AppDetails.bathroomsLimitation;
const priceLimitation: DataRange = AppDetails.priceLimitation;
const bedRoomsLimitation: DataRange = AppDetails.bedRoomsLimitation;

const initialStateFilters: CustomerFilterSettings = {
  freeSearch: null,
  landArea: landAreaLimitation,
  buildingArea: buildingAreaLimitation,
  bathrooms: bathroomsLimitation,
  price: priceLimitation,
  bedrooms: bedRoomsLimitation,
  types: [],
  locations: [],
};

const initialStatePopupAnchors: PopupAnchors = {
  [FilterPopupAnchor.ADVANCED]: {
    anchor: null,
    isOpened: false,
  },
  [FilterPopupAnchor.PRICE_RANGE]: {
    anchor: null,
    isOpened: false,
  },
  [FilterPopupAnchor.BEDROOMS_RANGE]: {
    anchor: null,
    isOpened: false,
  },
  [FilterPopupAnchor.PROPERTY_TYPE]: {
    anchor: null,
    isOpened: false,
  },
  [FilterPopupAnchor.LOCATION]: {
    anchor: null,
    isOpened: false,
  },
};

const propertiesTypes: DropDownItem[] = [
  {
    id: PropertyObjectType.VILLA,
    name: 'Villa',
  },
  {
    id: PropertyObjectType.APARTMENT,
    name: 'Apartment',
  },
  {
    id: PropertyObjectType.PENTHOUSE,
    name: 'Penthouse',
  },
  {
    id: PropertyObjectType.HOTEL,
    name: 'Hotel',
  },
  {
    id: PropertyObjectType.TOWNHOUSE,
    name: 'Townhouse',
  },
  {
    id: PropertyObjectType.PLOT,
    name: 'Plot',
  },
  {
    id: PropertyObjectType.COMMERCIAL,
    name: 'Commercial',
  },
];

const locations: DropDownItem[] = [
  {
    id: LocationId.QUINTA_FORMOSA,
    name: 'Quinta Formosa',
  },
  {
    id: LocationId.VALE_DE_LOBO,
    name: 'Vale de Lobo',
  },
  {
    id: LocationId.ENCOSTA_DO_LOBO,
    name: 'Encosta do Lobo',
  },
  {
    id: LocationId.PINHEIROS_ALTOS,
    name: 'Pinheiros Altos',
  },
  {
    id: LocationId.VALE_VERDE,
    name: 'Vale Verde',
  },
  {
    id: LocationId.VILAS_ALVAS,
    name: 'Vilas Alvas',
  },
  {
    id: LocationId.VARANDAS_DO_LAGO,
    name: 'Varandas do Lago',
  },
  {
    id: LocationId.VALE_DO_GARRAO,
    name: 'Vale do Garrao',
  },
  {
    id: LocationId.QUINTA_DO_MAR,
    name: 'Quinta do Mar',
  },
  {
    id: LocationId.QUINTA_VERDE,
    name: 'Quinta Verde',
  },
  {
    id: LocationId.FONTE_SANTA,
    name: 'Fonte Santa',
  },
  {
    id: LocationId.ALMANCIL,
    name: 'Almancil',
  },
  {
    id: LocationId.VALE_FORMOSO,
    name: 'Vale Formoso',
  },
  {
    id: LocationId.QUARTEIRA,
    name: 'Quarteira',
  },
  {
    id: LocationId.VILAMOURA,
    name: 'Vilamoura',
  },
  {
    id: LocationId.ENCOSTA_DAS_OLIVEIRAS,
    name: 'Encosta das Oliveiras',
  },
  {
    id: LocationId.VILA_SOL,
    name: 'Vila Sol',
  },
  {
    id: LocationId.LOULE,
    name: 'Loule',
  },
  {
    id: LocationId.OLHOS_DE_AGUA,
    name: 'Olhos de Agua',
  },
  {
    id: LocationId.MONCARAPACHO,
    name: 'Moncarapacho',
  },
  {
    id: LocationId.PORCHES,
    name: 'Porches',
  },
  {
    id: LocationId.PARCHAL,
    name: 'Parchal',
  },
  {
    id: LocationId.MONTE_REI_GOLF_RESORT,
    name: 'Monte Rei Golf Resort',
  },
  {
    id: LocationId.ALBUFEIRA,
    name: 'Albufeira',
  },
];

function Component({
  filters,
  isInSearchMode,
  setSearchMode,
  setFilters,
}: SearchPaneProps): Nullable<JSX.Element> {
  const location = useLocation();
  const { navigator } = useAdvancedNavigator();
  const { t } = useTranslation();

  const [popupSearchRequest, setPopupSearchRequest] =
    useState<Nullable<string>>(null);
  const [customerFilterSettings, setCustomerFilterSettings] =
    useState<CustomerFilterSettings>(initialStateFilters);

  const [popupAnchors, setPopupAnchors] = useState<PopupAnchors>(
    initialStatePopupAnchors,
  );

  const onSwitcherChanged = (name: SwitcherName, v: DataRange) => {
    setCustomerFilterSettings({
      ...customerFilterSettings,
      [name]: v,
    });
  };

  const onSearch = () => {
    const isInPropertiesGallery = location.pathname.endsWith(
      AppRoutes.PROPERTIES,
    );

    setFilters({ ...customerFilterSettings });

    if (!isInPropertiesGallery) {
      navigator(AppRoutes.PROPERTIES);
      return;
    }
  };

  const onClosePane = () => {
    setSearchMode(false);
    setFilters(null);

    setPopupAnchors(
      Object.fromEntries(
        Object.entries(popupAnchors).map(([k, _]) => [
          k,
          {
            isOpened: false,
            anchor: null,
          },
        ]),
      ),
    );
  };

  const onTogglePopup = (
    e: React.MouseEvent<HTMLElement>,
    anchorId: FilterPopupAnchor,
  ) => {
    setPopupAnchors({
      ...Object.fromEntries(
        Object.entries(popupAnchors).map(([k, _]) => [
          k,
          {
            isOpened: false,
            anchor: null,
          },
        ]),
      ),
      [anchorId]: {
        isOpened: !popupAnchors[anchorId].isOpened,
        anchor: popupAnchors[anchorId].anchor ? null : e.currentTarget,
      },
    });
  };

  const onSelectedChanged = (name: DropDownName, id: number) => {
    const e = customerFilterSettings[name] as any;

    if (e.includes(id)) {
      setCustomerFilterSettings({
        ...customerFilterSettings,
        [name]: customerFilterSettings[name].filter((v) => v !== id),
      });

      return;
    }

    setCustomerFilterSettings({
      ...customerFilterSettings,
      [name]: [...customerFilterSettings[name], id],
    });
  };

  useEffect(() => {
    setCustomerFilterSettings(filters ?? initialStateFilters);
  }, []);

  if (!isInSearchMode) {
    return null;
  }

  return (
    <div className="search-pane">
      <div className="search-pane__filters">
        <div className="search-pane__range primary">
          <span>FREE SEARCH</span>
          <div className="search-pane__free-search">
            <img
              src={require('../assets/svg/icons/search-primary.svg').default}
              alt="search"
            />
            <input
              autoComplete="off"
              spellCheck="false"
              type={FormInputType.TEXT}
              placeholder="Luxury villa with..."
              value={customerFilterSettings.freeSearch ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCustomerFilterSettings({
                  ...customerFilterSettings,
                  freeSearch: e.currentTarget.value.trim()
                    ? e.currentTarget.value.trim()
                    : null,
                })
              }
            />
          </div>
          <img
            src={require('../assets/svg/icons/search_panel/search.svg').default}
            alt="search"
          />
        </div>
        <div
          className="search-pane__range"
          onClick={(e) => onTogglePopup(e, FilterPopupAnchor.LOCATION)}
        >
          <span>LOCATION</span>
          <div className="search-pane__range-selector mw-200">
            <span>
              {`${
                locations.filter((v) =>
                  customerFilterSettings[DropDownName.LOCATIONS].includes(v.id),
                ).length
                  ? locations
                      .filter((v) =>
                        customerFilterSettings[DropDownName.LOCATIONS].includes(
                          v.id,
                        ),
                      )
                      .map((v) => v.name)
                      .join(', ')
                  : 'Select locations'
              }`}
            </span>
            <div className="search-pane__range-controllers">
              {customerFilterSettings[DropDownName.LOCATIONS].length ? (
                <div className="search-pane__range-amount">
                  {customerFilterSettings[DropDownName.LOCATIONS].length}
                </div>
              ) : null}
              {customerFilterSettings[DropDownName.LOCATIONS].length ? (
                <div
                  className="search-pane__range-hitbox"
                  onClick={(e) => {
                    e.stopPropagation();

                    setCustomerFilterSettings({
                      ...customerFilterSettings,
                      [DropDownName.LOCATIONS]: [],
                    });
                  }}
                >
                  <img
                    src={
                      require('../assets/svg/icons/search_close.svg').default
                    }
                    alt="reset"
                  />
                </div>
              ) : null}
              <div className="search-pane__range-hitbox">
                <img
                  src={
                    require('../assets/svg/icons/arrow_down_search.svg').default
                  }
                  alt="open drop down"
                />
              </div>
            </div>
          </div>
          <img
            src={
              require('../assets/svg/icons/search_panel/location.svg').default
            }
            alt="location"
          />
          <BasePopup
            id={
              popupAnchors[FilterPopupAnchor.LOCATION].isOpened
                ? 'placement-popper'
                : undefined
            }
            open={Boolean(popupAnchors[FilterPopupAnchor.LOCATION].anchor)}
            anchor={popupAnchors[FilterPopupAnchor.LOCATION].anchor}
          >
            <div className="dd-filter" onClick={(e) => e.stopPropagation()}>
              <div className="dd-filter__container">
                <div className="dd-filter__search">
                  <img
                    src={require('../assets/svg/icons/search-gray.svg').default}
                    alt="search"
                  />
                  <input
                    autoComplete="off"
                    spellCheck="false"
                    type={FormInputType.TEXT}
                    placeholder="Search"
                    value={popupSearchRequest ?? ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPopupSearchRequest(e.currentTarget.value)
                    }
                  />
                </div>
                <div className="dd-filter__container-layout">
                  {locations
                    .filter((v) =>
                      popupSearchRequest
                        ? v.name
                            .toLowerCase()
                            .includes(
                              popupSearchRequest
                                .toLowerCase()
                                .trim()
                                .replaceAll(new RegExp('\\s+', 'gim'), ' '),
                            )
                        : true,
                    )
                    .map((type) => (
                      <div
                        key={type.name}
                        className="dd-filter__item"
                        onClick={() =>
                          onSelectedChanged(DropDownName.LOCATIONS, type.id)
                        }
                      >
                        <span>{type.name}</span>
                        <CheckBoxIcon
                          isChecked={customerFilterSettings[
                            DropDownName.LOCATIONS
                          ].includes(type.id)}
                          disabled={false}
                          secondary
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div className="triangle" />
            </div>
          </BasePopup>
        </div>
        <div
          className="search-pane__range"
          onClick={(e) => onTogglePopup(e, FilterPopupAnchor.PROPERTY_TYPE)}
        >
          <span>PROPERTY TYPE</span>
          <div className="search-pane__range-selector mw-200">
            <span>
              {`${
                propertiesTypes.filter((v) =>
                  customerFilterSettings[DropDownName.PROPERTY_TYPES].includes(
                    v.id,
                  ),
                ).length
                  ? propertiesTypes
                      .filter((v) =>
                        customerFilterSettings[
                          DropDownName.PROPERTY_TYPES
                        ].includes(v.id),
                      )
                      .map((v) => v.name)
                      .join(', ')
                  : 'Select types'
              }`}
            </span>
            <div className="search-pane__range-controllers">
              {customerFilterSettings[DropDownName.PROPERTY_TYPES].length ? (
                <div className="search-pane__range-amount">
                  {customerFilterSettings[DropDownName.PROPERTY_TYPES].length}
                </div>
              ) : null}
              {customerFilterSettings[DropDownName.PROPERTY_TYPES].length ? (
                <div
                  className="search-pane__range-hitbox"
                  onClick={(e) => {
                    e.stopPropagation();

                    setCustomerFilterSettings({
                      ...customerFilterSettings,
                      [DropDownName.PROPERTY_TYPES]: [],
                    });
                  }}
                >
                  <img
                    src={
                      require('../assets/svg/icons/search_close.svg').default
                    }
                    alt="reset"
                  />
                </div>
              ) : null}
              <div className="search-pane__range-hitbox">
                <img
                  src={
                    require('../assets/svg/icons/arrow_down_search.svg').default
                  }
                  alt="open drop down"
                />
              </div>
            </div>
          </div>
          <img
            src={
              require('../assets/svg/icons/search_panel/property_type.svg')
                .default
            }
            alt="bedrooms"
          />
          <BasePopup
            id={
              popupAnchors[FilterPopupAnchor.PROPERTY_TYPE].isOpened
                ? 'placement-popper'
                : undefined
            }
            open={Boolean(popupAnchors[FilterPopupAnchor.PROPERTY_TYPE].anchor)}
            anchor={popupAnchors[FilterPopupAnchor.PROPERTY_TYPE].anchor}
          >
            <div className="dd-filter" onClick={(e) => e.stopPropagation()}>
              <div className="dd-filter__container">
                {propertiesTypes.map((type) => (
                  <div
                    key={type.name}
                    className="dd-filter__item"
                    onClick={() =>
                      onSelectedChanged(DropDownName.PROPERTY_TYPES, type.id)
                    }
                  >
                    <span>{type.name}</span>
                    <CheckBoxIcon
                      isChecked={customerFilterSettings[
                        DropDownName.PROPERTY_TYPES
                      ].includes(type.id)}
                      disabled={false}
                      secondary
                    />
                  </div>
                ))}
              </div>
              <div className="triangle" />
            </div>
          </BasePopup>
        </div>
        <div
          className="search-pane__range"
          onClick={(e) => onTogglePopup(e, FilterPopupAnchor.BEDROOMS_RANGE)}
        >
          <span>BEDROOMS</span>
          <div className="search-pane__range-selector">
            <span>
              {`${customerFilterSettings[
                SwitcherName.BEDROOMS
              ][0].toUserFriendlyPrice()} - ${customerFilterSettings[
                SwitcherName.BEDROOMS
              ][1].toUserFriendlyPrice()}`}
            </span>
            {customerFilterSettings[SwitcherName.BEDROOMS][0] !==
              bedRoomsLimitation[0] ||
            customerFilterSettings[SwitcherName.BEDROOMS][1] !==
              bedRoomsLimitation[1] ? (
              <div
                className="search-pane__range-hitbox"
                onClick={(e) => {
                  e.stopPropagation();

                  setCustomerFilterSettings({
                    ...customerFilterSettings,
                    [SwitcherName.BEDROOMS]: bedRoomsLimitation,
                  });
                }}
              >
                <img
                  src={require('../assets/svg/icons/search_close.svg').default}
                  alt="reset"
                />
              </div>
            ) : null}
            <div className="search-pane__range-hitbox">
              <img
                src={
                  require('../assets/svg/icons/arrow_down_search.svg').default
                }
                alt="open drop down"
              />
            </div>
          </div>
          <img
            src={
              require('../assets/svg/icons/search_panel/bedrooms.svg').default
            }
            alt="bedrooms"
          />
          <BasePopup
            id={
              popupAnchors[FilterPopupAnchor.BEDROOMS_RANGE].isOpened
                ? 'placement-popper'
                : undefined
            }
            open={Boolean(
              popupAnchors[FilterPopupAnchor.BEDROOMS_RANGE].anchor,
            )}
            anchor={popupAnchors[FilterPopupAnchor.BEDROOMS_RANGE].anchor}
          >
            <div className="range-filter" onClick={(e) => e.stopPropagation()}>
              <div className="range-filter__container">
                <span className="range-filter__label">Bedrooms</span>
                <Slider
                  min={bedRoomsLimitation[0]}
                  max={bedRoomsLimitation[1]}
                  value={customerFilterSettings[SwitcherName.BEDROOMS]}
                  onChange={(_, v) =>
                    onSwitcherChanged(SwitcherName.BEDROOMS, v as DataRange)
                  }
                />
                <div className="range-filter__range">
                  <span>
                    {customerFilterSettings[
                      SwitcherName.BEDROOMS
                    ][0].toDataRange()}
                  </span>
                  <span>
                    {customerFilterSettings[
                      SwitcherName.BEDROOMS
                    ][1].toDataRange()}
                  </span>
                </div>
              </div>
              <div className="triangle" />
            </div>
          </BasePopup>
        </div>
        <div
          className="search-pane__range"
          onClick={(e) => onTogglePopup(e, FilterPopupAnchor.PRICE_RANGE)}
        >
          <span>PRICE, €</span>
          <div className="search-pane__range-selector">
            <span>
              {`${customerFilterSettings[
                SwitcherName.PRICE
              ][0].toUserFriendlyPrice()} - ${customerFilterSettings[
                SwitcherName.PRICE
              ][1].toUserFriendlyPrice()}`}
            </span>
            {customerFilterSettings[SwitcherName.PRICE][0] !==
              priceLimitation[0] ||
            customerFilterSettings[SwitcherName.PRICE][1] !==
              priceLimitation[1] ? (
              <div
                className="search-pane__range-hitbox"
                onClick={(e) => {
                  e.stopPropagation();

                  setCustomerFilterSettings({
                    ...customerFilterSettings,
                    [SwitcherName.PRICE]: priceLimitation,
                  });
                }}
              >
                <img
                  src={require('../assets/svg/icons/search_close.svg').default}
                  alt="reset"
                />
              </div>
            ) : null}
            <div className="search-pane__range-hitbox">
              <img
                src={
                  require('../assets/svg/icons/arrow_down_search.svg').default
                }
                alt="open drop down"
              />
            </div>
          </div>
          <img
            src={require('../assets/svg/icons/search_panel/price.svg').default}
            alt="price"
          />
          <BasePopup
            id={
              popupAnchors[FilterPopupAnchor.PRICE_RANGE].isOpened
                ? 'placement-popper'
                : undefined
            }
            open={Boolean(popupAnchors[FilterPopupAnchor.PRICE_RANGE].anchor)}
            anchor={popupAnchors[FilterPopupAnchor.PRICE_RANGE].anchor}
          >
            <div className="range-filter" onClick={(e) => e.stopPropagation()}>
              <div className="range-filter__container">
                <span className="range-filter__label">Price, €</span>
                <Slider
                  min={priceLimitation[0]}
                  max={priceLimitation[1]}
                  value={customerFilterSettings[SwitcherName.PRICE]}
                  onChange={(_, v) =>
                    onSwitcherChanged(SwitcherName.PRICE, v as DataRange)
                  }
                />
                <div className="range-filter__range">
                  <span>
                    {customerFilterSettings[
                      SwitcherName.PRICE
                    ][0].toDataRange()}
                  </span>
                  <span>
                    {customerFilterSettings[
                      SwitcherName.PRICE
                    ][1].toDataRange()}
                  </span>
                </div>
              </div>
              <div className="triangle" />
            </div>
          </BasePopup>
        </div>
        <div
          className="search-pane__hitbox mobile"
          onClick={(e) => onTogglePopup(e, FilterPopupAnchor.ADVANCED)}
        >
          <img
            src={require('../assets/svg/icons/settings.svg').default}
            alt="settings"
          />
          <BasePopup
            id={
              popupAnchors[FilterPopupAnchor.ADVANCED].isOpened
                ? 'placement-popper'
                : undefined
            }
            open={Boolean(popupAnchors[FilterPopupAnchor.ADVANCED].anchor)}
            anchor={popupAnchors[FilterPopupAnchor.ADVANCED].anchor}
            placement="top-end"
          >
            <div
              className="advanced-filters"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="advanced-filters__container">
                <div className="advanced-filters__layout">
                  <div className="advanced-filters__slider-item">
                    <span className="advanced-filters__label">
                      Land area, m²
                    </span>
                    <Slider
                      min={landAreaLimitation[0]}
                      max={landAreaLimitation[1]}
                      value={customerFilterSettings[SwitcherName.LAND_AREA]}
                      onChange={(_, v) =>
                        onSwitcherChanged(
                          SwitcherName.LAND_AREA,
                          v as DataRange,
                        )
                      }
                    />
                    <div className="advanced-filters__range">
                      <span>
                        {customerFilterSettings[
                          SwitcherName.LAND_AREA
                        ][0].toDataRange()}
                      </span>
                      <span>
                        {customerFilterSettings[
                          SwitcherName.LAND_AREA
                        ][1].toDataRange()}
                      </span>
                    </div>
                  </div>
                  <div className="advanced-filters__slider-item">
                    <span className="advanced-filters__label">
                      Building area, m²
                    </span>
                    <Slider
                      min={buildingAreaLimitation[0]}
                      max={buildingAreaLimitation[1]}
                      value={customerFilterSettings[SwitcherName.BUILDING_AREA]}
                      onChange={(_, v) =>
                        onSwitcherChanged(
                          SwitcherName.BUILDING_AREA,
                          v as DataRange,
                        )
                      }
                    />
                    <div className="advanced-filters__range">
                      <span>
                        {customerFilterSettings[
                          SwitcherName.BUILDING_AREA
                        ][0].toDataRange()}
                      </span>
                      <span>
                        {customerFilterSettings[
                          SwitcherName.BUILDING_AREA
                        ][1].toDataRange()}
                      </span>
                    </div>
                  </div>
                  <div className="advanced-filters__slider-item">
                    <span className="advanced-filters__label">Bathrooms</span>
                    <Slider
                      min={bathroomsLimitation[0]}
                      max={bathroomsLimitation[1]}
                      value={customerFilterSettings[SwitcherName.BATHROOMS]}
                      onChange={(_, v) =>
                        onSwitcherChanged(
                          SwitcherName.BATHROOMS,
                          v as DataRange,
                        )
                      }
                    />
                    <div className="advanced-filters__range">
                      <span>
                        {customerFilterSettings[
                          SwitcherName.BATHROOMS
                        ][0].toDataRange()}
                      </span>
                      <span>
                        {customerFilterSettings[
                          SwitcherName.BATHROOMS
                        ][1].toDataRange()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="advanced-filters__layout"></div>
              </div>
              <div className="triangle" />
            </div>
          </BasePopup>
        </div>
      </div>
      <div className="search-pane__controllers">
        <div
          className="search-pane__hitbox"
          onClick={(e) => onTogglePopup(e, FilterPopupAnchor.ADVANCED)}
        >
          <img
            src={require('../assets/svg/icons/settings.svg').default}
            alt="settings"
          />
          <BasePopup
            id={
              popupAnchors[FilterPopupAnchor.ADVANCED].isOpened
                ? 'placement-popper'
                : undefined
            }
            open={Boolean(popupAnchors[FilterPopupAnchor.ADVANCED].anchor)}
            anchor={popupAnchors[FilterPopupAnchor.ADVANCED].anchor}
            placement="top-end"
          >
            <div
              className="advanced-filters"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="advanced-filters__container">
                <div className="advanced-filters__layout">
                  <div className="advanced-filters__slider-item">
                    <span className="advanced-filters__label">
                      Land area, m²
                    </span>
                    <Slider
                      min={landAreaLimitation[0]}
                      max={landAreaLimitation[1]}
                      value={customerFilterSettings[SwitcherName.LAND_AREA]}
                      onChange={(_, v) =>
                        onSwitcherChanged(
                          SwitcherName.LAND_AREA,
                          v as DataRange,
                        )
                      }
                    />
                    <div className="advanced-filters__range">
                      <span>
                        {customerFilterSettings[
                          SwitcherName.LAND_AREA
                        ][0].toDataRange()}
                      </span>
                      <span>
                        {customerFilterSettings[
                          SwitcherName.LAND_AREA
                        ][1].toDataRange()}
                      </span>
                    </div>
                  </div>
                  <div className="advanced-filters__slider-item">
                    <span className="advanced-filters__label">
                      Building area, m²
                    </span>
                    <Slider
                      min={buildingAreaLimitation[0]}
                      max={buildingAreaLimitation[1]}
                      value={customerFilterSettings[SwitcherName.BUILDING_AREA]}
                      onChange={(_, v) =>
                        onSwitcherChanged(
                          SwitcherName.BUILDING_AREA,
                          v as DataRange,
                        )
                      }
                    />
                    <div className="advanced-filters__range">
                      <span>
                        {customerFilterSettings[
                          SwitcherName.BUILDING_AREA
                        ][0].toDataRange()}
                      </span>
                      <span>
                        {customerFilterSettings[
                          SwitcherName.BUILDING_AREA
                        ][1].toDataRange()}
                      </span>
                    </div>
                  </div>
                  <div className="advanced-filters__slider-item">
                    <span className="advanced-filters__label">Bathrooms</span>
                    <Slider
                      min={bathroomsLimitation[0]}
                      max={bathroomsLimitation[1]}
                      value={customerFilterSettings[SwitcherName.BATHROOMS]}
                      onChange={(_, v) =>
                        onSwitcherChanged(
                          SwitcherName.BATHROOMS,
                          v as DataRange,
                        )
                      }
                    />
                    <div className="advanced-filters__range">
                      <span>
                        {customerFilterSettings[
                          SwitcherName.BATHROOMS
                        ][0].toDataRange()}
                      </span>
                      <span>
                        {customerFilterSettings[
                          SwitcherName.BATHROOMS
                        ][1].toDataRange()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="advanced-filters__layout"></div>
              </div>
              <div className="triangle" />
            </div>
          </BasePopup>
        </div>
        <div className="search-pane__hitbox primary" onClick={onSearch}>
          <img
            src={require('../assets/svg/icons/search_white.svg').default}
            alt="search"
          />
          <img
            className="mobile"
            src={
              require('../assets/svg/icons/search_panel/forward.svg').default
            }
            alt="search"
          />
          <span>{t(TranslationsKeys.searchPaneSearch)}</span>
        </div>
        <div className="search-pane__hitbox" onClick={onClosePane}>
          <img
            src={require('../assets/svg/icons/close_no_border.svg').default}
            alt="close"
          />
        </div>
      </div>
      {/* <div className="search-pane__form-input">
        <img
          className="search-icon"
          src={require('../assets/svg/icons/search.svg').default}
          alt="search"
          onClick={setFormIsOpened.bind(null, !formIsOpened)}
        />
        <FormInput
          name="FREE SEARCH"
          type={FormInputType.TEXT}
          label="FREE SEARCH"
          placeholder="Villa with 5 bedrooms"
          value=""
          error={undefined}
          //@ts-ignore
          onValueChanged={undefined}
        />
        <div
          className={`search-pane__form-input-layout ${
            formIsOpened ? 'opened' : ''
          }`}
        >
          <img
            src={require('../assets/svg/icons/search.svg').default}
            alt="search_close"
          />
          <input type="text" placeholder="Search" />
          <div className="triangle"></div>
        </div>
      </div>
      <SearchForm
        label="LOCATION"
        withSearchWidget={true}
        icon={
          require('../assets/svg/icons/search_panel.svg/location.svg').default
        }
      />
      <SearchForm
        label="PROPERTY TYPE"
        icon={
          require('../assets/svg/icons/search_panel.svg/property_type.svg')
            .default
        }
      />
      <SearchForm
        label="BEDROOMS"
        title="Bedrooms"
        isSlider={true}
        icon={
          require('../assets/svg/icons/search_panel.svg/bedrooms.svg').default
        }
      />
      <SearchForm
        label="PRICE RANGE"
        title="Price range"
        isSlider={true}
        icon={require('../assets/svg/icons/search_panel.svg/price.svg').default}
      />
      <div className="search-pane__tool">
        <div className={`search-pane__layout  ${isOpened ? 'opened' : ''}`}>
          <div className="search-pane__column">
            <SearchForm
              label="OWNERSHIP"
              extraClass="setting-form"
              isSettingTools={true}
            />
            <SearchForm
              label="PROPERTY FEATURES"
              extraClass="setting-form"
              isSettingTools={true}
            />
          </div>
          <div className="search-pane__column">
            <div className="search-pane__item">
              <SliderElement title="Building area, m2" />
            </div>
            <div className="search-pane__item">
              <SliderElement title="Land area, m2" />
            </div>
          </div>
          <div className="search-pane__column">
            <div className="search-pane__item">
              <SliderElement title="Bathrooms" />
            </div>
            <div className="search-pane__switcher-item">
              <Switcher
                label="GARAGE"
                icon={require('../assets/svg/icons/garage.svg').default}
              />
              <Switcher
                label="SWIMMING POOL "
                icon={
                  // eslint-disable-next-line max-len
                  require('../assets/svg/icons/property_item/swimming_pool.svg')
                    .default
                }
              />
            </div>
          </div>
          <div className="search-pane__triangle"></div>
        </div>
        <img
          src={require('../assets/svg/icons/settings.svg').default}
          alt="settings"
          // ??
          onClick={setIsOpened.bind(null, !isOpened)}
        />
      </div> */}
    </div>
  );
}

export const SearchPaneConnector = connect(mapState, mapDispatch);

export const SearchPane = SearchPaneConnector(Component);
