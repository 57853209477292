import { ReduxActionType } from '@project/enums';

const initialState: ReviewsState = {
  customersReviews: null,
};

export function reviewsReducer(
  state: ReviewsState = initialState,
  action: DispatchWithPayload<CustomerReview[]>,
): ReviewsState {
  switch (action.type) {
    default:
      return state;

    case ReduxActionType.REVIEWS_SET_LATEST:
      return {
        ...state,
        customersReviews: [...(action.payload as CustomerReview[])],
      };
  }
}

export class ReviewsActions {
  public static requestForLatest(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.REVIEWS_REQUEST_GET_LATEST,
    };
  }

  public static setLatest(
    payload: CustomerReview[],
  ): DispatchWithPayload<CustomerReview[]> {
    return {
      type: ReduxActionType.REVIEWS_SET_LATEST,
      payload,
    };
  }
}
