export function FavoriteIcon({
  shouldUseDarkTheme = false,
}: NavbarIconProps): JSX.Element {
  return (
    <svg
      width="29"
      height="25"
      viewBox="0 0 29 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={shouldUseDarkTheme ? 'icon-dark' : 'icon-light'}
    >
      <path
        // eslint-disable-next-line max-len
        d="M13.887 4.2C14.0275 4.39929 14.2562 4.51783 14.5 4.51783C14.7438 4.51783 14.9725 4.39929 15.113 4.2C16.3719 2.4144 18.4497 1.25 20.8 1.25C24.6392 1.25 27.75 4.35839 27.75 8.19093C27.75 11.748 25.6336 15.2384 22.8049 18.0916C20.078 20.8422 16.8083 22.8788 14.5 23.7066C12.1917 22.8788 8.92201 20.8422 6.19512 18.0916C3.36643 15.2384 1.25 11.748 1.25 8.19093C1.25 4.35839 4.36079 1.25 8.2 1.25C10.5503 1.25 12.6281 2.4144 13.887 4.2Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
