import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ButtonCwu } from '@components/button_cwu';
import { CommonQuestionItem } from '@components/common_question_item';
import { Footer } from '@components/footer';
import { Navbar } from '@components/navbar';
import { PreFooter } from '@components/pre_footer';
import { SearchPane } from '@components/search_pane';
import { TermsOfUsageToast } from '@components/terms_of_usage_toast';
import { TownFaq } from '@components/town';
import { CommonQuestionsActions } from '@store/items/common_questions';
import { TranslationsKeys } from '@translations/translations_keys';

const mapState = ({ commonQuestions }: RootState) => ({
  commonQuestions: commonQuestions.data,
});

const mapDispatch = {
  requestForAll: () => CommonQuestionsActions.requestForAll(),
};

function Component({
  requestForAll,
  commonQuestions,
}: FaqPageProps): JSX.Element {
  const { t } = useTranslation();

  useEffect(() => {
    requestForAll();
  }, []);

  return (
    <>
      <Navbar shouldUseDarkTheme />
      <section className="faq">
        <div className="faq__bg">
          <TownFaq />
        </div>
        <div className="faq__fg">
          <h1>{t(TranslationsKeys.faqPageTitle)}</h1>
        </div>
      </section>
      <section className="faq-content">
        {commonQuestions
          ? commonQuestions.map((v, i) => (
              <CommonQuestionItem key={i} value={v.value} answer={v.answer} />
            ))
          : null}
      </section>
      <PreFooter />
      <Footer />
      <TermsOfUsageToast />
      <ButtonCwu />
      <SearchPane />
    </>
  );
}

export const FaqPageConnector = connect(mapState, mapDispatch);

export const FaqPage = FaqPageConnector(Component);
