import { AvailableTranslationLang, ReduxActionType } from '@project/enums';

const initialState: CustomersState = {
  lang: null,
  wasAcceptTermsOfUsage: null,
  isInSearchMode: true,
  favoritesProperties: [],
  filters: null,
};

export function customersReducer(
  state: CustomersState = initialState,
  action: DispatchWithPayload<
    Lang | boolean | number[] | number | Nullable<CustomerFilterSettings>
  >,
): CustomersState {
  switch (action.type) {
    default:
      return state;

    case ReduxActionType.CUSTOMERS_SET_LANG_DETAILS:
      return {
        ...state,
        lang: action.payload as Lang,
      };

    case ReduxActionType.CUSTOMERS_SET_TOU:
      return {
        ...state,
        wasAcceptTermsOfUsage: action.payload as boolean,
      };

    case ReduxActionType.CUSTOMERS_SET_FAVORITES:
      return {
        ...state,
        favoritesProperties: [...(action.payload as number[])],
      };

    case ReduxActionType.CUSTOMERS_ADD_FAVORITE:
      return {
        ...state,
        favoritesProperties: [
          action.payload as number,
          ...state.favoritesProperties,
        ],
      };

    case ReduxActionType.CUSTOMERS_DELETE_FAVORITE:
      return {
        ...state,
        favoritesProperties: [
          ...state.favoritesProperties.filter(
            (favoriteId) => favoriteId !== (action.payload as number),
          ),
        ],
      };

    case ReduxActionType.CUSTOMERS_SET_SEARCH_MODE:
      return {
        ...state,
        isInSearchMode: action.payload as boolean,
      };

    case ReduxActionType.CUSTOMERS_SET_FILTERS:
      return {
        ...state,
        filters: action.payload as Nullable<CustomerFilterSettings>,
      };
  }
}

export class CustomersActions {
  public static requestGetLangDetails(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.CUSTOMERS_REQUEST_GET_LANG_DETAILS,
    };
  }

  public static requestUpdateLangDetails(
    payload: AvailableTranslationLang,
  ): DispatchWithPayload<AvailableTranslationLang> {
    return {
      type: ReduxActionType.CUSTOMERS_REQUEST_UPDATE_LANG_DETAILS,
      payload,
    };
  }

  public static requestGetTermsAcceptance(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.CUSTOMERS_REQUEST_GET_TERMS_ACCEPTANCE,
    };
  }

  public static requestSubscribe(
    payload: CreateCustomerDto,
  ): DispatchWithPayload<CreateCustomerDto> {
    return {
      type: ReduxActionType.CUSTOMERS_REQUEST_SUBSCRIBE,
      payload,
    };
  }

  public static requestForCallback(
    payload: CustomerRequest,
  ): DispatchWithPayload<CustomerRequest> {
    return {
      type: ReduxActionType.CUSTOMERS_REQUEST_FORM,
      payload,
    };
  }

  public static requestForAcceptTermsOfUsage(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.CUSTOMERS_REQUEST_FOR_ACCEPT_TOU,
    };
  }

  public static requestForFavorites(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.CUSTOMERS_REQUEST_FOR_FAVORITES,
    };
  }

  public static requestForAddFavorite(
    payload: number,
  ): DispatchWithPayload<number> {
    return {
      type: ReduxActionType.CUSTOMERS_REQUEST_ADD_FAVORITE,
      payload,
    };
  }

  public static requestForDeleteFavorite(
    payload: number,
  ): DispatchWithPayload<number> {
    return {
      type: ReduxActionType.CUSTOMERS_REQUEST_DELETE_FAVORITE,
      payload,
    };
  }

  public static setLangDetails(payload: Lang): DispatchWithPayload<Lang> {
    return {
      type: ReduxActionType.CUSTOMERS_SET_LANG_DETAILS,
      payload,
    };
  }

  public static setTermsOfUsage(
    payload: boolean,
  ): DispatchWithPayload<boolean> {
    return {
      type: ReduxActionType.CUSTOMERS_SET_TOU,
      payload,
    };
  }

  public static setFavorites(payload: number[]): DispatchWithPayload<number[]> {
    return {
      type: ReduxActionType.CUSTOMERS_SET_FAVORITES,
      payload,
    };
  }

  public static addFavorite(payload: number): DispatchWithPayload<number> {
    return {
      type: ReduxActionType.CUSTOMERS_ADD_FAVORITE,
      payload,
    };
  }

  public static deleteFavorite(payload: number): DispatchWithPayload<number> {
    return {
      type: ReduxActionType.CUSTOMERS_DELETE_FAVORITE,
      payload,
    };
  }

  public static setSearchMode(payload: boolean): DispatchWithPayload<boolean> {
    return {
      type: ReduxActionType.CUSTOMERS_SET_SEARCH_MODE,
      payload,
    };
  }

  public static setFilters(
    payload: Nullable<CustomerFilterSettings>,
  ): DispatchWithPayload<Nullable<CustomerFilterSettings>> {
    return {
      type: ReduxActionType.CUSTOMERS_SET_FILTERS,
      payload,
    };
  }
}
