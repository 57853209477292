export function ArrowBottomIcon({
  shouldUseDarkTheme = false,
}: NavbarIconProps): JSX.Element {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={shouldUseDarkTheme ? 'icon-dark' : 'icon-light'}
    >
      <g clipPath="url(#clip0_2158_1574)">
        <path
          d="M14.9463 8.07861L10.1033 12.9216L5.26025 8.07861"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2158_1574">
          <rect
            width="19.3721"
            height="19.3721"
            transform="translate(0.0136719 0.813965)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
