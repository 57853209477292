import { connect } from 'react-redux';

import { FavoriteIcon } from '@components/icons/favorite.icon';
import { useAdvancedNavigator } from '@hooks/advanced_navigator.hook';
import { AppRoutes } from '@project/enums';

const mapState = ({ customers }: RootState) => ({
  favoritesProperties: customers.favoritesProperties,
});

function Component({
  shouldUseDarkTheme = false,
  favoritesProperties,
}: FavoritesIndicatorsProps): JSX.Element {
  const { navigator } = useAdvancedNavigator();

  return (
    <div
      className="fi"
      onClick={navigator.bind(null, AppRoutes.FAVORITE_PROPERTIES, null)}
    >
      <FavoriteIcon shouldUseDarkTheme={shouldUseDarkTheme} />
      {favoritesProperties.length ? (
        <div className="fi__amount">
          {favoritesProperties.length > 9 ? '9+' : favoritesProperties.length}
        </div>
      ) : null}
    </div>
  );
}

export const FavoritesIndicatorsConnector = connect(mapState, null);

export const FavoritesIndicators = FavoritesIndicatorsConnector(Component);
