import { useState } from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from 'react-share';

export function ShareContentPopup({
  message,
}: ShareContentPopupProps): JSX.Element {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className="share">
      <img
        src={require('../assets/svg/icons/share_panel/link_button.svg').default}
        alt="link_button"
        onClick={setIsOpened.bind(null, !isOpened)}
      />
      {isOpened ? (
        <div className="share__scp">
          <div className="share__triangle"></div>
          <div className="share__scp__items">
            <FacebookShareButton
              title={message}
              content={message}
              children={
                <img
                  src={
                    require('../assets/svg/icons/share_panel/fb.svg').default
                  }
                  alt="facebook"
                />
              }
              url={location.href}
            />
            <WhatsappShareButton
              title={message}
              content={message}
              children={
                <img
                  src={
                    require('../assets/svg/icons/share_panel/whatsapp.svg')
                      .default
                  }
                  alt="whatsapp"
                />
              }
              url={location.href}
            />
            <TelegramShareButton
              title={message}
              content={message}
              children={
                <img
                  src={
                    require('../assets/svg/icons/share_panel/telegram.svg')
                      .default
                  }
                  alt="telegram"
                />
              }
              url={location.href}
            />
            <LinkedinShareButton
              title={message}
              content={message}
              children={
                <img
                  src={
                    require('../assets/svg/icons/share_panel/linkedin.svg')
                      .default
                  }
                  alt="linkedin"
                />
              }
              url={location.href}
            />
            <img
              onClick={() => navigator.clipboard.writeText(location.href)}
              src={require('../assets/svg/icons/copy.svg').default}
              alt="facebook"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
