/* eslint-disable max-len */

export const enum RuntimeMode {
  DEV = 'development',
  PROD = 'production',
}

export const enum FetchRequestType {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export const enum RequestHeader {
  CONTENT_TYPE = 'Content-Type',
  AUTHORIZATION = 'Authorization',
}

export const enum RequestHeaderValues {
  APPLICATION_JSON = 'application/json',
}

export const enum ResponseStatusCode {
  BAD_REQUEST = 400,
  INTERNAL_ERROR = 500,
}

export const enum LocalStorageKey {
  USER_LANG = 'userLang',
  WAS_USER_ACCEPT_TERMS_OF_USAGE = 'wasUserAcceptTermsOfUsage',
  FAVORITES = 'favorites',
}

export const enum FormInputType {
  EMAIL = 'email',
  TEXT = 'text',
}

export const enum FormName {
  FULL_NAME = 'fullName',
  EMAIL = 'email',
  SUBJECT = 'subject',
  MESSAGE = 'message',
}

export const enum SwitcherName {
  LAND_AREA = 'landArea',
  BUILDING_AREA = 'buildingArea',
  BATHROOMS = 'bathrooms',
  BEDROOMS = 'bedrooms',
  PRICE = 'price',
}

export const enum DropDownName {
  PROPERTY_TYPES = 'types',
  LOCATIONS = 'locations',
}

export const enum FilterPopupAnchor {
  ADVANCED = 'ADVANCED',
  PRICE_RANGE = 'PRICE_RANGE',
  BEDROOMS_RANGE = 'BEDROOMS_RANGE',
  PROPERTY_TYPE = 'PROPERTY_TYPE',
  LOCATION = 'LOCATION',
}

export const enum RouterAccessStatus {
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export const enum AppRoutes {
  ANY = '*',
  ROOT = '/',
  PROPERTIES = '/properties',
  FAVORITE_PROPERTIES = '/favorite-properties',
  PROPERTY = '/property/:refCode',
  SERVICES = '/services',
  SERVICE = '/service/:slug',
  NEWS = '/news',
  ARTICLE = '/article/:categorySlug/:slug',
  WHO_IS_NR = '/who-is-nataliya-roso',
  ABOUT_ALGARVE = '/about-algarve',
  FAQ = '/faq',
  TNC = '/terms-and-conditions',
  PRIVACY_POLICY = '/privacy-policy',
}

export const enum AppPassedAction {
  FORM_FOR_REQUEST_WAS_SENT = 'FORM_FOR_REQUEST_WAS_SENT',
  CONNECT_WITH_US_SENT = 'CONNECT_WITH_US_SENT',
}

export const enum AppError {
  FORM_FOR_REQUEST_ISSUE = 'FORM_FOR_REQUEST_ISSUE',
  UNABLE_TO_GET_PRIORITY_NEWS = 'UNABLE_TO_GET_PRIORITY_NEWS',
  UNABLE_TO_GET_PRIORITY_SERVICES = 'UNABLE_TO_GET_PRIORITY_SERVICES',
  UNABLE_TO_GET_EXACT_ARTICLE = 'UNABLE_TO_GET_EXACT_ARTICLE',
  UNABLE_TO_GET_EXACT_SERVICE = 'UNABLE_TO_GET_EXACT_SERVICE',
  UNABLE_TO_GET_RECOMMENDATIONS_ARTICLES = 'UNABLE_TO_GET_RECOMMENDATIONS_ARTICLES',
  UNABLE_TO_GET_RECOMMENDATIONS_SERVICES = 'UNABLE_TO_GET_RECOMMENDATIONS_SERVICES',
  UNABLE_TO_GET_LATEST_REVIEWS = 'UNABLE_TO_GET_LATEST_REVIEWS',
  UNABLE_TO_GET_PRIORITY_PROPERTY_PREVIEWS = 'UNABLE_TO_GET_PRIORITY_PROPERTY_PREVIEWS',
  UNABLE_TO_GET_ALL_PROPERTY_PREVIEWS = 'UNABLE_TO_GET_ALL_PROPERTY_PREVIEWS',
  UNABLE_TO_GET_EXACT_PROPERTY_PREVIEWS = 'UNABLE_TO_GET_EXACT_PROPERTY_PREVIEWS',
  UNABLE_TO_GET_FAVORITES_PROPERTY_PREVIEWS = 'UNABLE_TO_GET_FAVORITES_PROPERTY_PREVIEWS',
  UNABLE_TO_GET_COMMON_QUESTIONS = 'UNABLE_TO_GET_COMMON_QUESTIONS',
}

export const enum AppLoader {
  SPLASH_LOADING = 'SPLASH_LOADING',
  NAVBAR_LOADING = 'NAVBAR_LOADING',
  EXACT_ARTICLE_LOADING = 'EXACT_ARTICLE_LOADING',
  EXACT_SERVICE_LOADING = 'EXACT_SERVICE_LOADING',
  ARTICLE_RECOMMENDATIONS_LOADING = 'ARTICLE_RECOMMENDATIONS_LOADING',
  SERVICE_RECOMMENDATIONS_LOADING = 'SERVICE_RECOMMENDATIONS_LOADING',
  COMMON_QUESTIONS_LOADING = 'COMMON_QUESTIONS_LOADING',
  FAVORITES_PROPERTIES_LOADING = 'FAVORITES_PROPERTIES_LOADING',
}

export const enum ReduxActionType {
  APP_LOCK_UI = 'APP_LOCK_UI',
  APP_UNLOCK_UI = 'APP_UNLOCK_UI',
  APP_SET_ERROR = 'APP_SET_ERROR',
  APP_RESET_ERROR = 'APP_RESET_ERROR',
  APP_SET_LOADER = 'APP_SET_LOADER',
  APP_RESET_LOADER = 'APP_RESET_LOADER',
  APP_SET_PASSED_ACTION = 'APP_SET_PASSED_ACTION',
  APP_RESET_PASSED_ACTION = 'APP_RESET_PASSED_ACTION',
  APP_SET_DYNAMIC_PATH = 'APP_SET_DYNAMIC_PATH',
  APP_RESET_DYNAMIC_PATH = 'APP_RESET_DYNAMIC_PATH',

  CUSTOMERS_REQUEST_GET_LANG_DETAILS = 'CUSTOMERS_REQUEST_GET_LANG_DETAILS',
  CUSTOMERS_REQUEST_UPDATE_LANG_DETAILS = 'CUSTOMERS_REQUEST_UPDATE_LANG_DETAILS',
  CUSTOMERS_REQUEST_GET_TERMS_ACCEPTANCE = 'CUSTOMERS_REQUEST_GET_TERMS_ACCEPTANCE',
  CUSTOMERS_REQUEST_SUBSCRIBE = 'CUSTOMERS_REQUEST_SUBSCRIBE',
  CUSTOMERS_REQUEST_FORM = 'CUSTOMERS_REQUEST_FORM',
  CUSTOMERS_REQUEST_FOR_ACCEPT_TOU = 'CUSTOMERS_REQUEST_FOR_ACCEPT_TOU',
  CUSTOMERS_SET_LANG_DETAILS = 'CUSTOMERS_SET_LANG_DETAILS',
  CUSTOMERS_SET_TOU = 'CUSTOMERS_SET_TOU',
  CUSTOMERS_REQUEST_FOR_FAVORITES = 'CUSTOMERS_REQUEST_FOR_FAVORITES',
  CUSTOMERS_REQUEST_ADD_FAVORITE = 'CUSTOMERS_REQUEST_ADD_FAVORITE',
  CUSTOMERS_REQUEST_DELETE_FAVORITE = 'CUSTOMERS_REQUEST_DELETE_FAVORITE',
  CUSTOMERS_SET_FAVORITES = 'CUSTOMERS_SET_FAVORITES',
  CUSTOMERS_ADD_FAVORITE = 'CUSTOMERS_ADD_FAVORITE',
  CUSTOMERS_DELETE_FAVORITE = 'CUSTOMERS_DELETE_FAVORITE',
  CUSTOMERS_SET_SEARCH_MODE = 'CUSTOMERS_SET_SEARCH_MODE',
  CUSTOMERS_SET_FILTERS = 'CUSTOMERS_SET_FILTERS',

  ARTICLES_REQUEST_GET_ALL_NEWS = 'ARTICLES_REQUEST_GET_ALL_NEWS',
  ARTICLES_REQUEST_GET_PRIORITY_NEWS = 'ARTICLES_REQUEST_GET_PRIORITY_NEWS',
  ARTICLES_SET_PRIORITY_NEWS = 'ARTICLES_SET_PRIORITY_NEWS',
  ARTICLES_REQUEST_GET_EXACT_ARTICLE = 'ARTICLES_REQUEST_GET_EXACT_ARTICLE',
  ARTICLES_SET_EXACT_ARTICLE = 'ARTICLES_SET_EXACT_ARTICLE',
  ARTICLES_REQUEST_GET_RECOMMENDATION = 'ARTICLES_REQUEST_GET_RECOMMENDATION',
  ARTICLES_SET_RECOMMENDATION = 'ARTICLES_SET_RECOMMENDATION',

  REVIEWS_REQUEST_GET_LATEST = 'REVIEWS_REQUEST_GET_LATEST',
  REVIEWS_SET_LATEST = 'REVIEWS_SET_LATEST',

  SERVICES_REQUEST_GET_ALL = 'SERVICES_REQUEST_GET_ALL',
  SERVICES_REQUEST_GET_PRIORITY = 'SERVICES_REQUEST_GET_PRIORITY',
  SERVICES_SET_PRIORITY = 'SERVICES_SET_PRIORITY',
  SERVICES_REQUEST_GET_EXACT = 'SERVICES_REQUEST_GET_EXACT',
  SERVICES_SET_EXACT = 'SERVICES_SET_EXACT',
  SERVICES_REQUEST_GET_RECOMMENDATION = 'SERVICES_REQUEST_GET_RECOMMENDATION',
  SERVICES_SET_RECOMMENDATION = 'SERVICES_SET_RECOMMENDATION',

  COMMON_QUESTIONS_REQUEST_GET_ALL = 'COMMON_QUESTIONS_REQUEST_GET_ALL',
  COMMON_QUESTIONS_SET_DATA = 'COMMON_QUESTIONS_SET_DATA',

  PROPERTIES_REQUEST_GET_PRIORITY = 'PROPERTIES_REQUEST_GET_PRIORITY',
  PROPERTIES_REQUEST_GET_ALL = 'PROPERTIES_REQUEST_GET_ALL',
  PROPERTIES_REQUEST_GET_FAVORITES = 'PROPERTIES_REQUEST_GET_FAVORITES',
  PROPERTIES_REQUEST_GET_EXACT = 'PROPERTIES_REQUEST_GET_EXACT',
  PROPERTIES_SET_DATA = 'PROPERTIES_SET_DATA',
  PROPERTIES_SET_FAVORITES = 'PROPERTIES_SET_FAVORITES',
  PROPERTIES_SET_EXACT = 'PROPERTIES_SET_EXACT',
}

export const enum AvailableTranslationLang {
  EN_GB = 'en-GB',
  EN_US = 'en-US',
  PT = 'pt',
  UK = 'uk',
  FR = 'fr',
  IE = 'ie',
  DE = 'de',
  CN = 'cn',
}

export const enum CommonDomEvent {
  SCROLL = 'scroll',
  RESIZE = 'resize',
}

export const enum PropertyType {
  EXCLUSIVE = 'exclusive',
  SALE = 'sale',
  NEW = 'new',
}

export const enum MessageType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export const enum PropertySolarOrientation {
  NORTH = 1,
  EAST = 2,
  SOUTH = 3,
  WEST = 4,
}

export const enum PropertyEnergyCertificate {
  A_THREE_PLUS = 1,
  A_TWO_PLUS = 2,
  A_ONE_PLUS = 3,
  A = 4,
  B = 5,
  C = 6,
  D = 7,
  E = 8,
  F = 9,
  G = 10,
}

export const enum PropertyOwnership {
  COMPANY = 1,
  OFFSHORE = 2,
  PRIVATE = 3,
}

export const enum PropertyObjectType {
  VILLA = 1,
  APARTMENT = 2,
  PENTHOUSE = 3,
  HOTEL = 4,
  TOWNHOUSE = 5,
  PLOT = 6,
  COMMERCIAL = 7,
}

export const enum LocationId {
  QUINTA_FORMOSA = 1,
  VALE_DE_LOBO = 2,
  ENCOSTA_DO_LOBO = 3,
  PINHEIROS_ALTOS = 4,
  VALE_VERDE = 5,
  VILAS_ALVAS = 6,
  VARANDAS_DO_LAGO = 7,
  VALE_DO_GARRAO = 8,
  QUINTA_DO_MAR = 9,
  QUINTA_VERDE = 10,
  FONTE_SANTA = 11,
  ALMANCIL = 12,
  VALE_FORMOSO = 13,
  QUARTEIRA = 14,
  VILAMOURA = 15,
  ENCOSTA_DAS_OLIVEIRAS = 16,
  VILA_SOL = 17,
  LOULE = 18,
  OLHOS_DE_AGUA = 19,
  MONCARAPACHO = 20,
  PORCHES = 21,
  PARCHAL = 22,
  MONTE_REI_GOLF_RESORT = 23,
  ALBUFEIRA = 24,
}

export const enum FormForRequestType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  ASK_FOR_VIEWING = 'ASK_FOR_VIEWING',
  SELL_WITH_US = 'SELL_WITH_US',
  ASK_QUESTION = 'ASK_QUESTION',
  ASK_FOR_FREE_VALUATION = 'ASK_FOR_FREE_VALUATION',
  ASK_FOR_SERVICE = 'ASK_FOR_SERVICE',
  ASK_FOR_LONG_TERM = 'ASK_FOR_LONG_TERM',
  SAVE_FAVORITE_FILTERS = 'SAVE_FAVORITE_FILTERS',
}

export const enum CustomerRequestType {
  ASK_FOR_VIEWING = 1,
  SELL_WITH_US = 2,
  ASK_QUESTION = 3,
  ASK_FOR_FREE_VALUATION = 4,
  ASK_FOR_SERVICE = 5,
  ASK_FOR_LONG_TERM = 6,
}
