export function ReviewVideoPlayer({
  url,
  onClose,
}: ReviewVideoPlayerProps): JSX.Element {
  return (
    <div className="review-video-player" onClick={onClose}>
      <video controls autoPlay playsInline>
        <source src={url} type="video/mp4" />
      </video>
    </div>
  );
}
