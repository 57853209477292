/* eslint-disable max-len */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Achievements } from '@components/achievements';
import { ButtonCwu } from '@components/button_cwu';
import { Footer } from '@components/footer';
import { Navbar } from '@components/navbar';
import { PreFooter } from '@components/pre_footer';
import { SearchPane } from '@components/search_pane';
import { Signature } from '@components/signature';
import { TermsOfUsageToast } from '@components/terms_of_usage_toast';
import { AppDetails } from '@core/app_details';
import { CommonDomEvent } from '@project/enums';
import { TranslationsKeys } from '@translations/translations_keys';

export function WhoIsNrPage(): JSX.Element {
  const { t } = useTranslation();

  const onScroll = () => {
    const statics = document.querySelectorAll('.static');
    const { innerHeight } = window;

    for (const item of statics) {
      const { top } = item.getBoundingClientRect();

      if (top < innerHeight) {
        item.classList.add('animation');
      }
    }
  };

  useEffect(() => {
    window.addEventListener(CommonDomEvent.SCROLL, onScroll);
    onScroll();

    return () => {
      window.removeEventListener(CommonDomEvent.SCROLL, onScroll);
    };
  }, []);

  return (
    <>
      <Navbar shouldUseDarkTheme />
      <section className="who-is-nr">
        <div className="who-is-nr__content">
          <div className="who-is-nr__photo static">
            <Signature reversed={true} />
            <img
              src={require('../assets/png/nataliya_bg_white.png')}
              alt={t(TranslationsKeys.whoIsNrPageTitle)}
            />
          </div>
          <div className="who-is-nr__description static">
            <h1>{t(TranslationsKeys.whoIsNrPageTitle)}</h1>
            {(
              t(TranslationsKeys.whoIsNrPageDescription, {
                returnObjects: true,
              }) as string[]
            ).map((v, i) => (
              <p key={v + i}>{v}</p>
            ))}
          </div>
        </div>
      </section>
      <section className="why-is-nr">
        <div className="why-is-nr__content">
          <div className="why-is-nr__description static">
            <h1>{t(TranslationsKeys.whoIsNrPageSubtitle)}</h1>
            <div className="why-is-nr__body">
              {(
                t(TranslationsKeys.whoIsNrPageSubDescription, {
                  returnObjects: true,
                }) as string[]
              ).map((v, i) => (
                <p key={v + i}>{v}</p>
              ))}
            </div>
            <div className="why-is-nr__license">
              <span>{t(TranslationsKeys.whoIsNrPageLicense)}</span>
              <h2>
                {t(TranslationsKeys.whoIsNrPageAmi, {
                  ami: AppDetails.licenseAmi,
                })}
              </h2>
            </div>
            <Achievements data={AppDetails.achievements} />
            <div className="why-is-nr__advantages">
              {(
                t(TranslationsKeys.whoIsNrPageAdvantages, {
                  returnObjects: true,
                }) as string[]
              ).map((v, i) => (
                <div key={v + i} className="why-is-nr__item">
                  {i === 0 ? <hr /> : null}
                  <div className="why-is-nr__advantage-layout">
                    <h3>{`${i + 1}`}</h3>
                    <span className="why-is-nr__advantage-title">
                      {t(v[0])}
                    </span>
                    <span className="why-is-nr__advantage-description">
                      {t(v[1])}
                    </span>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          </div>
          <img
            className="why-is-nr__photo static"
            src={require('../assets/png/nataliya_bg_black.png')}
            alt={t(TranslationsKeys.whoIsNrPageSubtitle)}
          />
          <div className="why-is-nr__license mobile">
            <span>{t(TranslationsKeys.whoIsNrPageLicense)}</span>
            <h2>
              {t(TranslationsKeys.whoIsNrPageAmi, {
                ami: AppDetails.licenseAmi,
              })}
            </h2>
          </div>
        </div>
      </section>
      <PreFooter />
      <Footer />
      <TermsOfUsageToast />
      <ButtonCwu />
      <SearchPane />
    </>
  );
}
