import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { PriorityArticlePreview } from '@components/priority_article_preview';
import { useAdvancedNavigator } from '@hooks/advanced_navigator.hook';
import { AppLoader, AppRoutes } from '@project/enums';
import { AppActions } from '@store/items/app';
import { ArticlesActions } from '@store/items/articles';
import { TranslationsKeys } from '@translations/translations_keys';

const mapState = ({ app, articles }: RootState) => ({
  data: articles.recommendations,
  wasLoaded: !app.loaders.includes(AppLoader.ARTICLE_RECOMMENDATIONS_LOADING),
});

const mapDispatch = {
  resumeLoader: () =>
    AppActions.setLoader(AppLoader.ARTICLE_RECOMMENDATIONS_LOADING),
  requestForGetRecommendations: (payload: GetArticlesRecommendationsDto) =>
    ArticlesActions.requestForGetRecommendations(payload),
};

function Component({
  data,
  wasLoaded,
  requestForGetRecommendations,
  resumeLoader,
}: ArticlesRecommendationProps): Nullable<JSX.Element> {
  const { t, i18n } = useTranslation();
  const { navigator } = useAdvancedNavigator();

  useEffect(() => {
    const paths = location.pathname.split('/').filter((v) => v.trim());
    const [category, slug] = paths.slice(paths.length - 2);

    requestForGetRecommendations({
      category,
      slug,
    });

    return () => {
      resumeLoader();
    };
  }, []);

  if (wasLoaded) {
    return (
      <div className="articles-recommendation">
        <h4>{t(TranslationsKeys.articlesRecommendationWidgetTitle)}</h4>
        <div className="articles-recommendation__content">
          {data
            ? data!.map((article) => (
                <PriorityArticlePreview
                  key={article.id}
                  data={article}
                  onClick={() =>
                    navigator(
                      `/article/${article.categorySlug[i18n.language]}/${
                        article.slug[i18n.language]
                      }` as AppRoutes,
                    )
                  }
                />
              ))
            : null}
        </div>
      </div>
    );
  }

  return null;
}

export const ArticlesRecommendationConnector = connect(mapState, mapDispatch);

export const ArticlesRecommendation =
  ArticlesRecommendationConnector(Component);
