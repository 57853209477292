import { useTranslation } from 'react-i18next';

import { AppDetails } from '@core/app_details';

export function PriorityArticlePreview({
  data,
  onClick,
}: PriorityArticlePreviewProps): JSX.Element {
  const { i18n } = useTranslation();

  return (
    <article className="article" onClick={onClick}>
      <img
        src={`${AppDetails.apiHost}articles/${data.id}/cover.png`}
        alt={data.title[i18n.language]}
      />
      <div className="article__details">
        <p className="article__publish">{data.updatedAt.toPrettyDate()}</p>
        <h2 className="article__title">{data.title[i18n.language]}</h2>
      </div>
    </article>
  );
}
