import { Alignment, Fit, Layout, useRive } from '@rive-app/react-canvas';
import { useEffect, useState } from 'react';

import { RiveAssets } from '@core/rive_assets';

export function BurgerIcon({
  shouldUseDarkTheme = false,
  onClick,
}: NavbarIconProps): JSX.Element {
  const [isActive, setIsActive] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  const { RiveComponent: Animation, rive } = useRive({
    src: shouldUseDarkTheme ? RiveAssets.burgerBb : RiveAssets.burgerWb,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
    autoplay: false,
    onPlay: setIsLocked.bind(null, true),
    onStop: setIsLocked.bind(null, false),
  });

  const onToggle = () => {
    if (!isLocked) {
      setIsActive(!isActive);
      rive?.play((!isActive).toString());
      onClick!();
    }
  };

  useEffect(() => {
    if (!rive?.isPlaying) {
      rive?.load({
        src: shouldUseDarkTheme ? RiveAssets.burgerBb : RiveAssets.burgerWb,
      });
    }
  }, [shouldUseDarkTheme]);

  return (
    <div className="burger" onClick={onToggle}>
      <Animation />
    </div>
  );
}
