import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ButtonCwu } from '@components/button_cwu';
import { Footer } from '@components/footer';
import { Navbar } from '@components/navbar';
import { PreFooter } from '@components/pre_footer';
import { PriorityArticlePreview } from '@components/priority_article_preview';
import { SearchPane } from '@components/search_pane';
import { TermsOfUsageToast } from '@components/terms_of_usage_toast';
import { useAdvancedNavigator } from '@hooks/advanced_navigator.hook';
import { AppRoutes } from '@project/enums';
import { ArticlesActions } from '@store/items/articles';
import { TranslationsKeys } from '@translations/translations_keys';

const mapState = ({ articles }: RootState) => ({
  recommendations: articles.recommendations,
});

const mapDispatch = {
  requestForAllNews: () => ArticlesActions.requestForAllNews(),
};

function Component({
  recommendations,
  requestForAllNews,
}: NewsPageProps): JSX.Element {
  const { t, i18n } = useTranslation();
  const { navigator } = useAdvancedNavigator();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    requestForAllNews();
  }, []);

  useEffect(() => {
    if (recommendations) {
      setIsLoaded(true);
    }
  }, [recommendations]);

  return (
    <>
      <Navbar />
      <header className="news">
        <div className="news__bg">
          <img src={require('../assets/png/news.png')} alt="news" />
        </div>
        <div className="news__fg">
          <h1>{t(TranslationsKeys.newsPageTitle)}</h1>
        </div>
      </header>
      <section className="news-content">
        {isLoaded
          ? recommendations!.map((article) => (
              <PriorityArticlePreview
                key={article.id}
                data={article}
                onClick={() =>
                  navigator(
                    `/article/${article.categorySlug[i18n.language]}/${
                      article.slug[i18n.language]
                    }` as AppRoutes,
                  )
                }
              />
            ))
          : null}
      </section>
      <PreFooter />
      <Footer />
      <TermsOfUsageToast />
      <ButtonCwu />
      <SearchPane />
    </>
  );
}

export const NewsPageConnector = connect(mapState, mapDispatch);

export const NewsPage = NewsPageConnector(Component);
