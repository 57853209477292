import { call, put } from 'redux-saga/effects';

import { showToast } from '@components/toast';
import { ApiRequests } from '@core/api_requests';
import { AppError, AppLoader, MessageType } from '@project/enums';
import { AppActions } from '@store/items/app';
import { ServicesActions } from '@store/items/services';
import { TranslationsModule } from '@translations/module';
import { TranslationsKeys } from '@translations/translations_keys';

export function* getAllServices() {
  yield put(AppActions.setLoader(AppLoader.SERVICE_RECOMMENDATIONS_LOADING));

  try {
    const servicesPreviews: PriorityService[] = yield call(
      ApiRequests.getAllServices,
    );

    yield put(ServicesActions.setRecommendations(servicesPreviews));
  } catch (e: Exception) {
    yield put(
      AppActions.setError(AppError.UNABLE_TO_GET_RECOMMENDATIONS_SERVICES),
    );
  }

  yield put(AppActions.resetLoader(AppLoader.SERVICE_RECOMMENDATIONS_LOADING));
}

export function* getPriorityServices() {
  try {
    const priorityServices: PriorityService[] = yield call(
      ApiRequests.getPriorityServices,
    );

    yield put(ServicesActions.setPriority(priorityServices));
  } catch (e: Exception) {
    try {
      const messages = e.split(',').map((v: string) => v.trim());

      messages.length
        ? messages.forEach((v: string) =>
            showToast({
              type: MessageType.ERROR,
              message: TranslationsModule.t(
                TranslationsKeys.dynamicApiMessages(v),
              ),
            }),
          )
        : showToast({
            type: MessageType.ERROR,
            message: e as string,
          });

      yield put(AppActions.setError(AppError.UNABLE_TO_GET_PRIORITY_SERVICES));
    } catch (e: Exception) {
      showToast({
        type: MessageType.ERROR,
        message: TranslationsModule.t(
          TranslationsKeys.dynamicApiMessages('INTERNAL_ERROR'),
        ),
      });
    }
  }
}

export function* getExactService(
  action: DispatchWithPayload<GetExactServiceDto>,
) {
  yield put(AppActions.setLoader(AppLoader.EXACT_SERVICE_LOADING));

  try {
    yield put(AppActions.resetDynamicPath());

    const service: ExactService = yield call(
      ApiRequests.getExactService,
      action.payload,
    );

    yield put(AppActions.setDynamicPath(service.slug));
    yield put(ServicesActions.setExact(service));
  } catch (e: Exception) {
    yield put(AppActions.setError(AppError.UNABLE_TO_GET_EXACT_SERVICE));
  }

  yield put(AppActions.resetLoader(AppLoader.EXACT_SERVICE_LOADING));
}

export function* getServicesRecommendations(
  action: DispatchWithPayload<GetServicesRecommendationsDto>,
) {
  yield put(AppActions.setLoader(AppLoader.SERVICE_RECOMMENDATIONS_LOADING));

  try {
    const servicesPreviews: PriorityService[] = yield call(
      ApiRequests.getServicesRecommendation,
      action.payload,
    );

    yield put(ServicesActions.setRecommendations(servicesPreviews));
  } catch (e: Exception) {
    yield put(
      AppActions.setError(AppError.UNABLE_TO_GET_RECOMMENDATIONS_SERVICES),
    );
  }

  yield put(AppActions.resetLoader(AppLoader.SERVICE_RECOMMENDATIONS_LOADING));
}
