import { useRive, Layout, Fit, Alignment } from '@rive-app/react-canvas';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { FormForRequest } from '@components/form_for_request';
import { AppDetails } from '@core/app_details';
import { RiveAssets } from '@core/rive_assets';
import { AppLoader, AppPassedAction, FormForRequestType } from '@project/enums';
import { TranslationsKeys } from '@translations/translations_keys';

const mapState = ({ app, customers }: RootState) => ({
  wasLoaded: !app.loaders.includes(AppLoader.SPLASH_LOADING),
  wasPassed: app.passedActions.includes(
    AppPassedAction.FORM_FOR_REQUEST_WAS_SENT,
  ),
  isInSearchMode: customers.isInSearchMode,
});

function Component({
  wasLoaded,
  wasPassed,
  isInSearchMode,
}: ButtonCwuProps): Nullable<JSX.Element> {
  const { t } = useTranslation();

  const [isOpened, setIsOpened] = useState(false);
  const [isDisplayingFinal, setIsDisplayingFinal] = useState(false);
  const [requestType, setRequestType] =
    useState<Nullable<FormForRequestType>>(null);

  useEffect(() => {
    const controller = setInterval(() => {
      setIsOpened(true);
    }, AppDetails.defaultConnectWithUsNotifications);

    return () => {
      clearInterval(controller);
    };
  }, []);

  useEffect(() => {
    if (wasPassed) {
      setRequestType(null);
      setIsDisplayingFinal(true);
    }
  }, [wasPassed]);

  useEffect(() => {
    if (isDisplayingFinal) {
      setTimeout(() => {
        setIsDisplayingFinal(false);
        setIsOpened(false);
      }, 6000);
    }
  }, [isDisplayingFinal]);

  const { RiveComponent: Animation } = useRive({
    src: RiveAssets.buttonCwu,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
    autoplay: true,
  });

  if (wasLoaded) {
    return (
      <div className={`button-cwu ${isInSearchMode ? 'search-activated' : ''}`}>
        {isOpened ? (
          <div className="button-cwu__form">
            {!requestType && !isDisplayingFinal ? (
              <>
                <span>{t(TranslationsKeys.buttonCwuFormLabel)}</span>
                <div
                  className="button-cwu__form-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    setRequestType(FormForRequestType.SELL_WITH_US);
                  }}
                >
                  {t(TranslationsKeys.buttonCwuFormThinkingOfSelling)}
                  <img
                    src={
                      require('../assets/svg/icons/arrow_right_black.svg')
                        .default
                    }
                    alt="arrow_right_black"
                  />
                </div>
                <div
                  className="button-cwu__form-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    setRequestType(FormForRequestType.ASK_QUESTION);
                  }}
                >
                  {t(TranslationsKeys.buttonCwuFormSendYourRequest)}
                  <img
                    src={
                      require('../assets/svg/icons/arrow_right_black.svg')
                        .default
                    }
                    alt="arrow_right_black"
                  />
                </div>
                <div
                  className="button-cwu__form-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    setRequestType(FormForRequestType.ASK_FOR_FREE_VALUATION);
                  }}
                >
                  {t(TranslationsKeys.buttonCwuFormAskForFreeValuation)}
                  <img
                    src={
                      require('../assets/svg/icons/arrow_right_black.svg')
                        .default
                    }
                    alt="arrow_right_black"
                  />
                </div>
              </>
            ) : null}
            {requestType ? (
              <>
                <div className="button-cwu__form-header">
                  <img
                    src={
                      require('../assets/svg/icons/arrow_left_black.svg')
                        .default
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setRequestType(null);
                    }}
                    alt="arrow_left_black"
                  />
                  <span>{t(TranslationsKeys.buttonCwuFormTitle)}</span>
                  <img
                    className="close"
                    src={require('../assets/svg/icons/close.svg').default}
                    alt="close"
                    onClick={(e) => {
                      e.stopPropagation();
                      setRequestType(null);
                      setIsOpened(false);
                    }}
                  />
                </div>
                <FormForRequest type={requestType} />
              </>
            ) : null}
            {!requestType && isDisplayingFinal ? (
              <div className="button-cwu__form-thanks">
                <img
                  className="close"
                  src={require('../assets/svg/icons/close.svg').default}
                  alt="close"
                  onClick={(e) => {
                    e.stopPropagation();
                    setRequestType(null);
                    setIsOpened(false);
                    setIsDisplayingFinal(false);
                  }}
                />
                <div className="button-cwu__form-thanks-label">
                  <img
                    src={require('../assets/svg/icons/thanks.svg').default}
                    alt="thanks"
                  />
                  <span>{t(TranslationsKeys.buttonCwuFormThanksLabel)}</span>
                </div>
              </div>
            ) : null}
            <div className="triangle"></div>
          </div>
        ) : null}
        <div
          className="button-cwu__animation"
          onClick={setIsOpened.bind(null, !isOpened)}
        >
          <Animation />
        </div>
        <div
          className="button-cwu__layout"
          onClick={setIsOpened.bind(null, !isOpened)}
        >
          <img
            src={require('../assets/svg/send_one.svg').default}
            alt="send one"
          />
          <div className="button-cwu__label">
            {t(TranslationsKeys.connectWithUsButton)}
          </div>
        </div>
      </div>
    );
  }

  return null;
}

export const ButtonCwuConnector = connect(mapState, null);

export const ButtonCwu = ButtonCwuConnector(Component);
