import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReviewRate } from '@components/rate';
import { ReviewVideoPlayer } from '@components/review_video_player';
import { AppDetails } from '@core/app_details';

export function ReviewItem({ data }: ReviewItemProps): JSX.Element {
  const { i18n } = useTranslation();
  const [url, setUrl] = useState<Nullable<string>>(null);

  return (
    <div className="review static">
      <span className="review__description">{data.content[i18n.language]}</span>

      <div className="review__content">
        {data.hasVideoContent ? (
          <div
            className="review__video"
            onClick={setUrl.bind(
              null,
              `${AppDetails.apiHost}reviews/${data.id}.mp4`,
            )}
          >
            <img
              src={require('../assets/svg/icons/play.svg').default}
              alt="play"
            />
            <video>
              <source
                src={`${AppDetails.apiHost}reviews/${data.id}.mp4`}
                type="video/mp4"
              />
            </video>
          </div>
        ) : data.isAnonym ? (
          <img
            src={require('../assets/png/anonym-reviewer.png')}
            alt={data.subject}
          />
        ) : (
          <img
            src={`${AppDetails.apiHost}reviews/${data.id}.png`}
            alt={data.subject}
          />
        )}
        <div className="review__client-data">
          <span className="review__from">{data.subject}</span>
          <ReviewRate value={data.rate} />
          <span className="review__where">{data.areaLabel}</span>
        </div>
      </div>
      {url ? (
        <ReviewVideoPlayer url={url} onClose={setUrl.bind(null, null)} />
      ) : null}
    </div>
  );
}
