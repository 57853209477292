import { call, put } from 'redux-saga/effects';

import { showToast } from '@components/toast';
import { ApiRequests } from '@core/api_requests';
import { AppDetails } from '@core/app_details';
import {
  AppError,
  AppPassedAction,
  AvailableTranslationLang,
  LocalStorageKey,
  MessageType,
} from '@project/enums';
import { AppActions } from '@store/items/app';
import { CustomersActions } from '@store/items/customers';
import { TranslationsModule } from '@translations/module';
import { TranslationsKeys } from '@translations/translations_keys';

export function* getCustomersLangDetails() {
  try {
    let currentLang = AppDetails.fallbackAppLang;

    const userLang = navigator.language as AvailableTranslationLang;
    const savedLang = localStorage.getItem(
      LocalStorageKey.USER_LANG,
    ) as Nullable<AvailableTranslationLang>;

    if (savedLang && AppDetails.supportedLangs.includes(savedLang)) {
      const index = AppDetails.supportedLangs.indexOf(savedLang);

      currentLang = AppDetails.supportedLangs[index];
    } else if (AppDetails.supportedLangs.includes(userLang)) {
      const index = AppDetails.supportedLangs.indexOf(userLang);

      currentLang = AppDetails.supportedLangs[index];
    }

    const langDetails = AppDetails.langs.filter((l) => l.code === currentLang);

    yield put(CustomersActions.setLangDetails(langDetails[0]));
  } catch {
    showToast({
      type: MessageType.ERROR,
      message: '?',
    });
  }
}

export function* updateCustomersLangDetails(
  action: DispatchWithPayload<AvailableTranslationLang>,
) {
  try {
    const { payload: currentLang } = action;

    localStorage.setItem(LocalStorageKey.USER_LANG, currentLang);

    const langDetails = AppDetails.langs.filter((l) => l.code === currentLang);

    yield put(CustomersActions.setLangDetails(langDetails[0]));
  } catch {
    showToast({
      type: MessageType.ERROR,
      message: '?',
    });
  }
}

export function* getCustomersTermsAcceptance() {
  try {
    const valueFromStorage = localStorage.getItem(
      LocalStorageKey.WAS_USER_ACCEPT_TERMS_OF_USAGE,
    ) as Nullable<string>;

    yield put(CustomersActions.setTermsOfUsage(valueFromStorage === 'true'));
  } catch {
    showToast({
      type: MessageType.ERROR,
      message: '?',
    });
  }
}

export function* acceptTermsOfUsage() {
  yield put(CustomersActions.setTermsOfUsage(true));
  localStorage.setItem(LocalStorageKey.WAS_USER_ACCEPT_TERMS_OF_USAGE, 'true');
}

export function* subscribeCustomer(
  action: DispatchWithPayload<CreateCustomerDto>,
) {
  yield put(AppActions.lockUI());

  try {
    const response: ApiMessage = yield call(
      ApiRequests.subscribeCustomer,
      action.payload,
    );

    yield put(
      AppActions.setPassedAction(AppPassedAction.FORM_FOR_REQUEST_WAS_SENT),
    );

    showToast({
      type: MessageType.SUCCESS,
      message: TranslationsModule.t(
        TranslationsKeys.dynamicApiMessages(response.message!),
      ),
    });
  } catch (e: Exception) {
    try {
      const messages = e.split(',').map((v: string) => v.trim());

      messages.length
        ? messages.forEach((v: string) =>
            showToast({
              type: MessageType.ERROR,
              message: TranslationsModule.t(
                TranslationsKeys.dynamicApiMessages(v),
              ),
            }),
          )
        : showToast({
            type: MessageType.ERROR,
            message: e as string,
          });

      yield put(AppActions.setError(AppError.FORM_FOR_REQUEST_ISSUE));
    } catch (e: Exception) {
      showToast({
        type: MessageType.ERROR,
        message: TranslationsModule.t(
          TranslationsKeys.dynamicApiMessages('INTERNAL_ERROR'),
        ),
      });
    }
  }

  yield put(AppActions.unlockUI());
}

export function* requestFormFromCustomer(
  action: DispatchWithPayload<CustomerRequest>,
) {
  yield put(AppActions.lockUI());

  try {
    const response: ApiMessage = yield call(
      ApiRequests.requestFromCustomer,
      action.payload,
    );

    yield put(
      AppActions.setPassedAction(AppPassedAction.FORM_FOR_REQUEST_WAS_SENT),
    );

    showToast({
      type: MessageType.SUCCESS,
      message: TranslationsModule.t(
        TranslationsKeys.dynamicApiMessages(response.message!),
      ),
    });
  } catch (e: Exception) {
    try {
      const messages = e.split(',').map((v: string) => v.trim());

      messages.length
        ? messages.forEach((v: string) =>
            showToast({
              type: MessageType.ERROR,
              message: TranslationsModule.t(
                TranslationsKeys.dynamicApiMessages(v),
              ),
            }),
          )
        : showToast({
            type: MessageType.ERROR,
            message: e as string,
          });

      yield put(AppActions.setError(AppError.FORM_FOR_REQUEST_ISSUE));
    } catch (e: Exception) {
      showToast({
        type: MessageType.ERROR,
        message: TranslationsModule.t(
          TranslationsKeys.dynamicApiMessages('INTERNAL_ERROR'),
        ),
      });
    }
  }

  yield put(AppActions.unlockUI());
}

export function* getCustomerFavorites() {
  try {
    const valueFromStorage = localStorage.getItem(
      LocalStorageKey.FAVORITES,
    ) as Nullable<string>;

    if (!valueFromStorage) {
      localStorage.setItem(LocalStorageKey.FAVORITES, JSON.stringify([]));
    }

    yield put(
      CustomersActions.setFavorites(
        valueFromStorage ? JSON.parse(valueFromStorage) : [],
      ),
    );
  } catch {
    showToast({
      type: MessageType.ERROR,
      message: '?',
    });
  }
}

export function* addCustomerFavorite(action: DispatchWithPayload<number>) {
  try {
    const valueFromStorage = localStorage.getItem(
      LocalStorageKey.FAVORITES,
    ) as Nullable<string>;

    const favorites = [
      action.payload,
      ...(JSON.parse(valueFromStorage!) as number[]),
    ];

    localStorage.setItem(LocalStorageKey.FAVORITES, JSON.stringify(favorites));

    yield put(CustomersActions.addFavorite(action.payload));
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: '?',
    });
  }
}

export function* deleteCustomerFavorite(action: DispatchWithPayload<number>) {
  try {
    const valueFromStorage = localStorage.getItem(
      LocalStorageKey.FAVORITES,
    ) as Nullable<string>;

    const favorites = (JSON.parse(valueFromStorage!) as number[]).filter(
      (v) => v !== action.payload,
    );

    localStorage.setItem(LocalStorageKey.FAVORITES, JSON.stringify(favorites));

    yield put(CustomersActions.deleteFavorite(action.payload));
  } catch {
    showToast({
      type: MessageType.ERROR,
      message: '?',
    });
  }
}
