import { call, put } from 'redux-saga/effects';

import { ApiRequests } from '@core/api_requests';
import { AppError, AppLoader } from '@project/enums';
import { AppActions } from '@store/items/app';
import { CommonQuestionsActions } from '@store/items/common_questions';

export function* getAllCommonQuestions() {
  yield put(AppActions.setLoader(AppLoader.COMMON_QUESTIONS_LOADING));

  try {
    const data: CommonQuestion[] = yield call(
      ApiRequests.getAllCommonQuestions,
    );

    yield put(CommonQuestionsActions.setData(data));
  } catch (e: Exception) {
    yield put(AppActions.setError(AppError.UNABLE_TO_GET_COMMON_QUESTIONS));
  }

  yield put(AppActions.resetLoader(AppLoader.COMMON_QUESTIONS_LOADING));
}
