import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { AskForViewing } from '@components/ask_for_viewing';
import { ButtonFavorite } from '@components/button_favorite';
import { ButtonIcon } from '@components/button_icon';
import { CopyIcon } from '@components/icons/copy.icon';
import { DownloadIcon } from '@components/icons/download.icon';
import { EmailIcon } from '@components/icons/email.icon';
import { PrintIcon } from '@components/icons/print.icon';
import { LabelIcon } from '@components/label_icon';
import { showToast } from '@components/toast';
import { AppDetails } from '@core/app_details';
import { useAdvancedNavigator } from '@hooks/advanced_navigator.hook';
import {
  AppPassedAction,
  AppRoutes,
  MessageType,
  PropertyType,
} from '@project/enums';
import { TranslationsKeys } from '@translations/translations_keys';

const mapState = ({ app }: RootState) => ({
  wasPassed: app.passedActions.includes(
    AppPassedAction.FORM_FOR_REQUEST_WAS_SENT,
  ),
});

export function Component({
  wasPassed,
  data,
  positionAtList,
}: PropertyItemProps): JSX.Element {
  const { t, i18n } = useTranslation();
  const { navigator, guessNextRoute } = useAdvancedNavigator();
  let sliderRef = useRef(null);
  const [isOpened, setIsOpened] = useState(false);

  const next = () => {
    // @ts-ignore
    sliderRef.slickNext();
  };

  const previous = () => {
    // @ts-ignore
    sliderRef.slickPrev();
  };

  const isEven = positionAtList % 2 === 0;
  const hasDiscount = !!data.priceWithDiscount;

  const hasSold = data.maxPreviewAmount > 1 && data.price === null;

  const mostValuableLabel = data.id === 8 ? 'Reduced' : null;
  let label = data.maxPreviewAmount <= 1 ? 'secret property' : null;
  let type = hasDiscount
    ? PropertyType.SALE
    : data.isExternal
    ? PropertyType.NEW
    : PropertyType.NEW; // PropertyType.EXCLUSIVE

  if (hasSold) {
    label = 'sold';
  }

  useEffect(() => {
    if (wasPassed) {
      setIsOpened(false);
    }
  }, [wasPassed]);

  return (
    <article className={`property ${isEven ? 'even' : 'odd'} static ${type}`}>
      {isOpened ? (
        <AskForViewing
          onClose={setIsOpened.bind(null, false)}
          propertyId={data.id}
        />
      ) : null}
      <div
        className="property__details"
        onClick={() => navigator(`/property/${data.id}` as AppRoutes)}
      >
        <div className="property__pre-header">
          <span className={`property__type ${type}`}>
            {mostValuableLabel || label
              ? mostValuableLabel || label
              : t(TranslationsKeys.propertyPreviewWidgetTypeLabels(type))}
          </span>
          <span className="property__ref">
            Ref: {`${data.isExternal ? 'Ext_' : 'Roso_'}${data.id}`}
          </span>
        </div>
        <div className="property__header">
          <h2 className="property__title">{data.title}</h2>
          <div className="property__header-layout">
            {hasDiscount ? (
              <span className="property__discount">
                {`${`${data.priceWithDiscount}`!.toPrice()} ${
                  data.currencyCode
                }`}
              </span>
            ) : null}
            {data.price ? (
              <span className={`property__price ${type}`}>
                {`${`${data.price}`.toPrice()} ${data.currencyCode}`}
              </span>
            ) : null}
            {label === 'secret property' && data.id === 16 ? (
              <span className={`property__price ${type}`}>POA</span>
            ) : null}
          </div>
        </div>
        <div className="property__info">
          {data.landArea ? (
            <LabelIcon
              data={data.landArea.toString()}
              label={t(
                TranslationsKeys.propertyPreviewWidgetCommonFieldsLandArea,
              )}
              extraLabel={data.measurementUnitSymbol.toUpperCase()}
              icon={
                require('../assets/svg/icons/property_item/land_area.svg')
                  .default
              }
            />
          ) : null}
          <LabelIcon
            data={data.buildArea.toString()}
            label={t(
              TranslationsKeys.propertyPreviewWidgetCommonFieldsBuildingArea,
            )}
            extraLabel={data.measurementUnitSymbol.toUpperCase()}
            icon={
              require('../assets/svg/icons/property_item/build_area.svg')
                .default
            }
          />
          <LabelIcon
            data={data.bedrooms.toString()}
            label={t(TranslationsKeys.propertyPreviewWidgetCommonFieldsBedroom)}
            icon={
              require('../assets/svg/icons/property_item/bedroom.svg').default
            }
          />
          <LabelIcon
            data={data.bathrooms.toString()}
            label={t(
              TranslationsKeys.propertyPreviewWidgetCommonFieldsBathroom,
            )}
            icon={
              require('../assets/svg/icons/property_item/bathroom.svg').default
            }
          />
          <LabelIcon
            data={data.hasGarage ? 'YES' : 'NO'}
            label={t(TranslationsKeys.propertyPreviewWidgetCommonFieldsGarage)}
            icon={
              require('../assets/svg/icons/property_item/garage.svg').default
            }
          />
          <LabelIcon
            data={data.hasSwimmingPool ? 'YES' : 'NO'}
            label={t(
              TranslationsKeys.propertyPreviewWidgetCommonFieldsSwimmingPool,
            )}
            icon={
              require('../assets/svg/icons/property_item/swimming_pool.svg')
                .default
            }
          />
        </div>
        <p
          className="property__description"
          dangerouslySetInnerHTML={{
            __html: data.description[i18n.language],
          }}
        ></p>
        <div className="property__controllers">
          <ButtonIcon
            label={t(
              TranslationsKeys.propertyPreviewWidgetControllersPresentation,
            )}
            extraClasses="default-outline-button"
            widget={<DownloadIcon />}
            onClick={() =>
              fetch(
                `${AppDetails.apiHost}properties/${data.id}/presentation.pdf`,
              ).then((response) => {
                response.blob().then((blob) => {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = data.title;
                  a.click();
                });
              })
            }
          />
          <ButtonIcon
            label={t(TranslationsKeys.propertyPreviewWidgetControllersPrint)}
            extraClasses="default-outline-button"
            widget={<PrintIcon />}
            onClick={() =>
              // @ts-ignore
              printJS(
                `${AppDetails.apiHost}properties/${data.id}/presentation.pdf`,
              )
            }
          />
          <ButtonIcon
            label={t(TranslationsKeys.propertyPreviewWidgetControllersCopyLink)}
            extraClasses="default-outline-button"
            widget={<CopyIcon />}
            onClick={() => {
              window.navigator.clipboard.writeText(
                `${location.origin}${guessNextRoute(
                  `/property/${data.id}` as AppRoutes,
                )}`,
              );

              showToast({
                type: MessageType.SUCCESS,
                message: t(TranslationsKeys.toasterMessagesLinkWasCopied),
              });
            }}
          />
          <ButtonIcon
            label={t(
              TranslationsKeys.propertyPreviewWidgetControllersAskForViewing,
            )}
            extraClasses="default-outline-button"
            widget={<EmailIcon />}
            onClick={() => setIsOpened(true)}
          />
        </div>
      </div>
      <div className="property__layout">
        {hasSold ? (
          <header className="property__image-highlight">
            <h4>SOLD</h4>
          </header>
        ) : null}
        <ButtonFavorite id={data.id} />
        {data.maxPreviewAmount > 1 ? (
          <div
            className={`property__controller left ${isEven ? 'even' : 'odd'}`}
            onClick={previous}
          >
            <img
              src={require('../assets/svg/icons/slider_arrow_left.svg').default}
              alt="left"
            />
          </div>
        ) : null}
        {data.maxPreviewAmount > 1 ? (
          <Slider
            dots={false}
            slidesToShow={1}
            arrows={false}
            centerMode={true}
            variableWidth={true}
            infinite={true}
            className={`property__slider ${isEven ? 'even' : 'odd'}`}
            ref={(slider) => {
              sliderRef = slider as any;
            }}
            responsive={[
              {
                breakpoint: 1140,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false,
                  adaptiveHeight: true,
                },
              },
            ]}
          >
            {[...Array(data.maxPreviewAmount).keys()]
              .map((i) => i + 1)
              .map((fileName) => (
                <img
                  key={
                    `${AppDetails.apiHost}` +
                    `properties/${data.id}/${fileName}.jpg`
                  }
                  src={
                    `${AppDetails.apiHost}` +
                    `properties/${data.id}/${fileName}.jpg`
                  }
                  alt={`${data.title} - ${fileName}`}
                />
              ))}
          </Slider>
        ) : (
          <img
            className={isEven ? 'even' : 'odd'}
            src={`${AppDetails.apiHost}properties/${data.id}/1.jpg`}
            alt={`${data.title} - 1.jpg`}
          />
        )}
        {data.maxPreviewAmount > 1 ? (
          <div
            className={`property__controller right ${isEven ? 'even' : 'odd'}`}
            onClick={next}
          >
            <img
              src={
                require('../assets/svg/icons/slider_arrow_right.svg').default
              }
              alt="right"
            />
          </div>
        ) : null}
      </div>
    </article>
  );
}

export const PropertyItemConnector = connect(mapState, null);

export const PropertyItem = PropertyItemConnector(Component);
