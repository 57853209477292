import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { EmailIcon } from '@components/icons/email.icon';
import { Signature } from '@components/signature';
import { SocialNetworksPanel } from '@components/social_networks_panel';
import { AppDetails } from '@core/app_details';
import { useAdvancedNavigator } from '@hooks/advanced_navigator.hook';
import { AppRoutes } from '@project/enums';
import { TranslationsKeys } from '@translations/translations_keys';

export function PreFooter(): JSX.Element {
  const { t } = useTranslation();
  const { navigator } = useAdvancedNavigator();

  return (
    <section className="pre-footer">
      <div className="pre-footer__layout">
        <Signature />
        <div className="pre-footer__links">
          <div className="pre-footer__column">
            <span onClick={() => navigator(AppRoutes.TNC)}>
              {t(TranslationsKeys.preFooterTnc)}
            </span>
            <span onClick={() => navigator(AppRoutes.PRIVACY_POLICY)}>
              {t(TranslationsKeys.preFooterPrivacyPolicy)}
            </span>
            <NavLink target="_blank" to={AppDetails.complaintBook}>
              {t(TranslationsKeys.preFooterComplaintBook)}
            </NavLink>
          </div>
          <div className="pre-footer__column">
            <span onClick={() => navigator(AppRoutes.ABOUT_ALGARVE)}>
              {t(TranslationsKeys.preFooterAboutAlgarve)}
            </span>
            <span onClick={() => navigator(AppRoutes.FAQ)}>
              {t(TranslationsKeys.preFooterFaq)}
            </span>
            <span onClick={() => navigator(AppRoutes.SERVICES)}>
              {t(TranslationsKeys.preFooterServices)}
            </span>
          </div>
          <div className="pre-footer__contacts">
            <SocialNetworksPanel />
            <div className="pre-footer__contact">
              <img
                src={require('../assets/svg/icons/phone.svg').default}
                alt="phone number"
              />
              <NavLink to="tel:+351915656525">+351915656525</NavLink>
            </div>
            <div className="pre-footer__contact">
              <EmailIcon extraClasses="contact" />
              <NavLink to="mailto:nataliya@roso.properties">
                nataliya@roso.properties
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
